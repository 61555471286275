import {getNumberSpinnerValue, setNumberSpinnerMaxValue} from "./numberSpinner";

export function initInScope($scope) {
    $scope.find('.js-number-spinner-group').on('change input', function (group, i) {
        let $group = $(this);
        let totalMax = +$group.data('number-spinner-group-map');
        let $items = $group.find('.js-number-spinner-group__item');
        let currentTotal = $items.toArray().reduce((total, input) => {
            return total + (+getNumberSpinnerValue($(input)));
        }, 0);
        let remaining = totalMax - currentTotal;

        $items.each((index, element) => {
            let $element = $(element);
            setNumberSpinnerMaxValue($element, (+getNumberSpinnerValue($element)) + remaining);
        })
    })
}