'use strict';

export function initInScope($scope) {

    let counter = 1;
    let $popup = $scope.find('.js-popup');
    let showAlways = $popup.data('popup-show-always') || false;
    let visitedCounter = $popup.data('popup-show-on-visit-counter') || 2;

    if (showAlways === true) {
        $popup.modal('show');
        try {
            sessionStorage.removeItem('showpopup');
            sessionStorage.removeItem('popupclosed');
        } catch (err) {}
    } else {
        try {
            if (sessionStorage.getItem("showpopup")) {
                let popupShownCounter = sessionStorage.getItem("showpopup");
                counter = parseInt(popupShownCounter) + 1;

                if (sessionStorage.getItem("popupclosed")) {
                    let isClosed = parseInt(sessionStorage.getItem("popupclosed"));
                    if (isClosed === 0) {
                        if (counter >= visitedCounter) {
                            $popup.modal('show');
                        }
                    }
                }
            } else {
                sessionStorage.setItem('popupclosed', '0');
            }

            $popup.on('hidden.bs.modal', () => {
                sessionStorage.setItem('popupclosed', '1');
            });

            sessionStorage.setItem('showpopup', String(counter));
        } catch (err) {}
    }

}