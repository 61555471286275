"use strict";

export function init() {
    updateFullHeight();
}

export function updateFullHeight() {
    //due to timing problem
    setTimeout(function() {
        let $fullHeightItem = $('.js-full-height');
        let height = $( window ).height();

        $fullHeightItem.each(function() {
            let topOffset = $(this).offset().top;
            height = height - topOffset;
            $('body').css('overflow', 'hidden');

            if (matchMedia('(max-width: 767px)').matches) {
                let $console = $('.navbar-console-bottom');
                height -= $console.height();
            }

            $(this).css('height', `${height}px`);
        })
    }, 500);
}
