/** @jsx h */

import {noop} from "../../utils";
import {h} from "preact";
import {translate} from '@elements/translations';

export default function SkiTicketConfigurationList({
    insurances = [],
    upgrades = [],
    specialTickets = [],
    selectedConfigurationId = null,
    ageGroups = [],
    currencies = [],
    prices,
    selectedCurrencyId,
    configurations = [],
    persons = [],
    onEditClick = noop,
    onDeleteClick = noop,
}) {

    function compareArrays(first, second){
        return first.every((e)=> second.includes(e)) && second.every((e)=> first.includes(e));
    }

    //get Total Price of configuration
    const getPrice = (configuration) => {
        let selectedPrice = prices.filter(obj => compareArrays(obj.upgradeIds, configuration.upgradeIds) && obj.insuranceId === configuration.insuranceId && obj.specialTicketId === configuration.specialTicketId)[0];
        let total = 0;
        if(selectedPrice) {
            total = selectedPrice.byAgeGroupId[configuration.ageGroupId][selectedCurrencyId].toFixed(2)
        }
        return total;
    };

    return (
        <ul className={`list-unstyled ticket-configuration__list mt-3  ${selectedConfigurationId ? 'ticket-configuration__list--edit' : ''}`}>
            {configurations.map((configuration) => (
                <li className={`ticket-configuration__box ticket-configuration__list-item ${selectedConfigurationId === configuration.id ? 'ticket-configuration__box--edit active' : ''}`} key={configuration.id}>
                    <div className="row row--gutter-width-6">
                        <div className="col-md-4 col-7 order-md-1 order-1">
                            {persons.allIds.length > 0 && configuration.personId ? (
                                <div className="ticket-configuration__small-title">{persons.byId[configuration.personId].name}</div>
                            ) : null }
                            <div className="ticket-configuration__title">{ageGroups.byId[configuration.ageGroupId].name}</div>
                        </div>
                        <div className="col-md-4 col order-md-2 order-3">
                            <div className="ticket-configuration__small-title">{translate('ski-ticket.services')}</div>
                            { configuration.specialTicketId ? (
                                <div>{specialTickets.byId[configuration.specialTicketId].name}</div>
                            ) : null }

                            {configuration.upgradeIds.map((upgrade) => (
                                <div>{upgrades.byId[upgrade].name}</div>
                            ))}

                            { configuration.insuranceId ? (
                                <div>{insurances.byId[configuration.insuranceId].name}</div>
                            ) : null }

                            { !configuration.specialTicketId && configuration.upgradeIds.length === 0 && !configuration.insuranceId ? (
                                <div>{translate('ski-ticket.no-services')}</div>
                            ) : null }
                        </div>
                        <div className="col-md-3 col-5 order-md-3 order-2 text-right text-md-left">
                            <div className="ticket-configuration__small-title">{translate('ski-ticket.price')}</div>
                            { getPrice(configuration) + " " + currencies.byId[selectedCurrencyId].name}
                        </div>
                        <div className="col-md-1 col-auto order-md-4 order-4">
                            <div className="row row--gutter-width-0 h-100 align-items-md-center align-items-end">
                                <div className="col-6">
                                    <button className="btn-no-styling ticket-configuration__edit-btn" onClick={() => onEditClick(configuration.id)}><span className="icon icon-edit-new"/></button>
                                </div>
                                <div className="col-6">
                                    <button className="btn-no-styling ticket-configuration__delete-btn" onClick={() => onDeleteClick(configuration.id)}><span className="icon icon-bin"/></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    )
}


