"use strict";

import {onEnterViewPort} from "@elements/viewport-utils";
import {default as initMap, setMarker, loadGoogleMapsAPI, showInfoBoxByMarker, closeInfoBoxByMarker, centerMap} from "@elements/google-map";

export function initInScope($scope) {
    let $maps  = $scope.find('.js-google-map');

    if ($maps && $maps.length) {

        $maps.each(function (index, element) {
            let $container = $(element);
            let $mapCanvas = $container.find('.js-google-map__canvas');

            onEnterViewPort($(element), function () {
                loadGoogleMapsAPI().then(function () {
                    const poiStyles = {
                        default: {
                            default: {
                                url: '/static/build/img/map/marker.svg',
                                size: new google.maps.Size(36, 53),
                                origin: new google.maps.Point(0, 0),
                                anchor: new google.maps.Point(25, 45)
                            },
                            active: {
                                url: '/static/build/img/map/marker-active.svg',
                                size: new google.maps.Size(36, 53),
                                origin: new google.maps.Point(0, 0),
                                anchor: new google.maps.Point(25, 45)
                            }
                        }
                    };

                    let infoBoxWidth = matchMedia('(max-width: 767px)').matches ? '270px' : '300px';
                    let infoBoxPadding = matchMedia('(max-width: 767px)').matches ? '10px' : '20px';
                    let infoBoxPixelOffset = matchMedia('(max-width: 767px)').matches ? new google.maps.Size(-140, -50) : new google.maps.Size(-158, -50);
                    const infoBoxOptions = {
                        alignBottom: true,
                        pixelOffset: infoBoxPixelOffset,
                        boxStyle: {width: infoBoxWidth, background: "#fff", padding: infoBoxPadding},
                        boxClass: "infobox",
                        closeBoxURL: "/static/img/map/close.svg"
                    };

                    let clusteringOptions = {
                        styles: [{
                            height: 72,
                            url: "/static/build/img/map/marker-cluster.svg",
                            width: 72,
                            textSize: 20,
                            textColor: "#FFF"
                        }]
                    };

                    const customOptions = {
                        scrollwheel: false,
                        zoom: 17,
                        disableDefaultUI: true,
                        styles: [
                            {
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "hue": "#FFBB00"
                                    },
                                    {
                                        "saturation": 43.400000000000006
                                    },
                                    {
                                        "lightness": 37.599999999999994
                                    },
                                    {
                                        "gamma": 1
                                    }
                                ]
                            },
                            {
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "hue": "#FFC200"
                                    },
                                    {
                                        "saturation": -61.8
                                    },
                                    {
                                        "lightness": 45.599999999999994
                                    },
                                    {
                                        "gamma": 1
                                    }
                                ]
                            },
                            {
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "hue": "#FF0300"
                                    },
                                    {
                                        "saturation": -100
                                    },
                                    {
                                        "lightness": 51.19999999999999
                                    },
                                    {
                                        "gamma": 1
                                    }
                                ]
                            },
                            {
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "hue": "#FF0300"
                                    },
                                    {
                                        "saturation": -100
                                    },
                                    {
                                        "lightness": 52
                                    },
                                    {
                                        "gamma": 1
                                    }
                                ]
                            },
                            {
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "hue": "#0078FF"
                                    },
                                    {
                                        "saturation": -13.200000000000003
                                    },
                                    {
                                        "lightness": 2.4000000000000057
                                    },
                                    {
                                        "gamma": 1
                                    }
                                ]
                            },
                            {
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "hue": "#00FF6A"
                                    },
                                    {
                                        "saturation": -1.0989010989011234
                                    },
                                    {
                                        "lightness": 11.200000000000017
                                    },
                                    {
                                        "gamma": 1
                                    }
                                ]
                            }
                        ]
                    };

                    let options = customOptions;

                    let poiVarName = $container.data('google-map-pois');
                    let pois = [];

                    if (poiVarName) {
                        if (!window['_googleMapPois']) {
                            console.error('_googleMapPois is not set');
                        } else if (!window._googleMapPois[poiVarName]) {
                            console.error(poiVarName + ' is not set in _googleMapPois', _googleMapPois);
                        } else {
                            pois = _googleMapPois[poiVarName];
                        }
                    }

                    let mapObj = initMap({
                        element: $mapCanvas[0],
                        mapOptions: options,
                        poiStyles,
                        infoBoxOptions,
                        clustering: true,
                        clusteringOptions,
                        onActivateMarker,
                        onDeactivateMarker
                    });

                    setCustomUiControls(mapObj.map, $mapCanvas);


                    setMarker(mapObj, pois);
                }).catch(function (a,b,c) {
                    console.error(a,b,c);
                });
            }, {
                offset: window.innerHeight * 1.5
            })
        });
    }
}

function onActivateMarker(marker, mapObj) {
    let detailArea = $('.js-google-map__detail-area');


    if(marker.detailInfoBoxUrl) {
        let infoBoxPromise = showInfoBoxByMarker(
            marker,
            `<div class="my-2">
                <div class="loading-spinner" aria-label="Loading...">
                    <div class="loading-spinner__item loading-spinner__item--1"></div>
                    <div class="loading-spinner__item loading-spinner__item--2"></div>
                    <div class="loading-spinner__item loading-spinner__item--3"></div>
                </div>
            </div>`,
            mapObj
        );

        let contentPromise = $.ajax(marker.detailInfoBoxUrl, {
            cache: true,
            dataType: 'html',
            headers: {Ajax: 1}
        }).catch(function (a, b, c) {
            console.error(a, b, c);
        });

        contentPromise.then(function (infoBoxMarkup) {
            detailArea.html(infoBoxMarkup);
        });

        $.when(infoBoxPromise, contentPromise).then(function (infoBox, [infoBoxContent]) {
            if(infoBox.getVisible()) {
                // open the new info box with the new content only if the old one is still open
                infoBox.setContent(infoBoxContent);

                mapObj.map.panTo( marker.getPosition());
                mapObj.map.panBy(0, -140);
            }
        }); 
    }
}

function onDeactivateMarker(marker, mapObj) {
    closeInfoBoxByMarker(marker, mapObj);

    let detailArea = $('.js-google-map__detail-area');
    detailArea.html("");
}

function setCustomUiControls(map, $map) {
    $('.js-google-map__custom-zoom-in').on('click', function() {
        map.setZoom(++map.zoom);
    });

    $('.js-google-map__custom-zoom-out').on('click', function() {
        map.setZoom(--map.zoom);
    });
}