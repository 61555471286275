"use strict";

export function initInScope ($scope) {
    $scope.on('click', '.isClickable', function(evt){
        let $target = $(evt.target);

        if (!$target.is('a, button, input, select, label')
            && !$target.closest('a, button, input, select, label').length
        ) {
            let a = $(this).find('a');
            let href = $(a).attr('href');
            let target = $(a).attr('target');

            if(href){
                if( target === '_blank' ){
                    window.open( href );
                }else{
                    window.location.href = href;
                }

                evt.preventDefault();
                evt.stopImmediatePropagation();
            }
        }
    });
}
