"use strict";

import {isParsleyForm, isValid} from "./parsley";
import {getServicePermission} from "./cookiePermissions";

let trackingFunctions = {
    event: trackEventByObject
};

export default function () {
    if (_config.initialTracking) {
        _config.initialTracking.forEach(function (obj) {
            if (!trackingFunctions[obj.type]) {
                console.warn('Can\'t track initial events from _config.initialTracking. Type "' + obj.type + '" is not supported. Please use one of the following supported types: ' + Object.keys(trackingFunctions).join(', '));
                return;
            }

            trackingFunctions[obj.type](obj.data)
        });
    }

    initAutoTrack();

    // track all tel-links
    $('[href^="tel:"]').on('click', function (e) {
        let href = $(this).attr('href');
        trackEvent('Outbound', 'Telefon', href.replace('tel:', ''))
    });

    // track all mailto-links
    $('[href^="mailto:"]').on('click', function (e) {
        let href = $(this).attr('href');
        trackEvent('Outbound', 'Mail', href.replace('mailto:', ''))
    });
}

export function initAutoTrack() {
    if (getServicePermission('google')) {
        import('autotrack/autotrack.js');
    }
}

export function initInScope($scope) {
    $scope.find('.js-tracking--click').on('click', function () {
        let $this = $(this);

        trackEvent(
            $this.data('tracking-category'),
            $this.data('tracking-action'),
            $this.data('tracking-label'),
            $this.data('tracking-value')
        );

    });

    $scope.find('.js-tracking--click-piwik').on('click', function () {
        let $this = $(this);

        trackPiwikEvent(
            $this.data('tracking-category'),
            $this.data('tracking-action'),
            $this.data('tracking-label'),
            $this.data('tracking-value')
        );
    });

    $scope.find('.js-tracking--submit').on('submit', function () {
        let $this = $(this);

        if (isParsleyForm($this)) {
            if (isValid($this)) {
                trackEvent(
                    $this.data('tracking-category'),
                    $this.data('tracking-action'),
                    $this.data('tracking-label'),
                    $this.data('tracking-value')
                );
            }
            ;
        } else {
            trackEvent(
                $this.data('tracking-category'),
                $this.data('tracking-action'),
                $this.data('tracking-label'),
                $this.data('tracking-value')
            );
        }
    });

    $scope.find('.js-tracking--submit-gtm').on('submit', function () {
        let $this = $(this);
        if (isParsleyForm($this)) {
            if (isValid($this)) {
                trackGTMEvent($this.data('tracking-gtm'));
            }
        } else {
            trackGTMEvent($this.data('tracking-gtm'));
        }
    });

    
    $scope.find('.js-tracking--pageview').on('click', function() {
        let $this = $(this);
        let url = $this.attr('data-tracking-pageview-url');
        trackPageView(url);
    });


    $scope.find('.js-tracking--tab').on('shown.bs.tab', function () {
        let $this = $(this);
        let $container = $this.closest('.js-tracking--tab-container');
        let tabName = $this.text().trim();

        if ($container.length) {
            trackEvent(
                $container.data('tracking-category'),
                $container.data('tracking-action'),
                tabName
            );
        }

    });

    $scope.find('.js-ecommerce-tracking--click').on('click', function() {
        let dataLayerEcommerceObj = $(this).data('ecommerce-tracking') || false;

        if(dataLayerEcommerceObj) {
            let dataLayerData = {
                'event': 'productClick',
                'ecommerce': {
                    'click': dataLayerEcommerceObj
                }
            };

            trackGTMEvent(dataLayerData);

            console.log('GTM Tracking - dataLayer push:', dataLayerData);
        }
    });

    // if (window['Piwik'] && Piwik.FormAnalytics) {
    //     Piwik.FormAnalytics.scanForForms($scope[0])
    // }
}

export function trackEvent(category, action, label, value, nonInteraction) {
    category = decodeEntities(category);
    action = decodeEntities(action);
    label = decodeEntities(label);
    value = decodeEntities(value);

    if (getServicePermission('google')) {
        let params = [category, action, label, value, {
            nonInteraction: !!nonInteraction
        }
        ];

        // we have to remove empty values because ga ignores all params after an empty one.
        params = params.filter(x => !!x);

        ga('send', 'event', ...params);
    }

    // trackPiwikEvent(category, action, label, value);
}

export function trackPageView(pageUrl) {

    if (getServicePermission('google')) {
        ga('send', 'pageview', pageUrl);
    }

}

export function trackPiwikEvent(category, action, label, value) {
    category = decodeEntities(category);
    action = decodeEntities(action);
    label = decodeEntities(label);
    value = decodeEntities(value);

    if (getServicePermission('matomo')) {
        _paq.push(['trackEvent', category, action, label, value]);
    }
}

// data must be an object e.g. {"event":"eventName"}
export function trackGTMEvent(data) {
    if (getServicePermission('GoogleTagManagerRemarketing')) {
        console.log('GTM Tracking - dataLayer push:', data);
        console.log('GTM Tracking - dataLayer push:', data.toString());

        window.dataLayer.push(data);
    }
}

function trackEventByObject(data) {
    trackEvent(data.category, data.action, data.label, data.value, data.nonInteraction);
}


export function trackFormSubmit(element) {
    if (getServicePermission('matomo')) {
        _paq.push(['FormAnalytics::trackFormSubmit', element]);
    }
}

export function getTrackingData(element) {
    let data = $(element).data();
    return Object.keys(data).filter(function (key) {
        return key.indexOf('tracking') === 0;
    }).reduce(function (obj, key) {
        let newKey = key.replace('tracking', '').toLowerCase();
        obj[newKey] = decodeEntities(data[key]);
        return obj;
    }, {});
}

if (!window['ga'] || typeof window['ga'] !== 'function') {
    window.ga = function (...params) {
        params = params.map(JSON.stringify);
        console.log('Google Analytics Tracking: ', ...params);
    }
}

if (!window['_paq']) {
    window._paq = {
        push: function (...params) {
            let vals = params[0].filter(x => !!x);
            console.log("_paq.push(['" + vals.join("', '") + "']);");
        }
    };
}
if (!window['dataLayer']) {
    window.dataLayer = window.dataLayer || [];
}

function parseFormattedNumberString(string) {
    return string
        ? +string.toString().replace(',', '')
        : 0;
}

function decodeEntities(encodedString) {
    if (typeof encodedString !== 'string') {
        return encodedString;
    }

    let textArea = document.createElement('textarea');
    textArea.innerHTML = encodedString;
    return textArea.value;
}

function decodeEntitiesOfObject(object) {
    if (typeof object !== 'object') {
        return object;
    }

    return Object.entries(object).reduce((accum, [key, value]) => ({
        ...accum,
        [key]: decodeEntities(value)
    }), {});
}
