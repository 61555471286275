"use strict";
import "../libs/jquery.mask";

console.log("keycard test");
export function initInScope($scope) {

    $scope.find('.js-keycard').each(function() {
        let $keycardInput = $(this);

        var options = {
            onKeyPress:function(us) {
                let masks = ["00-0000 0000 0000 0000 0000-0", "U000000", "A000000", "D000000", "B000000", "C000000", "E000000", "F000000"];
                if(us.length){
                    let mask;
                    if(us[0].match(/\d/)){
                        mask = masks[0];
                        $keycardInput.attr('data-parsley-length','[29,29]');
                    } else {
                        if(us[0].toLowerCase().match(/u/)) {
                            mask = masks[1];
                            $keycardInput.attr('data-parsley-length','[6,7]');
                        }
                        else if(us[0].toLowerCase().match(/a/)) {
                            mask = masks[2];
                            $keycardInput.attr('data-parsley-length','[5,7]');
                        }
                        else if(us[0].toLowerCase().match(/d/)) {
                            mask = masks[3];
                            $keycardInput.attr('data-parsley-length','[6,7]');
                        }
                        else if(us[0].toLowerCase().match(/b/)) {
                            mask = masks[4];
                            $keycardInput.attr('data-parsley-length','[7,7]');
                        }
                        else if(us[0].toLowerCase().match(/c/)) {
                            mask = masks[5];
                            $keycardInput.attr('data-parsley-length','[7,7]');
                        } else if(us[0].toLowerCase().match(/e/)) {
                            mask = masks[6];
                            $keycardInput.attr('data-parsley-length','[6,7]');
                        } else if(us[0].toLowerCase().match(/f/)) {
                            mask = masks[7];
                            $keycardInput.attr('data-parsley-length','[7,7]');
                        }
                    }
                    $keycardInput.mask(mask, $(this));
                }
            },
            onChange: function (input) {
                $keycardInput.mask("A", options);
            },
            reverse:true
        };

        let kValue = false;
        $keycardInput.on('input change keypress', function(evt) {
            kValue = $keycardInput.val();
            if($(this).val().length === 0) {
                $keycardInput.val("");
                $keycardInput.mask("A", options);
            }
            // if($(this).val().length === 29) {
            //     kValue = $keycardInput.val();
            // }
        });

        // hacky.... but was necessary to prevent typeahead to remove "-" from keycard number
        $keycardInput.on('blur', function(evt) {
            if($keycardInput.hasClass('js-typeahead-keycardnr') && kValue && $(this).val().length < 29) {
                $keycardInput.val(kValue);
            }
        });

        $keycardInput.on('typeahead:autocomplete typeahead:select', function() {
            $(this).mask("A", options);
            $(this).attr('data-parsley-length','[5,29]');
            $(this).attr('maxlength','29');
        });

        $keycardInput.on('paste', function(e) {
            $(this).val('');
            $(this).attr('maxlength','29');
            $(this).attr('data-parsley-length','[5,29]');
            $(this).unmask();
        });

        if($keycardInput.val() === '') {
            $keycardInput.mask("A", options);
        } else {
            let input = $keycardInput.val()[0];
            if(typeof input != 'undefined' && input.length){
                $keycardInput.mask(getMask(input, $keycardInput), options);
            }
        }
    });

}

function getMask(input, $keycardInput) {
    let masks = ["00-0000 0000 0000 0000 0000-0", "U000000", "A000000", "D000000", "B000000", "C000000", "E000000", "F000000"];
    let mask;
    if(input.match(/\d/)){
        mask = masks[0];
        $keycardInput.attr('data-parsley-length','[29,29]');
    } else {
        if(input.toLowerCase().match(/u/)) {
            mask = masks[1];
            $keycardInput.attr('data-parsley-length','[6,7]');
        }
        else if(input.toLowerCase().match(/a/)) {
            mask = masks[2];
            $keycardInput.attr('data-parsley-length','[5,7]');
        }
        else if(input.toLowerCase().match(/d/)) {
            mask = masks[3];
            $keycardInput.attr('data-parsley-length','[6,7]');
        }
        else if(input.toLowerCase().match(/b/)) {
            mask = masks[4];
            $keycardInput.attr('data-parsley-length','[7,7]');
        }
        else if(input.toLowerCase().match(/c/)) {
            mask = masks[5];
            $keycardInput.attr('data-parsley-length','[7,7]');
        }
        else if(input.toLowerCase().match(/e/)) {
            mask = masks[6];
            $keycardInput.attr('data-parsley-length','[6,7]');
        }else if(input.toLowerCase().match(/f/)) {
            mask = masks[7];
            $keycardInput.attr('data-parsley-length','[7,7]');
        }
    }
    return mask;
}


export function isValidKeycard(value) {
    return !!((typeof value != 'undefined' && value.length) && (value.toLowerCase().match(/^[0-9]\d/) || (value.toLowerCase().match(/^(u|a|b|c|d|e|f)\d/))));
}