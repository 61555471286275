 import 'popper.js';
import 'bootstrap';

if (!window.HTMLPictureElement) {
    import('picturefill');
}

let _config = window['_config'] || {};

// let app = {
//     debug: false,
// };

let app = window.app || {};
window.app = app || {};
window.app.modules =  app.modules || {};

import initModulesInScope from "./scripts/initModulesInScope";
app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('./scripts/loadScript').then(function (loadScript) {
        return loadScript.default(url);
    });
};

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

import './shared/common';

import * as slickslider from './scripts/slickslider';
app.modules.slickslider = slickslider.initInScope;

import * as thumbSlider from './scripts/thumbSlider';
app.modules.thumbSlider = thumbSlider.initInScope;

import * as updateTopicFilter from './scripts/updateTopicFilter';
app.modules.updateTopicFilter = updateTopicFilter.initInScope;

import * as panomax from './scripts/panomax';
app.modules.panomax = panomax.initInScope;

import * as priceList from './scripts/priceList';
app.priceList = priceList.init;

import * as stepForm from './scripts/stepForm';
app.modules.stepForm = stepForm.initInScope;

import * as addTemplate from './scripts/addTemplate';
app.modules.addTemplate = addTemplate.initInScope;

import * as selectTopics from './scripts/selectTopics';
app.modules.selectTopics = selectTopics.initInScope;

import * as countdown from './scripts/countdown.js';
app.modules.countdown = countdown.initInScope;

import * as showAllTeasers from './scripts/showAllTeasers';
app.modules.showAllTeasers = showAllTeasers.initInScope;

import * as animationStart from './scripts/animationStart';
app.modules.animationStart = animationStart.initInScope;

import * as inView from './scripts/inView';
app.modules.inView = inView.initInScope;

 import * as isVideoInView from './scripts/isVideoInView';
 app.modules.isVideoInView = isVideoInView.initInScope;

import * as fileUpload from './scripts/fileUpload';
app.modules.fileUpload = fileUpload.initInScope;

import * as waypoints from './scripts/waypoints';
app.modules.waypoints = waypoints.initInScope;

import * as animatedList from './scripts/animatedList';
app.modules.animatedList = animatedList.initInScope;

import * as googleMap from './scripts/googleMap';
app.modules.googleMap = googleMap.initInScope;

import * as wallsIo from './scripts/wallsIo';
app.modules.wallsIo = wallsIo.initInScope;

import * as personalization from './scripts/personalization';
app.modules.personalization = personalization.initInScope;

import * as paymentForm from './scripts/paymentForm';
app.modules.paymentForm = paymentForm.initInScope;

import * as priceTendency from './scripts/priceTendency';
app.modules.priceTendency = priceTendency.initInScope;

app.filterForm = function () {
    import('./scripts/filter-form/filter-form').then(function (filterForm) {
        app.modules.filterForm = filterForm.initInScope;

        filterForm.default();
        filterForm.initInScope($('body'));
    });

    import('./scripts/filter-form/quick-filter').then(function (quickFilter) {
        app.modules.filterFormquickFilter = quickFilter.initInScope;
        quickFilter.initInScope($('body'));
    });
};

app.panorama = () => {
    import('./scripts/panorama')
        .then(panorama => panorama.init())
        .catch(console.error);
};

import * as quickfinder from './scripts/quickfinder';
app.quickfinder = quickfinder.init();
app.modules.quickfinder = quickfinder.initInScope;

import * as sortable from './scripts/sortable';
app.modules.sortable = sortable.initInScope;

import * as toggleActive from './scripts/toggleActive';
app.modules.toggleActive = toggleActive.initInScope;

import * as enableOnFormValidate from './scripts/enableOnFormValidate';
app.modules.enableOnFormValidate = enableOnFormValidate.initInScope;

import * as enableOnFormValidateAbTestCheckout from './scripts/enableOnFormValidateAbTestCheckout';
app.modules.enableOnFormValidateAbTestCheckout = enableOnFormValidateAbTestCheckout.initInScope;

import {getStore} from './scripts/redux/store.js';
app.store = getStore();

import * as skiTicketConfig from './scripts/skiTicketConfig';
app.modules.skiTicketConfig = skiTicketConfig.initInScope;

import * as eventTicketConfig from './scripts/eventTicketConfig';
app.modules.eventTicketConfig = eventTicketConfig.initInScope;

import * as eventTicketListConfig from './scripts/eventTicketListConfig';
app.modules.eventTicketListConfig = eventTicketListConfig.initInScope;

import * as eventTicketCombiConfig from './scripts/eventTicketCombiConfig';
app.modules.eventTicketCombiConfig = eventTicketCombiConfig.initInScope;

import * as eventTicketMultipleConfig from './scripts/eventTicketMultipleConfig';
app.modules.eventTicketMultipleConfig = eventTicketMultipleConfig.initInScope;

import * as showIfChecked from './scripts/showIfChecked';
app.modules.showIfChecked = showIfChecked.initInScope;

import * as multiSelect from './scripts/multiSelect';
app.modules.multiSelect = multiSelect.initInScope;

import * as stickyAddToCartBtn from './scripts/stickyAddToCartBtn';
app.modules.stickyAddToCartBtn = stickyAddToCartBtn.initInScope;


import * as cashbackForm from './scripts/cashbackForm';
app.modules.cashbackForm = cashbackForm.initInScope;

import * as bubbleOverlay from './scripts/bubble-overlay';
app.modules.bubbleOverlay = bubbleOverlay.initInScope;

 import * as reloadForm from "@elements/reload-form";
 app.modules.reloadForm = reloadForm.initInScope;

 import * as buttonFormAttributPolyfill from "./scripts/buttonFormAttributPolyfill";
 _config.buttonFormAttributPolyfill = true;
 app.modules.buttonFormAttributPolyfill = buttonFormAttributPolyfill.initInScope;

 import * as reactSelect from './scripts/react-select';
 app.modules.reactSelect = reactSelect.initInScope;

 import * as fullHeight from './scripts/full-height';
 app.fullHeight = fullHeight.init;


 (function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }

    initModulesInScope();
})(jQuery);