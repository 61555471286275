"use strict";

let currentId = 1;
export function initInScope ($scope) {
    let $videos = $scope.find('.js-video-js');
    import('video.js').then(function (videojs){
        $videos.each(function () {
            let id = $(this).prop('id');
            if (!id) {
                id = 'video-js-id-' + currentId++;
                $(this).prop('id', id);
            }
            let player = videojs.default(id);
            player.controlBar.liveDisplay.show();

        });
    });
}
