"use strict";

import {collapseCheckboxShow} from "./collapseCheckbox";

export function initInScope ($scope) {
    let $forms = $scope.find('.js-parsley');

    loadParsley().then(function () {

        if (_config.deliveryCountries) {
            let deliveryCountries = _config.deliveryCountries;

            if (!Parsley.hasValidator('deliveryCountry')) {
                Parsley.addValidator('deliveryCountry', {
                    validateString: function (value, arg2, field) {
                        if (!deliveryCountries.includes(value)) {
                            collapseCheckboxShow();
                        }
                        return deliveryCountries.includes(value);
                    }
                });
            }
        }

        // checks file size of every single selected file
        if (!Parsley.hasValidator('maxFileSize')) {
            Parsley.addValidator('maxFileSize', {
                validateString: function(_value, maxSize, parsleyInstance) {
                    let files = parsleyInstance.$element[0].files;
                    for(let x = 0; x < files.length; x++) {
                        if(files[x].size >  maxSize * 1024) {
                            return false;
                        }
                    }
                },
                messages: {
                    de: "Maximale Dateigröße:" + ' %s kb.',
                    en: "Max file size:" + ' %s kb.'
                }
            });
        }

        $forms.parsley(options);

        $forms.each(function () {
            let $form = $(this);

            $form.parsley().on('field:error', function () {
                let parsleyId = this.__id__;
                let fieldClass = this.__class__;

                if (fieldClass === 'ParsleyFieldMultiple') {
                    parsleyId = 'multiple-' + this.$element.data('parsley-multiple');
                }

                this.$element.attr({
                    'aria-invalid': "true",
                    'aria-describedby': 'parsley-id-' + parsleyId
                });
            });

            $form.parsley().on('field:success', function () {
                if (this.$element.hasClass("select") && this.$element.val() !== "") {
                    this.$element.closest(".form-group, .js-form-group").addClass("has-success");
                }
                this.$element.removeAttr('aria-invalid aria-describedby');
            });
        });

    });
}

export const options = {
    successClass: 'has-success',
    errorClass: 'has-error',
    classHandler: function (_el) {
        // console.log("element", _el, _el.$element.closest('.form-group, .js-form-group'));
        return _el.$element.closest('.form-group, .js-form-group');
    },
    errorsContainer: function (_el) {
        let errorContainer = _el.$element.closest('.form-group, .js-parsley__error-group').find('.form-errors, .js-form-errors');
        if (errorContainer && errorContainer.length > 0) {
            console.log(_el);
            return errorContainer;
        }
    },
    // nur felder validieren die sichtbar sind
    excluded: "input[type=button], input[type=submit], input[type=reset], input[type=hidden], [disabled], :hidden"
};

let promise;
export function loadParsley() {
    if (promise) {
        return promise;
    }

    promise = new Promise(function (resolve, reject) {
        import('parsleyjs').then(function () {
            if (_config.lang && _config.lang !== 'en') {
                import(/* webpackChunkName: "parsley-lang-package-" */'parsleyjs/dist/i18n/' + _config.lang + '.js').then(function () {
                    resolve();
                }).catch(function (reason) {
                    /*fallback if there is no package*/
                    resolve();
                });
            } else {
                resolve();
            }
        }).catch(function (reason) {
            reject();
        });
    });

    return promise;
}

export function isParsleyForm ($form) {
    return $form.is('.js-parsley');
}

export function isValid($form) {
    if (!$form.data('Parsley')) {
        /* if the form is not initialized */
        $form.parsley(options);
    }

    return $form.data('Parsley').validate();
}