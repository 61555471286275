"use strict";

let isRunning = false;
let $addToCartBtn;
let $watchElement;

export function initInScope($scope) {
    // only on mobile
    if (matchMedia('(min-width: 768px)').matches) {
        return;
    }
    let $addToCartBtnLocal = $scope.find('.js-sticky-add-to-cart-btn');

    if ($addToCartBtnLocal.length) {
        $addToCartBtn = $addToCartBtnLocal;
    }
    let watchElementSelector = $addToCartBtn.data('watch-element');

    if (!watchElementSelector) {
        console.error('"data-watch-element" is not defined for js-sticky-add-to-cart-btn');
        return;
    }

    $watchElement = $(watchElementSelector);
    // if (!isRunning) {
    isRunning = true;
    lastScrollPosition = 0;
    requestAnimationFrame(interval);
    // }
}

let lastScrollPosition = 0;
export function interval() {
    if (lastScrollPosition === window.pageYOffset) {
        requestAnimationFrame(interval);
        return;
    }

    lastScrollPosition = window.pageYOffset;
    if ($watchElement[0].getBoundingClientRect().top < 70) {
        $addToCartBtn.addClass('show');
        $('body').addClass('sticky-add-to-cart');
    } else {
        $addToCartBtn.removeClass('show');
        $('body').removeClass('sticky-add-to-cart');
    }
    requestAnimationFrame(interval);
}
