/** @jsx h */

import {h} from "preact";
import {noop} from "../../utils";
import {translate} from '@elements/translations';
import NumberSpinner from "../NumberSpinner";

export default function EventTicketConfigurationForm({
     ageGroups = [],
     selectedCurrencyId,
     currencies = [],
     availability = null,
     onChangeTicketCounter = noop,
     title,
     status = null,
     ticketCounter,
     prices,
 }) {

    const getTotalOfAgeGroup = (ageGroupId) => {
        let subtotal = 0;
        if(ticketCounter[ageGroupId]) {
            subtotal = prices[ageGroupId][selectedCurrencyId] * ticketCounter[ageGroupId];
        }
        return subtotal;
    };

    const getTotalOfAgeGroups = () => {
        let total = 0;
        ageGroups.allIds.map((ageGroupId) => {
            total += getTotalOfAgeGroup(ageGroupId);
        });
        return total;
    };

    const getTicketCount = (currentAgeGroupId) => {
        let count = 0;
        ageGroups.allIds.map(function(ageGroupId) {
            if(ageGroupId !== currentAgeGroupId) {
                count += ticketCounter[ageGroupId];
            }
        });

        return count;
    };

    // Render
    return (
        <form className="ticket-configuration__box mt-4">

            <div className="ticket-configuration__box-header">
                <div className="row">
                    <div className="col-md-8">
                        {title}
                    </div>
                    <div className="col-md-4 text-md-right">
                        <span className={`status mr-2 status--small status--${status}`}/>
                        {+availability === 0 ? translate('event-ticket.ausgebucht') : availability + " " + translate('event-ticket.Plätze frei') }
                    </div>
                </div>
            </div>

            <div className="ticket-configuration__table">
                <div className="ticket-configuration__table-header row flex d-none">
                    <div className="col-md-4">
                        {translate('event-ticket.Personen')}
                    </div>
                    <div className="col-md-2">
                        {translate('event-ticket.Anzahl')}
                    </div>
                    <div className="col-md-3">
                        {translate('event-ticket.Einzelpreis')}
                    </div>
                    <div className="col-md-3 text-right">
                        {translate('event-ticket.Gesamtpreis')}
                    </div>
                </div>
                { ageGroups.allIds.map((ageGroup) => (
                    <div className="row mb-4">
                        <div className="col-md-4 col-6">
                            {ageGroups.byId[ageGroup].name}
                        </div>
                        <div className="col-md-2 col-6">
                            <NumberSpinner
                                value={ticketCounter[ageGroup]}
                                maxValue={availability - getTicketCount(ageGroup)}
                                onChange={(value) => {
                                    onChangeTicketCounter(ticketCounter => {
                                        return {...ticketCounter, [ageGroup]: value}
                                    })
                                }}
                            />
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="d-md-none">{translate('event-ticket.Einzelpreis')}</div>
                            {currencies.byId[selectedCurrencyId].name + " " + prices[ageGroup][selectedCurrencyId].toFixed(2)}
                        </div>
                        <div className="col-md-3 text-md-right col-6">
                            <div className="d-md-none">{translate('event-ticket.Gesamtpreis')}</div>
                            {currencies.byId[selectedCurrencyId].name + " " + getTotalOfAgeGroup(ageGroup).toFixed(2)}
                        </div>
                    </div>
                ))}
            </div>

            <div className="ticket-configuration__box-footer">
                <div className="row">
                    <div className="col-8">
                        {translate('event-ticket.Gesamtsumme')}
                    </div>
                    <div className="text-md-right col-4">
                        {currencies.byId[selectedCurrencyId].name + " " + getTotalOfAgeGroups().toFixed(2) }
                    </div>
                </div>
            </div>

        </form>
    );

};