"use strict";

import * as Chartist from "chartist";
import ChartistTooltipPlugin from "chartist-plugin-tooltip";
import {requireConfigs, getConfig} from "@elements/config-utils";
import fetch from '@elements/fetch';

export function initInScope($scope) {
    const $chart = $scope.find('.js-price-tendency__chart');

    $chart.each(function() {
        let $this = $(this);
        initChart($this);
    });

}

function initChart($element) {
    let url = $element.data('price-tendency-url');
    let pendingRequest = fetch(url);

    pendingRequest.then((data) => {
        return data.clone().json()
    }).then((data) => {
        drawGraph($element[0], data);
    }).catch((error) => {
        if (error.name !== 'AbortError') {
            console.error(error);
        }
    });
}
let yLabels = [
    'low',
    'average',
    'high'
];
function drawGraph(domNode, data) {
    let isMobile = matchMedia('(max-width: 767px)').matches;
    let options = {
        fullWidth: true,
        height: 130,
        lineSmooth: true,
        showArea: false,
        divisor: 3,
        axisY: {
            offset: 0,
            labelOffset: {
                x: isMobile ? -20 : -55,
                y: isMobile ? 16 : 5
            },
            onlyInteger: true,
            labelInterpolationFnc: function(value, index) {
                return yLabels[index];
            },
            high: 3,
            low: 1,
            stretch: true,
            showLabel: true
        },
        axisX: {
            labelOffset: {
                x: -15,
                y: -10
            },
            position: 'start'
        },
        chartPadding: {
            top: isMobile ? 10 : 20,
            right: isMobile ? 20 : 40,
            bottom: 30,
            left: isMobile ? 20 : 55
        }
    };

    let chart = new Chartist.Line(domNode, data, options);

    chart.on('draw', function (data) {
        if (data.type === 'line' || data.type === 'area') {
            data.element.animate({
                d: {
                    begin: 50 * data.index,
                    dur: 300 * Math.pow(.9, data.index),
                    from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
                    to: data.path.clone().stringify(),
                    easing: Chartist.Svg.Easing.easeOutQuint
                }
            });
        }
    });

    return data;
}

function isUrl(url) {
    return url && typeof url === 'string' && (url.includes('/') || url.includes('#'));
}
