"use strict";

let subscriptions = [];
let impressionInterval;
let defaultSettings = {
    offset: 0
};
export function onEnterViewPort ($newElements, callback, settings) {
    $newElements.each(function (index) {
        subscriptions.push({
            $element: $newElements.eq(index),
            callback: callback,
            settings: $.extend({}, defaultSettings, settings)
        });
    });

    if(!impressionInterval) {
        impressionInterval = setInterval(function () {
            subscriptions = subscriptions.filter(function (subscription) {
                return isElementInDom(subscription.$element);
            });

            let inViewport = subscriptions.filter(function (subscription) {
                return isElementInViewport(subscription.$element, subscription.settings.offset);
            });

            subscriptions = subscriptions.filter(function (subscription) {
                return !isElementInViewport(subscription.$element, subscription.settings.offset);
            });

            inViewport.forEach(function (subscription) {
                subscription.callback.call(subscription.$element, subscription.$element);
            });

            if (subscriptions.length === 0) {
                clearInterval(impressionInterval);
                impressionInterval = null;
            }
        }, 200);
    }
}

export function isElementInViewport (el, offset = 0) {
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    let rect = el.getBoundingClientRect();

    if (!isElementVisible(el) ) {
        return false;
    }

    return (
        rect.top + offset >= 0 &&
        rect.bottom - offset <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
        rect.left <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
    );
}

export function isElementVisible(el) {
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    let rect = el.getBoundingClientRect();

    return !(rect.top === 0 &&
        rect.bottom === 0 &&
        rect.height === 0 &&
        rect.width === 0
    );
}

export function isElementInDom(el) {
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    return document.body.contains(el);
}