"use strict";

export function initInScope ($scope) {
    let $shariff = $scope.find('.js-shariff');
    $shariff.data('title', location.href);
    $shariff.data('mail-body', location.href);
    $shariff.data('mail-subject', document.title);

    if ($shariff && $shariff.length) {
        import('shariff').then(function () {
            new Shariff($shariff, {
                //do stuff
            });
        });
    }

}
