'use strict';
import asyncAppend from "./async-append";

export function init() {
    let $priceList = $('.js-price-list');
    let timeOut = $priceList.data('price-list-time-out');

    if (!_config.priceListUrls) {
       console.warn(new Error('Can\'t fetch price-list. _config.priceListUrls is not set.'));
    }

    let priceListUrlArray = _config.priceListUrls;

    let newLoopTimer = new LoopTimer(function (time) {
        let element = priceListUrlArray.shift();
        asyncAppendPriceLists(element);
        priceListUrlArray.push(element);
    }, timeOut);

    newLoopTimer.start();
}

// Timer 
function LoopTimer(render, interval) {
    let timeout;
    let lastTime;

    this.start = startLoop;

    // Start Loop
    function startLoop() {
        timeout = setTimeout(createLoop, 0);
        lastTime = Date.now();
        return lastTime;
    }

    // The actual loop
    function createLoop() {
        let thisTime = Date.now();
        let loopTime = thisTime - lastTime;
        let delay = Math.max(interval - loopTime, 0);
        timeout = setTimeout(createLoop, delay);
        lastTime = thisTime + delay;
        render(thisTime);
    }
}
function asyncAppendPriceLists(url) {
    asyncAppend({
        $target: $('.js-price-list__result'),
        $loading: $('.js-price-list__loading'),
        $notifications: $('.js-price-list__notifications')
    }, fetch(url));
}