"use strict";

import initModulesInScope from "./initModulesInScope";
import {shoppingListChangeHandler} from "./cartOverlay";
import {updateNotificationUI, animateNotification} from "./shoppingList";
import {trackEvent} from "./tracking";

let pendingRequest;


export function initInScope($scope) {
    let $cart = $scope.find('.js-cart'),
        $cartButton = $scope.find('.js-cart__button'),
        $cartLogin = $scope.find('.js-cart__login'),
        $cartDefault = $scope.find('.js-cart__default'),
        $cartEditButton = $scope.find('.js-cart__edit-button'),
        $cartLoginAffix = $scope.find('.js-cart__login--add-affix'),
        $cartTicketEdit = $scope.find('.js-cart__edit'),
        $cartEdit = $scope.find('.js-cart__edit-text');


    // hide default text, show login and set focus on Input
    $cartButton.on('click', function(evt) {
        evt.preventDefault();
        $cartLogin.attr('hidden', false);
        $cartDefault.attr('hidden', true);
        if (matchMedia('(min-width: 768px)').matches ) {
            $cartLoginAffix.addClass('js-affix');
            initModulesInScope($cart);
            initModulesInScope($cartLogin);
        }
        $('html, body').animate({
            scrollTop: $cartLogin.offset().top - 100
        }, 500);

        $cartEdit.attr('hidden', false);
        $cartTicketEdit.addClass('ticket-overlay--disabled');
        //shoppingListChangeHandler();
    });

    $cartEdit.on('click', function() {
        $cartTicketEdit.removeClass('ticket-overlay--disabled');
        $(this).attr('hidden', true);
        shoppingListChangeHandler();
    });

    // edit a cart element
    $cartEditButton.on('click', function() {
        $cartTicketEdit.removeClass('ticket-overlay--edit');
        $cartTicketEdit.addClass('ticket-overlay--disabled');
        let $container = $(this).closest('.js-cart__edit');
        $container.addClass('ticket-overlay--edit');
        $container.find('.js-cart__edit-content').attr('hidden', false);
        $container.find('.js-cart__edit-preview').attr('hidden', true);
        editAction($scope);
    });

    removeAction($scope);
}


function editAction($scope) {
    let $editForm = $scope.find('.js-cart__edit-form');

    $editForm.on('submit', function(evt) {

        $.ajax({
            type        : 'POST',
            url         : $editForm.data('ajax-action'),
            data        : $editForm.serialize(),
            dataType    : 'json',
            encode          : true
        })
            .done(function(data) {
                shoppingListChangeHandler();
            });
        evt.preventDefault();
    });
}

function removeAction ($scope) {
    let $removeForm = $scope.find('.js-cart__remove-form');

    $removeForm.on("submit", function(evt) {
        // process the form
        $.ajax({
            type        : 'POST',
            url         : $(this).data('ajax-action'),
            data        : {'key' : $(this).data('item-to-remove') },
            dataType    : 'json',
            encode          : true
        })
            .done(function(data) {
                let checkoutable = data.checkoutable;
                if(!checkoutable) {
                   $('.js-cart__button').addClass('disabled');
                } else {
                   $('.js-cart__button').removeClass('disabled');
                }
                shoppingListChangeHandler();
                updateNotificationUI(data.counter);
                animateNotification();
                if ($removeForm.hasClass('js-tracking--remove-ticket')) {
                    trackEvent(
                        $removeForm.data('tracking-category'),
                        $removeForm.data('tracking-action'),
                        $removeForm.data('tracking-label')
                    );
                }
            });

        evt.preventDefault();


    });
}
