"use strict";

export function initInScope ($scope) {
    import('jquery-inview').then(function() {
        let animatedList = $scope.find('.js-animated-list');


        animatedList.on('inview', function(event, isInView) {
            let timer = 0;

            let animatedListItem = $(this).find('.js-animated-list__item');
            animatedListItem.each(function() {
                let $self = $(this);
                if(isInView) {
                    setTimeout(function() {
                        $self.addClass('inview-reveal');
                    }, 100 + timer);
                }
                timer = timer + 400;
            });

        });

    });
}
