
"use strict";

import initModulesInScope from "./initModulesInScope";
import {trackEvent} from "./tracking";

let pendingRequest;
export function initInScope($scope) {
    let $submit = $scope.find('.js-load-next-step__submit'),
        $error = $scope.find('.js-load-next-step__error');

    $submit.on('click', function(evt) {
        evt.preventDefault();
        let $this = $(this),
            $form = $(this).closest('.js-load-next-step'),
            $loading = $form.closest('.modal').find('.js-load-next-step__loading'),
            url =  $form.attr('data-ajax-next-step'),
            $resultContainer = $(this).closest('.modal').find('.js-load-next-step__result');

        let $validateForm = $form.is('form') ? $form : $form.find('.js-parsley');

        if($validateForm.parsley().validate()){
            $resultContainer.addClass('is-loading');
            $loading.attr('hidden', null).removeClass('hidden');

            if (pendingRequest) {
                pendingRequest.abort();
            }
            let type = $form.is('form') ? $form.attr('method') : $form.attr('data-method');

            let formData = new FormData($validateForm[0]);
            
            // tracking
            let $deliveryTracking = $scope.find('.js-tracking--delivery-tabs');
            trackingDeliveryOption($deliveryTracking);


            pendingRequest = $.ajax(url, {
                method: type,
                data: formData,
                processData:false,
                contentType:false,
                headers: {Ajax: 1}
            }).done(function (result) {
                let $result = $(result);
                $resultContainer.empty();
                $resultContainer.append($result);
                initModulesInScope($result);
                trackingAdditionalServices();

            }).fail(function (jqXHR, textStatus, errorThrown) {
                console.log(jqXHR, textStatus, errorThrown);
                let errors = jqXHR.responseJSON['errors'][0];

                for (let error in errors) {
                    console.log(error);
                    $error.append("<li>" + errors[error] + "</li>");
                }

                if (textStatus !== 'abort') {
                    $error.attr('hidden', null);
                    console.error(textStatus, errorThrown, jqXHR);
                }

            }).always(function () {
                pendingRequest = null;
                $resultContainer.removeClass('is-loading');
                $loading.attr('hidden', 'hidden');
            });
        }

    })

}


function trackingDeliveryOption($deliveryTracking) {
    if($deliveryTracking && $deliveryTracking.length != 0) {
        let label = $deliveryTracking.find('.js-tracking--delivery-option:checked').data('tracking-label');
        trackEvent(
            $deliveryTracking.data('tracking-category'),
            $deliveryTracking.data('tracking-action'),
            label
        );
    }
}

function trackingAdditionalServices() {
    let $form = $('.js-tracking--additional-service__form');
    $form.on('submit', function() {
        let label = $(this).find('.js-tracking--additional-service__input:checked').data('tracking-label');
        trackEvent(
            $form.data('tracking-category'),
            $form.data('tracking-action'),
            label
        );
    });

}