"use strict";
import loadScript from '@elements/load-script';

export function initInScope($scope) {

    loadScript("https://static.panomax.com/front/thumbnail/js/pmaxthumbnail.js").done(function () {
        $scope.find('.js-panomax').each(function () {
            let panomaxKey = $(this).attr('data-key');
            let panomaxId = $(this).attr('id');

            PmaxThumbnail.place({instance: panomaxKey, height: "408", id:panomaxId, map: false, instanceName: false, weather: false, instanceLogo: false, timelapse: false});
        });
    });
}
