"use strict";

export function initInScope($scope) {
    let $scrollToButton = $scope.find('.js-scroll-to__button');

    $scrollToButton.on('click', function() {
        let scrollToTarget = $(this).attr('data-scroll-target');
        let mobileOffset = $(this).attr('data-mobile-offset');
        let scrollOffset = (matchMedia('(max-width: 767px)').matches && mobileOffset) ? mobileOffset : 75;
        let duration = $(this).attr('data-scroll-duration') || 1000;

        if (scrollToTarget) {
            $('html, body').animate({
                scrollTop: $(scrollToTarget).offset().top - scrollOffset
            }, duration);
        } else {
            console.warn("No Data-scroll-target defined.");
        }
    });
}

export function scrollTo ($target, callback, doNotFocusAfterScroll) {
    if (!$target || !$target.length) {
        console.warn("Can't scroll to '$target'. Target element not found");
        return;
    }

    let duration = Math.abs(window.pageYOffset) / 8 + 400;

    $('html, body').stop().animate({
            scrollTop:  $target.offset().top - 75
        },
        duration,
        'swing', function () {

            if (!doNotFocusAfterScroll) {
                focus($target);
            }

            if (callback) {
                callback();
            }
        }
    );
}
