import {getNumberSpinnerValue} from "./numberSpinner";

export function initInScope($scope) {
    $scope.find('.js-number-spinner-group-enable-button').on('change input', function (group, i) {

        let $group = $(this);
        let $btnToDisable = $group.find($($group.data('number-spinner-group-enable-button')));

        let totalMin = +$group.data('number-spinner-group-enable-button-min');
        let $items = $group.find('.js-number-spinner-group__item');
        let hasError = false;

        let currentTotal = $items.toArray().reduce((total, input) => {
            if (typeof $(input).data('button') != 'undefined' && $(input).data('button') == $group.data('number-spinner-group-enable-button')) {
                console.error('Can\'t en-/disable buttons for number spinner group', $(input), 'disables the same button as the group');
                hasError = true;
            }
            return total + (+getNumberSpinnerValue($(input)));
        }, 0);

        if(!hasError) {
            if( currentTotal >= totalMin) {
                $btnToDisable.attr('disabled', false);
            } else {
                $btnToDisable.attr('disabled', true);
            }
        }
    })
}