"use strict";

import {trackEvent} from "./tracking";

let isLoggedIn;
let loginRequest;
let initialLikeListRequest;
export function init() {
    loginRequest = $.ajax('/de/auth/isloggedin');
    loginRequest.then((result) => {
        if (result && result.loggedIn) {
            isLoggedIn = true;
            initialLikeListRequest = $.ajax('/de/bookmarks/get-ids');
        }
    })
}


let pendingRequest;
export function initInScope($scope) {
    let $items = $scope.find('.js-like-list__item');
    let isAdded = false;

    loginRequest.then(function () {
        if (isLoggedIn) {
            initialLikeListRequest.then(function (result) {
                if (result && result.ids) {
                    result.ids.forEach(function (id) {
                        $items.filter(`[data-id="${id}"]`).addClass('is-liked').attr('aria-pressed', 'true');;
                    })
                }
            })
        }
    });

    document.querySelectorAll('.js-like-list__item').forEach(item => item.addEventListener('click',function() {
        let $item = $(this);
        let id = this.dataset.id;
        let actionUrl = $(this).attr('data-action-url');

        if (!id) {
            console.warn('Can\'t add item to link list. data-id was not set on ', this);
            return;
        }

        const trackCategory = $item.data('tracking-category');
        const trackAction = $item.data('tracking-action');
        const trackLabel = $item.data('tracking-label') + " + " + window.location.pathname || window.location.pathname;

        if (loginRequest) {
            loginRequest.then(() => {
                if (isLoggedIn) {
                    if ($item.hasClass('is-liked')) {
                        $item.removeClass('is-liked').attr('aria-pressed', 'false');
                        updateItem(id, actionUrl, $item, 'remove');
                    } else {
                        $item.addClass('is-liked').attr('aria-pressed', 'true');
                        updateItem(id, actionUrl, $item, 'add');
                        isAdded = true;
                        if ($item.hasClass('js-tracking--like-list')) {
                            trackEvent(
                                trackCategory + ' - eingeloggt',
                                trackAction,
                                trackLabel
                            );
                        }
                    }

                    if (!isAdded && $('.js-like-list__count').text() == 0) {
                        $('.js-overlay__toggle').trigger('click');
                    }
                } else {
                    location.href = $item.data('login-url');

                    if ($item.hasClass('js-tracking--like-list')) {
                        trackEvent(
                            trackCategory + ' - nicht eingeloggt',
                            trackAction,
                            trackLabel
                        );
                    }
                }
            })
        }
    }, {capture: true}));
}


function updateItem(id, url, $item, action) {
    if (pendingRequest) {
        pendingRequest.abort();
    }

    $item.addClass('is-pending');

    pendingRequest = $.ajax({
        url:url,
        data: {
            id: id,
            likeAction: action
        },
        dataType: 'json'
    }).done(function (response) {
        updateUI(response.counter);
    }).fail(function (jqXHR, textStatus, errorThrown) {
        if (textStatus !== 'abort') {
            console.error(textStatus, errorThrown, jqXHR);
        }
    }).always(function () {
        pendingRequest = null;
        $item.removeClass('is-pending');
    });

}

function updateUI(count) {
    $('.js-like-list__count').text(count).attr('hidden', count === 0 ? 'hidden' : null);
    animateNotification();
}

function animateNotification() {
    let $notificationCounter =  $('.js-like-list__count');
    $notificationCounter.addClass("notification-bounce");
    $notificationCounter.one('webkitAnimationEnd oanimationend msAnimationEnd animationend', function(e) {
        $notificationCounter.removeClass('notification-bounce');
    });
}