"use strict";

import {isParsleyForm, isValid} from "./parsley";

export function initInScope($scope) {

    let $birthdayTimestamp = $scope.find('.js-birthday-timestamp');
    let $form = $birthdayTimestamp.closest('form'),
        $yearSelect = $birthdayTimestamp.find('.js-birthday-timestamp__year'),
        $monthSelect = $birthdayTimestamp.find('.js-birthday-timestamp__month');

    validateYearMonth($yearSelect);
    validateMonthDay($monthSelect);


    //validation of year select
    $yearSelect.on('change', function (evt) {
        validateYearMonth($(this));
        validateMonthDay($(this));
    });


    //validation of month select
    $monthSelect.on('change', function (evt) {
        validateMonthDay($(this));
    });


    $form.on('submit', function (evt) {
        let $self = $(this);
        evt.preventDefault();

        if (!matchMedia('(max-width: 767px)').matches) {

            let day = $self.find('.js-birthday-timestamp__day').val();
            let month = $self.find('.js-birthday-timestamp__month').val();
            let year = $self.find('.js-birthday-timestamp__year').val();
            let $error = $self.find('.js-birthday-timestamp__error');
            let $timestamp = $self.find('.js-birthday-timestamp__result');

            if (isValidDate(day, month, year)) {
                $error.attr('hidden', true);
                let date = new Date(year, month, day);
                $timestamp.val(date.getTime());
                if (isParsleyForm($self) && isValid($self)) {
                    $self[0].submit();
                }
            } else {
                $error.attr('hidden', false);
            }
        } else {
            let $timestamp = $self.find('.js-birthday-timestamp__result');
            $birthdayTimestamp = $self.find('.js-birthday-timestamp');
            $timestamp.val((new Date($birthdayTimestamp.val()).getTime())/1000);
            if (isParsleyForm($self) && isValid($self)) {
                $self[0].submit();
            }
        }
    });



    function validateYearMonth($self) {
        let $birthdayTimestamp = $self.closest('.js-birthday-timestamp'),
            $result = $birthdayTimestamp.find('.js-birthday-timestamp__result'),
            minYear = $result.data('min-year'),
            minMonth = $result.data('min-month'),
            maxYear = $result.data('max-year'),
            maxMonth = $result.data('max-month'),
            monthSelect = $birthdayTimestamp.find('.js-birthday-timestamp__month'),
            daySelect = $birthdayTimestamp.find('.js-birthday-timestamp__day'),
            selectedYear = $birthdayTimestamp.find('.js-birthday-timestamp__year').val(),
            selectedOption = monthSelect.find("option:selected").val();


        if(minYear || maxYear) {
            if(selectedYear <= minYear) {
                monthSelect.find("option:not(:first-of-type)").prop('disabled', false);
                for (let i = 1; i < minMonth; i++) {
                    let index = i;

                    monthSelect.find("option[value=" + index + "]").prop('disabled', true);
                }

                if(selectedOption < minMonth) {
                    monthSelect.find("option:first-of-type").prop('selected', true);
                }
            } else if(selectedYear == maxYear) {
                monthSelect.find("option:not(:first-of-type)").prop('disabled', false);
                let maxOptions = monthSelect.find("option").length - 1;

                for (let i = maxMonth+1; i <= maxOptions; i++) {
                    let index = i;
                    monthSelect.find("option[value=" + index + "]").prop('disabled', true);
                }

                if(selectedOption > maxMonth) {
                    monthSelect.find("option:first-of-type").prop('selected', true);
                }

            }  else {
                monthSelect.find("option:not(:first-of-type)").prop('disabled', false);
                daySelect.find("option:not(:first-of-type)").prop('disabled', false);
            }
        }

    }

    function validateMonthDay($self) {
        let $birthdayTimestamp = $self.closest('.js-birthday-timestamp'),
            $result = $birthdayTimestamp.find('.js-birthday-timestamp__result'),
            minYear = $result.data('min-year'),
            minMonth = $result.data('min-month'),
            minDay = $result.data('min-day'),
            maxYear = $result.data('max-year'),
            maxDay = $result.data('max-day'),
            daySelect = $birthdayTimestamp.find('.js-birthday-timestamp__day'),
            selectedYear = $birthdayTimestamp.find('.js-birthday-timestamp__year').val(),
            selectedMonth = $birthdayTimestamp.find('.js-birthday-timestamp__month').val(),
            selectedOption = daySelect.find("option:selected").val();


        selectedMonth = parseInt(selectedMonth);


        if(selectedYear <= minYear && selectedMonth <= minMonth) {
            daySelect.find("option:not(:first-of-type)").prop('disabled', false);
            for (let i = 1; i < minDay; i++) {
                daySelect.find("option[value=" + i + "]").prop('disabled', true);
            }
            if(selectedOption < minDay ) {
                daySelect.find("option:first-of-type").prop('selected', true);
            }
        } else if(selectedYear == maxYear && selectedMonth == minMonth) {
            daySelect.find("option:not(:first-of-type)").prop('disabled', false);
            let maxOptions = daySelect.find("option").length;
            for (let i = maxDay+1; i <= maxOptions; i++) {
                daySelect.find("option[value=" + i + "]").prop('disabled', true);
            }

            if(selectedOption > maxDay) {
                daySelect.find("option:first-of-type").prop('selected', true);
            }
        }  else {
            daySelect.find("option:not(:first-of-type)").prop('disabled', false);
        }
    }
};


export function isValidDate(day, month, year) {

    year = parseInt(year);
    month = parseInt(month);
    day = parseInt(day);

    if ((month === 4 || month === 6 || month === 9 || month === 11) && day === 31) {
        return false;
    } else if (month === 2) {
        let isLeap = (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0));
        if (day > 29 || (day === 29 && !isLeap)) {
            return false;
        }
    } else {
        return true;
    }
    return true;
}


