"use strict";



function renderItem(select) {
    let text = select.val();

    return $(`<li class=\"list-inline-item personal-topic\">
                 <label class=\"topic-checkbox\">
                      <input type=\"checkbox\" class=\"topic-checkbox__input\">
                      <span class=\"topic-checkbox__text\">${text}</span>
                      <span class=\"icon icon-close js-update-topic-filter__remove-topic\"></span>
                  </label>
              </li>`);
}


export function initInScope ($scope) {
    let topicFilterWrapper = $scope.find('.js-update-topic-filter'),
        topicChangeForm = $scope.find('.js-update-topic-filter__form'),
        addTopicsSelect = topicChangeForm.find('.js-update-topic-filter__select');


    addTopicsSelect.on('change', function(evt) {
        evt.preventDefault();
        let newItem = renderItem($(this));
        topicFilterWrapper.append(newItem);
    });

    $scope.on('click', '.js-update-topic-filter__remove-topic', function() {
        $(this).closest('.personal-topic').remove();
    });
}
