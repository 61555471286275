"use strict";

_config.cookieServices || (_config.cookieServices = {});
let permissionsFormStorage = {};
try {
    permissionsFormStorage = JSON.parse(localStorage.getItem('cookie-permissions'));
} catch (e) {}

let servicePermissions = Object.keys(_config.cookieServices).reduce(function (servicesObject, serviceName) {
    servicesObject[serviceName] = $.extend(
        {}, {
            isAllowed: !!_config.cookieServiceDefault,
            callbacks: []
        },
        _config.cookieServices[serviceName]
    );

    if (permissionsFormStorage && typeof permissionsFormStorage[serviceName] !== 'undefined') {
        servicesObject[serviceName].isAllowed = permissionsFormStorage[serviceName];
    }

    return servicesObject;
}, {});

document.cookie = 'pimcore_targeting_disabled=1';

if (_config.registerPermissionGrantedCallback) {
    _config.registerPermissionGrantedCallback.forEach(function (obj) {
        registerPermissionGrantedCallback(obj.service, obj.callback);
    });
}

export function getServicePermission(serviceName) {
    if (typeof servicePermissions[serviceName] === 'undefined') {
        console.warn('getServicePermission of undefined service "' + serviceName + '". ' +
            'Add the service to _config.cookieServices or use one of the existing ones: ',
            Object.keys(servicePermissions)
        );
    }

    return !!servicePermissions[serviceName] && !!servicePermissions[serviceName].isAllowed;
}

/* callback: passed callback is only called once */
export function registerPermissionGrantedCallback(serviceName, callback) {
    if (!serviceName || !callback) {
        console.error('registerPermissionGrantedCallback: wrong arguments serviceName:String, callback:Function');
        return;
    }

    if (getServicePermission(serviceName)) {
        callback();
    } else {
        if (servicePermissions[serviceName] && servicePermissions[serviceName].callbacks) {
            servicePermissions[serviceName].callbacks.push(callback);
        } else {
            console.warn('registerPermissionGrantedCallback of undefined service "' + serviceName + '". ' +
                'Add the service to _config.cookieServices or use one of the existing ones: ',
                Object.keys(servicePermissions)
            );
        }
    }
}

export function setServicePermission(serviceName, isAllowed) {
    servicePermissions[serviceName].isAllowed = isAllowed;
    if (isAllowed) {
        notifyCallbacks(serviceName);
    }

    try {
        let permissionsFormStorage = JSON.parse(localStorage.getItem('cookie-permissions')) || {};

        permissionsFormStorage[serviceName] = isAllowed;

        localStorage.setItem('cookie-permissions', JSON.stringify(permissionsFormStorage));
    } catch (e) {
        console.error(e);
    }


    if (window['dataLayer'] && typeof window.dataLayer.push === 'function') {
        window.dataLayer.push({
            event: 'cookie-permission-change'
        })
    }
}

function notifyCallbacks(serviceName) {
    if (servicePermissions[serviceName].callbacks) {
        let currentCallback;
        while (currentCallback = servicePermissions[serviceName].callbacks.shift()) {
            currentCallback();
        }
    }
}

function acceptAll() {
    Object.keys(servicePermissions).forEach(function (serviceName) {
        setServicePermission(serviceName, true);
    });
}

function acceptRequired() {
    let requiredServices = Object.keys(_config.cookieServices).filter(function (serviceId) {
        return _config.cookieServices[serviceId].group === 'required'
    });

    requiredServices.forEach(function (serviceName) {
        setServicePermission(serviceName, true);
    });
}

function declineAll() {
    Object.keys(servicePermissions).forEach(function (serviceName) {
        setServicePermission(serviceName, false);
    });
}


/* modal */
let permissionModal;
export function showModal() {
    if (permissionModal) {
        permissionModal.remove();
    }

    permissionModal = createPermissionModal();
    notifyModalShowCallbacks();
    permissionModal.modal('show');
}

export function hideModal() {
    if (permissionModal) {
        permissionModal.modal('hide');
    }
}

function createPermissionModal() {
    let $modal = $(
        `<div class="cookie-modal modal fade" tabindex="-1" role="dialog" aria-labelledby="permissionModal" aria-hidden="true">
              <div class="modal-dialog" role="document">
                  <div class="modal-content">
                      <div class="modal-header">
                        <h3 class="modal-title cookie-modal__title" id="permissionModal">${_config.cookieModal.title}</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span class="icon icon-close" aria-hidden="true"></span>
                        </button>
                      </div> 
                       <div class="modal-body"> 
                            <div class="modal-body__text">${_config.cookieModal.text}</div>
                            
                            <button type="button" class="btn-no-styling font-rounded-bold js-cookie-modal__accept-required  fz14 mb-4">${_config.cookieModal.acceptRequired}</button>  

                            ${createServiceOptions(servicePermissions)}    
                        </div>      
                      <div class="modal-footer text-center">
                           <button type="button" class="btn btn-primary js-cookie-modal__accept fz14 cookie-modal__btn">${_config.cookieModal.acceptAll}</button>  
                           <button type="button" class="btn-no-styling font-rounded-bold fz14 d-block mt-2 mx-auto js-cookie-modal__save">${_config.cookieModal.save}</button>  
                      </div>   
                  </div> 
              </div>
          </div>`
    );


    let $globalToggle = $modal.find('input[name="all"]');
    let $groupToggles = $modal.find('input[name^="group-"]');
    let $serviceToggles = $modal.find('input').not($globalToggle).not($groupToggles);

    $globalToggle.on('change', function () {
        if ($(this).is(':checked')) {
            $serviceToggles.not('[disabled]').prop('checked', 'checked');
        } else {
            $serviceToggles.not('[disabled]').prop('checked', null);
        }
        groupToggleStateUpdate();
    });
    $groupToggles.on('change', function () {
        let $toggles = $serviceToggles.filter('[data-group="' + $(this).data('group') + '"]');
        if ($(this).is(':checked')) {
            $toggles.not('[disabled]').prop('checked', 'checked');
        } else {
            $toggles.not('[disabled]').prop('checked', null);
        }

        globalToggleStateUpdate();
    });


    $serviceToggles.on('change', function () {
        groupToggleStateUpdate();
        globalToggleStateUpdate();
    });

    globalToggleStateUpdate();
    groupToggleStateUpdate();

    function globalToggleStateUpdate() {
        $globalToggle.prop('checked', $serviceToggles.not(':checked').length ? null: 'checked');
    }

    function groupToggleStateUpdate() {
        $groupToggles.each(function () {
            $(this).prop('checked', $serviceToggles.filter('[data-group="' + $(this).data('group') + '"]:checked').length
                ? 'checked'
                : null
            );
        });
    }


    $modal.on('click', '.js-cookie-modal__save', function () {
        hideModal();
        $serviceToggles.toArray().forEach(function (input) {
            let serviceName = input.name;
            let isAllowed = $(input).is(':checked');
            setServicePermission(serviceName, isAllowed);
            hideCookieBar();
        });
    });

    $modal.on('click', '.js-cookie-modal__accept', function () {
        hideModal();
        acceptAll();
        hideCookieBar();
    });

    $modal.on('click', '.js-cookie-modal__accept-required', function () {
        hideModal();
        acceptRequired();
        hideCookieBar();
    });

    $('body').append($modal);
    return $modal;
}

function createServiceOptions(services) {
    function findServiceIdsByGroupId(groupId) {
        return Object.keys(services).filter(function (serviceId) {
            return services[serviceId].group === groupId
        })
    }

    let markup = '';
    let options = [];

    // if (Object.keys(services).length > 1) {
    //     markup += ('<div class="cookie-modal__item cookie-modal__item--all">' +
    //         createServiceOption(_config.cookieModal.toggleAll, 'all', areAllServicesAllowed(services), false) +
    //         '</div>');
    // }

        if (_config.cookieServiceGroups) {
            let groups = Object.entries(_config.cookieServiceGroups).map(function (entry) {
                let groupId = entry[0];
                let group = entry[1];
                let serviceIds = findServiceIdsByGroupId(groupId);

                return Object.assign({}, group, {
                    id: groupId,
                    serviceIds: serviceIds
                });
            }).filter(function (group) {
                return !!group.serviceIds.length;
            }).map(function (group) {
                let isAllowed = group.serviceIds.reduce(function (result,serviceId) {
                    if (result) {
                        return result;
                    }

                    return services[serviceId].isAllowed;
                }, false);

                options = group.serviceIds.map(function (serviceId) {
                    return ('<div class="cookie-modal__item">' +
                        createServiceGroupChildOption(
                            services[serviceId].text,
                            serviceId,
                            services[serviceId].isAllowed,
                            services[serviceId].isReadOnly,
                            services[serviceId].description,
                            services[serviceId].group
                        ) +
                        createDetailLink(services[serviceId]) +
                        '</div>'
                    );
                });

                return (
                    `<div class="cookie-modal__group"><div class="cookie-modal__group-header"><div class="cookie-modal__group-header__title">${createServiceOption(group.name, 'group-' + group.id, isAllowed, group.isReadOnly, group.id)}</div><div class="cookie-modal__group-header__arrow"><div class="cookie-modal__group-toggle collapsed" role="button" data-toggle="collapse" href="#cookie-modal-group-${group.id}" aria-expanded="false" aria-controls="cookie-modal-group-${group.id}"><span class="cookie-modal__group-toggle-icon icon icon-arrow-bold"></span></div></div></div><div class="collapse" id="cookie-modal-group-${group.id}"><div class="cookie-modal__group-text">${group.description}</div><div class="cookie-modal__group-body">${options.join('')}</div></div></div>`
                );
            });

            return markup + groups.join('');
        } else {
            options = options.concat(Object.keys(services).map(function (serviceName) {
                return ('<div class="cookie-modal__item">' +
                    createServiceOption(services[serviceName].text, serviceName, services[serviceName].isAllowed, services[serviceName].isReadOnly) +
                    createDetailLink(services[serviceName]) +
                    '</div>'
                );
            }));

            return options.join('');
        }
    }

function createServiceGroupChildOption(label, name, isChecked, isReadOnly, description, group) {
    return (`<label class="cookie-modal__label  row row--gutter-width-12 ${isReadOnly ? 'cookie-modal__switch-toggle--is-disabled' : ''}">
    <div class="col-3 text-right font-rounded-bold">${label}</div>
    <div class="col-7">${description}</div>
    <div class="col-2">
       <span class="cookie-modal__switch  ${isReadOnly ? 'd-none' : ''}"><input class="sr-only" name="${name}" type="checkbox" ${isChecked ? 'checked' : ''}
        ${isReadOnly ? 'disabled' : ''} ${group ? 'data-group="' + group + '"' : ''}>
        <span class="cookie-modal__switch-toggle"></span>
        </span>
    </div>
    </label>
     `
    );
}

function createServiceOption(label, name, isChecked, isReadOnly,  group) {
    return (`<label class="cookie-modal__label ${isReadOnly ? 'cookie-modal__switch-toggle--is-disabled' : ''}">
    <span class="cookie-modal__switch"><input class="sr-only" name="${name}" type="checkbox" ${isChecked ? 'checked' : ''}
    ${isReadOnly ? 'disabled' : ''} ${group ? 'data-group="' + group + '"' : ''}>
    <span class="cookie-modal__switch-toggle"></span>
    </span>
    <span class="font-rounded-bold">${label}</span>
    </label>
     `
    );
}

function areAllServicesAllowed(services) {
    return Object.keys(services).reduce(function (allAllowed, currentServiceName) {
        return !services[currentServiceName].isAllowed ? false: allAllowed;
    }, true);
}

function createDetailLink(service) {
    return (_config.cookieModal.detail && service.url)
        ? '<a class="cookie-modal__link" target="_blank" href="' + service.url + '">' + _config.cookieModal.detail + '</a>'
        : '';
}


/* cookie bar */
let $cookieBar;
function createCookieBar() {
    $cookieBar = $(`<div class="cookie-bar js-cookie-bar"><img src="/static/img/cookie.gif" class="cookie-bar__img img-fluid"> <div class="cookie-bar__text">${_config.cookieBar.text} <span class="d-block mt-2 mt-md-1">${createCookieBarSettingsLink()} | ${createCookieBarDetailLink()}</span></div>    <div class="cookie-bar__buttons">${createDeclineButton()} ${createAcceptButton()} ${createAcceptRequiredButton()}</div></div>`);

    $('body').append($cookieBar);

    $cookieBar.on('click', '.js-cookie-bar__accept', function () {
        acceptAll();
        hideCookieBar();
    });

    $cookieBar.on('click', '.js-cookie-bar__decline', function () {
        declineAll();
        hideCookieBar();
    });

    $cookieBar.on('click', '.js-cookie-bar__detail', function () {
        showModal();
    });

    $cookieBar.on('click', '.js-cookie-bar__accept-required', function () {
        acceptRequired();
        hideCookieBar();
    });

    // usage: panorama screenshots
    if(_config.hideCookieBar) {
        hideCookieBar();
    }

    return $cookieBar;
}

function createDeclineButton() {
    return _config.cookieBar.decline
        ? '<button type="button" class="cookie-bar__decline btn btn-no-styling js-cookie-bar__decline">' +
        '    ' + _config.cookieBar.decline +
        '</button>'
        : '';
}
function createAcceptButton() {
    return _config.cookieBar.accept
        ? '<button type="button" class="cookie-bar__accept btn btn-primary js-cookie-bar__accept">' +
        '    ' + _config.cookieBar.accept +
        '</button>'
        : '';
}
function createAcceptRequiredButton() {
    return _config.cookieBar.accept
        ? '<button type="button" class="btn-no-styling js-cookie-bar__accept-required cookie-bar__accept-required">' +
        '    ' + _config.cookieBar.acceptRequired +
        '</button>'
        : '';
}

function createCookieBarDetailLink() {
    return _config.cookieBar.detail
        ?   '<a target="_blank" href="' + _config.cookieBar.detailUrl +  '" class="cookie-bar__detail-btn font-rounded-bold btn btn-no-styling text-primary">' +
        _config.cookieBar.detail +
        '</a>'
        : '';
}

function createCookieBarSettingsLink() {
    return _config.cookieBar.settings && _config.cookieServices && Object.keys(_config.cookieServices).length
        ?   '<button type="button" class="cookie-bar__detail-btn btn btn-no-styling font-rounded-bold js-cookie-bar__detail">' +
        _config.cookieBar.settings +
        '</button>'
        : '';
}

/* options.force: if set, force the cookie bar to be shown even if it was already dismissed */
export function showCookieBar({force} = {}) {
    if (force) {
        return createCookieBar();
    }

    let showCookieBar = true;
    try {
        showCookieBar = !localStorage.getItem('cookie-bar-dismissed');
    } catch (e) {}

    if (!showCookieBar && localStorage.getItem('cookie-permissions')) {
        let existingServices = Object.keys(JSON.parse(localStorage.getItem('cookie-permissions')));
        showCookieBar = !!(Object.keys(servicePermissions).filter(function (currentService) {
            return !existingServices.includes(currentService)
        }).length);
    }

    if (showCookieBar) {
        return createCookieBar();
    }
}

export function hideCookieBar() {
    if ($cookieBar && $cookieBar.length || _config.hideCookieBar) {
        $cookieBar.attr('hidden', 'hidden');
    }

    try {
        localStorage.setItem('cookie-bar-dismissed', true);
    } catch (e) {}
}

function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function hasCookie(cname) {
    return document.cookie.indexOf(cname) >= 0;
}

let openCallbacks = [];
export function onModalShow(cb) {
    openCallbacks.push(cb);
}
function notifyModalShowCallbacks () {
    openCallbacks.forEach(function (cb) {
        cb(permissionModal);
    });
}
