"use strict";


let isRunning = false;
let elements = [];

export function initInScope($scope) {

    let $newElements = $scope.find('.js-affix');


    if (!$newElements.length) {
        return;
    }
    

    elements = elements.concat($newElements.toArray().map(function (element) {
        let $element = $(element);
        let $placeholder = $element.closest('.js-affix-placeholder');
        let $container = $element.closest('.js-affix-container');

        $element.css('transform', 'translateZ(0)');

        if (!$container || !$container.length) {
            console.warn('Could not find parent ".js-affix-container" for element ', element);
            return null;
        }

        if (!$placeholder || !$placeholder.length) {
            console.warn('Could not find parent ".js-affix-placeholder" for element ', element);
            return null;
        }



        return {
            element: element,
            placeholder: $placeholder[0],
            container: $container[0],
            isAffix: false,
            offset: +$element.data('affix-offset') || 0
        }
    }).filter(x => !!x));

    if (!isRunning) {
        isRunning = true;
        requestAnimationFrame(interval);
    }
}

let lastScrollPosition = 0;
export function interval() {
    if (lastScrollPosition === window.pageYOffset) {
        requestAnimationFrame(interval);
        return;
    }

    lastScrollPosition = window.pageYOffset;

    elements.forEach(function (obj) {

        if (obj.placeholder) {
            $(obj.placeholder).css('min-height', obj.element.getBoundingClientRect().height);
        }

        let elementRect =  obj.placeholder.getBoundingClientRect();
        let containerRect = obj.container.getBoundingClientRect();


        if (obj.isAffix) {
            if (isContainerBottomReached(containerRect, elementRect, obj.offset)) {
                $(obj.element).removeClass('is-affix').css({
                    position: 'relative',
                    top: containerRect.height - (elementRect.top - containerRect.top) - elementRect.height,
                    left: '',
                });

                obj.isAffix = false;
            } if (!isAffixTopReached(elementRect, obj.offset)){

                $(obj.element).removeClass('is-affix').css({
                    position: '',
                    top: '',
                    left: '',
                    width: '',
                });

                obj.isAffix = false;
            }
        } else {
            if (isAffixTopReached(elementRect, obj.offset) && !isContainerBottomReached(containerRect, elementRect, obj.offset)) {
                $(obj.element).addClass('is-affix').css({
                    position: 'fixed',
                    top: obj.offset,
                    left: elementRect.left,
                    width: elementRect.width,
                });

                obj.isAffix = true;

            }
        }
    });

    requestAnimationFrame(interval);
}



function isContainerBottomReached(containerRect, elementRect, offset) {
    return containerRect.top + containerRect.height - offset - elementRect.height <= 0;
}

function isAffixTopReached(elementRect, offset) {
    return elementRect.top - offset <= 0
}