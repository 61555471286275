"use strict";

import {isParsleyForm, isValid} from "./parsley";

export function initInScope ($scope) {
    let $wrapper = $scope.find('.js-enable-on-form-validate__wrapper');

    $wrapper.each(function() {
        let $form = $(this).find('.js-enable-on-form-validate__form');
        let $button = $(this).find('.js-enable-on-form-validate__button');

        // toggleDisableFromButton($form, $button);

        $form.on('change', function (evt) {
            let $form = $(this);
            toggleDisableFromButton($form, $button);
        });

        function toggleDisableFromButton($form, $button) {
            if (isParsleyForm($form) && isValid($form)) {
                $button.attr('disabled', false);
            } else {
                $button.attr('disabled', true);
            }
        }

    });
}
