"use strict";

export function initInScope($scope) {
    let $submitTickets = $scope.find('.js-personalization__submit');
    let $errorText = $scope.find('.js-personalization__error');

    $submitTickets.on('click', function(evt) {

        let $ticketNotPersonalized = $('.js-personalization__item[data-personalized="false"]');

        if ($ticketNotPersonalized.length > 0) {
             evt.preventDefault();
             $errorText.attr('hidden', false);
             $ticketNotPersonalized.each(function() {
                 $(this).addClass('has-error');
             })
        }
    });
}

