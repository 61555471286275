"use strict";

export function initInScope ($scope) {
    let $label = $scope.find('.js-file-upload__label');


    $scope.on('change', '.js-file-upload', function(evt){
        $label.html($(this).val().split('\\').pop());

        let $form = $(this).closest('.js-file-upload__form');
        let $submit = $form.find('.js-file-upload__submit');
        $submit.attr('hidden', false);
    });
}
