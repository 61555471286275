/** @jsx h */

import {h, render, Component} from "preact";
import {noop} from "../utils";
import {useState} from "preact/hooks";
import {translate} from '@elements/translations';
import loadJQueryUi from '../loadJQueryUi';
import {dateToISOString, ISOStringToDate, localDateToUTCDate, UTCDateToLocalDate} from "@elements/date-utils";


/**
 * @return {string}
 */

export default class Datepicker extends Component {

    //datepicker just copied and adjustments for preact
    componentDidMount() {
        const dayInMilliseconds = 24 * 60 * 60 * 1000;
        this.$el = $(this.el);
        let $el = this.$el;
        let props = this.props;
        let predefinedOptions = this.props.datePickerOptions;
        let seasons = this.props.seasons;
        let priceTendencies = this.props.priceTendencies;

        loadJQueryUi().then(function () {
            let $this = $el,
                defaultDate = props.date || predefinedOptions['date'] || null,
                $datepicker = $this.find('.js-datepicker__input'),
                availableDateRanges = predefinedOptions['availableDateRanges'] || false;

            
            // capitalize fr datepicker monthnames
            if (_config.lang && _config.lang == 'fr') {
                let monthNames = $.datepicker.regional['fr'].monthNames;
                let capitalizedMonthNames = [];
                monthNames.forEach(function(month) {
                    capitalizedMonthNames.push(month.charAt(0).toUpperCase()+month.slice(1));
                });
                $.datepicker.regional['fr'].monthNames = capitalizedMonthNames;
                $.datepicker.setDefaults($.datepicker.regional['fr']);
            }

            let changeDefaultDate = true;

            //if default date is not in available date range
            if(availableDateRanges && changeDefaultDate) {
                let defaultTempDate = $.datepicker.formatDate( "yy-mm-dd", new Date(defaultDate));

                for(let i=0; i< availableDateRanges.length; i++) {
                    if(defaultTempDate >= availableDateRanges[i].from && defaultTempDate <= availableDateRanges[i].to && changeDefaultDate) {
                        changeDefaultDate = false;
                    }
                }

                if(changeDefaultDate) {
                    defaultDate = availableDateRanges[0].from;
                    $datepicker.datepicker('setDate', defaultDate);
                    props.onChangeDate(defaultDate);
                    $datepicker.val(toLocalDateString(Date.parse(defaultDate)));
                } else {
                    $datepicker.datepicker('setDate', defaultDate);
                    props.onChangeDate(defaultDate);
                    $datepicker.val(defaultDate);
                    $el.trigger('change');
                }
            }

            let options = {
                numberOfMonths:  matchMedia('(min-width: 768px)').matches ? 2 : 1,
                nextText: '<span class="icon icon-arrow" aria-label="Next" title="Next"></span>',
                prevText: '<span class="icon icon-arrow" aria-label="Previous" title="Previous"></span>',
                dateFormat: 'yy-mm-dd',
                minDate: availableDateRanges && availableDateRanges[0] ? availableDateRanges[0].from : null, // minimum selectable date is today
                defaultDate: defaultDate,
                firstDay: 1,
                maxDate: availableDateRanges && availableDateRanges[availableDateRanges.length-1] ?  availableDateRanges[availableDateRanges.length-1].to : null,
                onSelect: function(dateString, inst) {
                    let $self = $(this);
                    $(this).trigger('change');
                    $datepicker.datepicker('setDate', dateString);
                    props.onChangeDate(dateString);
                    $datepicker.val(toLocalDateString(Date.parse(dateString)));
                    $self.data('datepicker').inline = true;
                },
                beforeShowDay: function (date) {
                    let newDate = $.datepicker.formatDate( "yy-mm-dd", date);
                    let dateClass = '';

                    if(seasons) {
                        seasons.allIds.map((seasonId) => {
                            let season = seasons.byId[seasonId];
                            for(let i=0; i< season['ranges'].length; i++) {
                                if(newDate >= season['ranges'][i].from && newDate <= season['ranges'][i].to) {
                                    let colorClass = ` is-season--${season.color} `;
                                    dateClass += colorClass;
                                }
                            }
                        });
                    }

                    if(priceTendencies) {
                        priceTendencies.allIds.map((tendencyId) => {
                            let priceTendency = priceTendencies.byId[tendencyId];
                            for(let i=0; i< priceTendency['ranges'].length; i++) {
                                if(newDate >= priceTendency['ranges'][i].from && newDate <= priceTendency['ranges'][i].to) {
                                    let colorClass = ` is-price-tendency--${priceTendency.arrow} `;
                                    dateClass += colorClass;
                                }
                            }
                        });
                    }

                    if(availableDateRanges) {
                        for(let i=0; i< availableDateRanges.length; i++) {
                            if(newDate >= availableDateRanges[i].from && newDate <= availableDateRanges[i].to) {
                                return [true, dateClass];
                            }
                        }
                        return [false, dateClass];
                    }
                    return [true, dateClass];
                }
            };

            $el.datepicker(options);

            function toLocalDateString(timestamp) {
                return UTCDateToLocalDate(new Date(+timestamp)).toLocaleString(_config.lang, {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric'
                });
            }
        });

    }

    componentWillUnmount() {
        // this.$el.datepicker('destroy');
    }

    render() {
        return (
            <div className="ticket-configuration__datepicker-wrapper">
                <div className="js-datepicker ticket-configuration__datepicker" ref={el => this.el = el}>
                    <input type="hidden" className="js-datepicker__input" name="date" value={ this.props.date }/>
                </div>
            </div>
        );
    }
}
