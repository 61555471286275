"use strict";

export function initInScope($scope) {
    let $container = $scope.find('.js-interests-filter');
    let $interestsFilter = $container.find('.js-interests-filter__interest');
    let $interestsDependencies = $container.find('.js-interests-filter__interest-dependencies');

    $interestsFilter.on('change', function() {
        let val = $(this).val();
        $interestsDependencies.attr('disabled', true);
        $interestsDependencies.parent().addClass('interest-disabled');

        $interestsDependencies.each(function() {
            let $self = $(this);
            $self.prop('checked', false);
            let dependencies = $(this).attr('data-interests');
            let dependenciesArray =  JSON.parse(dependencies);

            for (let arrayL = dependenciesArray.length, i = 0; i < arrayL; i++) {
                let elem = dependenciesArray[i];
                if(elem == val) {
                    $self.attr('disabled', false);
                    $self.parent().removeClass('interest-disabled');
                }
            };
        });

    });
}