export function initInScope($scope) {
    $scope.find('.js-click-nav__trigger').one('click', function (evt) {
        if (!evt.ctrlKey) {
            evt.preventDefault();
        }

        // reset
        if (!matchMedia('(max-width: 767px)').matches) {
            // do not reset on mobile otherwise the newly opened sub nav could not be jump out of the viewport.
            $(this).closest('.js-click-nav__list')
                .find('.js-click-nav__item')
                .removeClass('is-open')
                .each((index,item) => {
                        $(item).find('.js-click-nav__trigger')
                            .attr('aria-expanded', 'false');
                    }
                );
        }


        // set
        let $item = $(this).closest('.js-click-nav__item');
        console.log($item);
        if ($item.hasClass('is-open')) {
            $item.removeClass('is-open');
            $(this).attr('aria-expanded', 'false');
        } else {
            $item.addClass('is-open');
            $(this).attr('aria-expanded', 'true');
        }

    });
}
