"use strict";

import initModulesInScope from "./initModulesInScope";

export function initInScope($scope) {
    let $input = $scope.find('.js-cashback-form__input');
    let pendingRequest = null;

    $input.on('change', function(evt) {
        evt.preventDefault();
        evt.stopImmediatePropagation();

        let $input = $(this);
        let $wrapper = $input.closest('.js-cashback-form');
        let action = $wrapper.data('action') || $wrapper.attr('action');
        let method = $wrapper.attr('method') || 'POST';

        let $loading = $('.js-cashback-form__loading');
        let $cartResult = $('.js-cashback-form__result-cart');

        if (pendingRequest) {
            pendingRequest.abort();
            pendingRequest = null;
        }

        $cartResult.attr('hidden', null);
        $loading.attr('hidden', null);

        let data = {};
        $('.js-cashback-form__input').each(function(){
            let input = $(this);
            data[input.attr('name')] = input.is(':checked') ? '1' : '0'
        });

        pendingRequest = $.ajax(action, {
            method: method,
            data: data,
            headers: {Ajax: 1}
        }).done(function (result) {
            $cartResult.empty().append(result);
            initModulesInScope($cartResult);
        }).always(function () {
            $loading.attr('hidden', 'hidden');
            pendingRequest = null;
        });
    });
}