"use strict";

export function initInScope ($scope) {
    let $bubbleOverlay = $scope.find('.js-bubble-overlay');

    const bubbleOverlayFistView = sessionStorage.getItem('bubble-overlay-first-view');
    if ($bubbleOverlay.hasClass('is-open-first-visit') && !bubbleOverlayFistView){
        $bubbleOverlay.addClass('is-open');
    }
    sessionStorage.setItem('bubble-overlay-first-view', true);

    $('.js-bubble-overlay__close, .js-bubble-overlay__link').on('click', function() {
        $bubbleOverlay.removeClass('is-open');
    });

    $('.js-bubble-overlay__toggle').on('click', function() {
        let $overlay = $(this).closest('.js-bubble-overlay');
        $overlay.toggleClass('is-open');
    });
}

