/** @jsx h */

import {h} from "preact";

export default function () {
    return (
        <div className="loading-overlay p-2">
            <div className="loading-spinner loading-spinner--small"></div>
            <div className="pt-1">Loading…</div>
        </div>
    );
}