"use strict";

import {isEmptyObject, supportsLocalStorage} from "./utils";
import { getServicePermission } from "./cookiePermissions";
import {trackEvent} from "./tracking";

export function init() {
    let $notificationCounter = $('.js-shopping-list__count');

    /*
    getLocalItemsCounter().then(function (counter) {
        if (counter) {
            $notificationCounter.html(counter).attr('hidden', false);
            animateNotification($notificationCounter);
        }
    });*/

}
let $notificationCounter = $('.js-shopping-list__count');

export function initInScope($scope) {

    let $currencySelect = $('.js-shopping-list__currency-select');
    let $submitButton = $('.js-shopping-list__submit');
    let $itemsPriceCurrency = $('.js-shopping-list__price-currency');
    let $items = $scope.find('.js-shopping-list__item');
    let $size = $scope.find('.js-shopping-list__item-size input');

    $submitButton.on('click', function (evt) {
        if ($submitButton[0].hasAttribute('disabled')) {
            evt.preventDefault();
        }
    });

    $size.on('click', function () {
        if ($(this).is(':checked')) {
            $items.attr('disabled', false);
        }
    });

    if($currencySelect.length > 0) {
        updateCurrencyUI($currencySelect, $itemsPriceCurrency);
    }



    $items.on('click', function (evt) {
        evt.preventDefault();

        let $item = $(this);
        let $form = $item.closest('form');
        let url = $form.data('action') || $form.attr('action');
        let pendingRequest = null;
        let id = this.dataset.id;
        let $itemWrapper = $item.closest('.js-shopping-list__item-wrapper');
        let itemCounter = $itemWrapper.find('.js-shopping-list__item-count').val();
        let itemSize = $itemWrapper.find('.js-shopping-list__merch-size').val();
        let $loading = $itemWrapper.find('.js-shopping-list__item--loading');
        let additionalInputs = $itemWrapper.find('.js-shopping-list__additional-input');
        let $result = $('.js-shopping-list__result');


        if (!id) {
            console.warn('Can\'t add item to shopping list. data-id was not set on ', this);
            return;
        }

        $loading.attr('hidden', null).removeClass('hidden');
        let additionalData = additionalInputs.toArray().reduce((acc, cur) => {
            return {
                ...acc, 
                [cur.getAttribute('name')]: cur.value 
            }
        }, {});

        if ($item.attr('data-type') === 'ticket') {
            let url = $item.data('url');
            let $addedCount = $itemWrapper.find('.js-shopping-list__added-count');
            let id = this.dataset.id;

            let data = {
                id: id,
                amount: itemCounter,
                ...additionalData
            };
            

            pendingRequest = $.ajax({
                url: url,
                type: "POST",
                dataType: "json",
                data: data
            }).done(function (response) {
                $item.addClass('is-added').attr('aria-pressed', 'true');
                updateNotificationUI(response.counter);
                updateTicketUI($addedCount, $item, itemCounter, response);
                animateNotification();
                // fbTrackAddToCart();
                // gtmTrackAddToCart();
                // trackingShoppingList($item);
                // $submitButton.attr('disabled', false);
            }).fail(function (jqXHR, textStatus, errorThrown) {
                if (textStatus !== 'abort') {
                    console.error(textStatus, errorThrown, jqXHR);
                }
            }).always(function () {
                pendingRequest = null;
                $loading.attr('hidden', true).addClass('hidden');
            });
        } else {
            let data = {
                id: id,
                amount: itemCounter,
                size: itemSize,
                ...additionalData
            };

            pendingRequest = $.ajax({
                url: url,
                type: "POST",
                dataType: "json",
                data: data
            }).done(function (response) {
                if(response.success) {
                    // $result.empty().append(response.html);
                    $item.addClass('is-added').attr('aria-pressed', 'true');
                    $('.js-shopping-list__result-added-counter').text(response.addedCounter);
                    $item.find('.js-shopping-list__item__default').attr('hidden', true);
                    $item.find('.js-shopping-list__item__is-added').attr('hidden', false);
                    updateNotificationUI(response.cartCounter, $notificationCounter);
                    // fbTrackAddToCart();
                    // gtmTrackAddToCart();
                    // trackingShoppingList($item);
                } else {
                    pendingRequest = null;
                    $loading.attr('hidden', true).addClass('hidden');
                    console.log("shoppingList: something went wrong");
                }
            }).fail(function (jqXHR, textStatus, errorThrown) {
                if (textStatus !== 'abort') {
                    console.error(textStatus, errorThrown, jqXHR);
                }
            }).always(function () {
                pendingRequest = null;
                $loading.attr('hidden', true).addClass('hidden');
            });
        }


    });
}


export function updateNotificationUI(counter) {
    $notificationCounter.html(counter).attr('hidden', false);
}

function updateTicketUI($addedCount, $item, itemCounter, response) {
    $addedCount.html(itemCounter);
    updateUiShoppingListFooter(response.priceSum);
    showItemOverlay($item);
    closeItemOverlay($item);
}


function updateCurrencyUI($currencySelect, $itemsPriceCurrency) {
    $currencySelect.on('change', function () {
        let $self = $(this);
        let $price = $('.js-shopping-list__price');
        let currency = $(this).val();
        $itemsPriceCurrency.html(currency);
        $price.each(function () {
            $(this).html($(this).attr('data-price-' + currency));
        });
        if ($self.hasClass('js-tracking--currency-select')) {
            trackEvent(
                $self.data('tracking-category'),
                $self.data('tracking-action'),
                currency
            );
        }
    });
}

function updateUiShoppingListFooter(obj) {
    let selectedCurrency = $('.js-shopping-list__currency-select').val();
    let $itemsPriceSum = $('.js-shopping-list__price-sum');
    let $itemsPriceCurrency = $('.js-shopping-list__price-currency');
    $.each(obj, function (key, value) {
        let currency = key.toLowerCase();
        $itemsPriceSum.attr('data-price-' + currency, value);
        if (key == selectedCurrency) {
            $itemsPriceSum.html(value);
            $itemsPriceCurrency.html(selectedCurrency);
        }
    });
}

export function animateNotification() {
    $notificationCounter.addClass("notification-bounce");
    $notificationCounter.one('webkitAnimationEnd oanimationend msAnimationEnd animationend', function (e) {
        $notificationCounter.removeClass('notification-bounce');
    });
}

function showItemOverlay($item) {
    $item.closest('.js-shopping-list__item-wrapper').find('.js-shopping-list__item-overlay').attr('hidden', false);
}

function closeItemOverlay($item) {
    $('.js-shopping-list__item-overlay__close').on('click', function () {
        let $itemWrapper = $(this).closest('.js-shopping-list__item-wrapper');
        $(this).closest('.js-shopping-list__item-overlay').attr('hidden', true);
        $item.removeClass('is-added');
        $itemWrapper.find('.js-shopping-list__item-count').attr('value', 0);
        $itemWrapper.find('.js-shopping-list__item-count').val(0);

        $itemWrapper.find('.js-shopping-list__item').attr('disabled', 'disabled');
    });
}


function fbTrackAddToCart() {
    if (getServicePermission('facebook')) {
        if(typeof fbq != 'undefined') {
            fbq('track', 'AddToCart');
        }

    }
}

function gtmTrackAddToCart() {
    if (getServicePermission('GoogleTagManagerRemarketing')) {
        if(window.dataLayer != 'undefined') {
            window.dataLayer.push({
                'event': 'addToCart'
            });
        }

    }
}

function trackingShoppingList($item) {
    if ($item.hasClass('js-tracking--shopping-list')) {
        trackEvent(
            $item.data('tracking-category'),
            $item.data('tracking-action'),
            $item.data('tracking-label')
        );
    }
}