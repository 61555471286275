"use strict";

export function initInScope($scope) {
    import('bootstrap-select').then(function () {
        $scope.find('.js-multi-select').each(function () {
            let $multiSelect = $(this);

            $multiSelect.selectpicker({
                noneSelectedText: $(this).attr('placeholder') || $(this).data('label') || ' '
            });

            if($multiSelect.attr('data-multi-select-trigger-filter-form-submit')) {
                $multiSelect.on('rendered.bs.select', function (e, clickedIndex, isSelected, previousValue) {
                    $multiSelect.addClass('js-filter-form__input');
                });
            }

        });

        $scope.find('.js-multi-select__reset').on('click', function() {
            $scope.find('.js-multi-select').selectpicker('deselectAll');
        });

    });
}