"use strict";

import initModulesInScope from "./initModulesInScope";

export function initInScope($scope) {
    let $button = $scope.find('.js-show-all-teasers__btn');

    let pendingRequest = null;

    $button.on('click', function(evt) {
        let $self = $(this);
        let $container = $(this).closest('.js-show-all-teasers');
        let $result = $container.find('.js-show-all-teasers__result');
        let $loading = $container.find('.js-show-all-teasers__loading');
        let $error = $container.find('.js-show-all-teasers__error');

        if (pendingRequest) {
            pendingRequest.abort();
            pendingRequest = null;
        }

        evt.preventDefault();

        let ajaxUrl = $container.attr('data-url');
        $result.attr('hidden', null);
        $loading.attr('hidden', null);

        pendingRequest = $.ajax({
            url: ajaxUrl,
            method: 'GET'
        }).done(function(data) {
            $result.html(data);
            $self.attr('hidden', true);
            initModulesInScope($result);
        }).fail(function (jqXHR, textStatus, errorThrown) {
            if (textStatus !== 'abort') {
                $error.attr('hidden', null);
                console.error(textStatus, errorThrown, jqXHR);
            }
        }).always(function () {
            $loading.attr('hidden', 'hidden');
            pendingRequest = null;
        })
    });
}