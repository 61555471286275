"use strict";

export function initInScope ($scope) {
    let $notificationHeader = $scope.find('.js-notification-header');

    $notificationHeader.find('.js-notification-header__close, .js-notification-header__link').on('click', function() {
        document.cookie = "notification-header-closed=true; expires='';";
        $notificationHeader.attr('hidden', '');
    });
}

