/** @jsx h */

import {h, render, Fragment} from 'preact';
import {useState, useEffect} from 'preact/hooks';
import StepNav from "./components/StepNav";
import CurrencySwitch from "./components/CurrencySwitch";
import LoadingSpinner from "./components/LoadingSpinner";
import Datepicker from "./components/Datepicker";
import {translate} from '@elements/translations';
import fetch from '@elements/fetch';
import EventTicketConfigurationForm from "./components/EventTicketConfiguration/EventTicketConfigurationForm";
import {Modal} from 'react-bootstrap';
import {noop} from "./utils.js";
import {addParamsToUrl} from "./utils";
import {getPrefixedDataSet} from "./data-set-utils";
import {animateNotification, updateNotificationUI} from "./shoppingList";
import {responseTracking} from "@elements/tracking";
import {UTCDateToLocalDate} from "./rangeDatePicker";

function EventTicketConfig({
                               isLoading,
                               id,
                               cartId = 0,
                               title = "",
                               stepDefault = 0,
                               dateDefault = new Date(),
                               configurationsDefault = [],
                               ageGroups = [],
                               currencies = [],
                               datePickerOptions = [],
                               onModalHide = noop,
                               prices,
                               statusDefault = null,
                               ticketCounterDefault = {},
                               availabilityDefault = null,
                               selectedConfigurationIdDefault = null,
                               isModalOpen = true
                           }) {
    const maxStep = 2;

    // State
    const [loadingData, setLoadingData] = useState(false);
    const [completedConfiguration, setCompletedConfiguration] = useState(false);
    const [currentStep, setCurrentStep] = useState(stepDefault); // 0 is initial state
    const [date, setDate] = useState(dateDefault); // today is initial date
    const [configurations, setConfigurations] = useState(configurationsDefault);
    const [selectedConfigurationId, setSelectedConfigurationId] = useState(selectedConfigurationIdDefault); // determines which configuration is currently edited in the form
    const [selectedCurrencyId, setSelectedCurrencyId] = useState("chf");
    const [availability, setAvailabilty] = useState(availabilityDefault);
    const [ticketCounter, setTicketCounter] = useState(ticketCounterDefault);
    const [status, setStatus] = useState(statusDefault);
    const [addedToCartCount, setAddedToCartCount] = useState(0);

    const getEventTicketData = id => {
        const _config = window._config || {};

        if (!_config.eventTicketDataUrl) {
            console.warn(`"_config.eventTicketDataUrl" is not set`);
        }

        let data = {
            id: id,
            date: date
        };

        setLoadingData(true);

        const url = addParamsToUrl(_config.eventTicketDataUrl, data);

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(result => result.json()).then(result => {
            if (result.success === true) {
                setAvailabilty(result.data.availability);
                setStatus(result.data.status);
                setLoadingData(false);
            } else {
                setLoadingData(false);
            }
        }).catch(e => {
            setLoadingData(false);
            console.error(`Could not load configuration data (options and prices) for id "${id}"`, e);
        });
    };

    const getLocalizedDate = (date) => {
        let newDate = UTCDateToLocalDate(new Date(date));
        let dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };

        if(_config.lang === 'de') {
            newDate = newDate.toLocaleDateString('de-DE', dateOptions);
        } else if(_config.lang === 'fr' || _config.lang === 'it') {
            newDate = newDate.toLocaleDateString('fr-FR', dateOptions);
        } else {
            newDate = newDate.toLocaleDateString('en-US', dateOptions);
        }
        return newDate;
    };

    const getTicketCounterSum = () => {
        let sum = 0;
        for (let [ageGroup] of Object.entries(ticketCounter)) {
            sum +=  ticketCounter[ageGroup];
        }
        return sum;
    };

    const addConfigurationsToCart = () => {
        const _config = window._config || {};

        if (!_config.addToCartUrl) {
            console.warn(`"_config.addToCartUrl" is not set`);
        }

        setLoadingData(true);

        let data = {
            id: id,
            cartId: cartId,
            configurationId: selectedConfigurationId,
            orderDate: date,
            type: 'event-ticket',
            prices: ticketCounter
        };

        let request = fetch(_config.addToCartUrl, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        responseTracking(request);

        request.then(result => result.json()).then(result => {
            if (result.success === true) {
                setCompletedConfiguration(true);
                setAddedToCartCount(result.addedCounter);
                animateNotification();
                updateNotificationUI(result.cartCounter);
                setLoadingData(false);
            } else {
                setLoadingData(false);
            }
        }).catch(e => {
            setLoadingData(false);
            console.error(`Could not add ticket to cart for id "${id}"`, e);
        });
    };


    // Render
    if (isLoading) {
        return (
            <LoadingSpinner/>
        );
    }

    return (
        <Modal
            dialogClassName="modal-dialog modal-lg-shop"
            show={isModalOpen} onHide={onModalHide}>

            <button type="button" className="close text-right" onClick={onModalHide}>
                <span aria-hidden="true" className="icon icon-close"></span>
            </button>

            {completedConfiguration === false ? (
                <Fragment>
                    {currentStep === 1 ? (
                        <div className="ticket-configuration__currency-switch">
                            <CurrencySwitch currencies={currencies} selectedCurrencyId={selectedCurrencyId}
                                            onCurrencyChange={setSelectedCurrencyId}/>
                        </div>
                    ) : null}

                    <StepNav max={maxStep} current={currentStep} onStepClick={setCurrentStep}/>

                    <div className="modal-content__inner">
                        {currentStep === 0 ? (
                            <div className="ticket-configuration">
                                <div className="ticket-configuration__header">
                                    <h2 className="ticket-configuration__title">{translate('event-ticket.choose-dates')}</h2>
                                </div>

                                <div className="my-3">
                                    <Datepicker
                                        onChangeDate={setDate}
                                        date={date}
                                        datePickerOptions={datePickerOptions}/>
                                </div>
                            </div>
                        ) : (

                            <div className="ticket-configuration">
                                <div className="ticket-configuration__header">
                                    <h2 className="ticket-configuration__title">{translate('event-ticket.configure')}</h2>
                                    <div className="ticket-configuration__subtitle">{getLocalizedDate(date)}</div>
                                </div>


                                <EventTicketConfigurationForm
                                    key={selectedConfigurationId} // bind the form to the selectedConfigurationId, forces rerender of the element if changed
                                    ageGroups={ageGroups}
                                    prices={prices}
                                    availability={availability}
                                    title={title}
                                    ticketCounter={ticketCounter}
                                    onChangeTicketCounter={setTicketCounter}
                                    status={status}
                                    currencies={currencies}
                                    selectedCurrencyId={selectedCurrencyId}
                                    isInEdit={!!selectedConfigurationId}
                                />
                            </div>
                        )}
                    </div>

                    <div className="text-center mt-4">
                        {currentStep + 1 < maxStep ? (
                            <button className="btn btn-secondary ticket-configuration__bottom-btn"
                                    onClick={() => {
                                        setCurrentStep(currentStep + 1);
                                        getEventTicketData(id);
                                    }}>
                                {translate('ticket.continue')}
                            </button>
                        ) : (
                            <button className="btn btn-secondary ticket-configuration__bottom-btn"
                                    disabled={getTicketCounterSum() === 0}
                                    onClick={() => {
                                        setCurrentStep(currentStep + 1);
                                        addConfigurationsToCart();
                                    }}>
                                {translate('ticket.add-to-cart')}
                            </button>
                        )}
                    </div>

                    { loadingData ? (
                        <Fragment>
                            <LoadingSpinner/>
                        </Fragment>
                    ) : ""}
                </Fragment>
            ) : (
                <div className="ticket-configuration__completed">
                    <span className="icon icon-complete ticket-configuration__completed__icon"/>

                    <div className="h6 text-uppercase">
                        {addedToCartCount + " "}
                        {+addedToCartCount === 1 ? (
                            translate('event-ticket.ticket-added-to-cart')
                        ) : (
                            translate('event-ticket.tickets-added-to-cart')
                        )}
                    </div>

                    <div className="row mt-5">
                        <div className="col-md-4">
                            <a className="btn bg-transparent text-primary btn-block text-center px-3"
                               href={_config.ticketOverviewLink}>
                                {translate('ticket.continue-shopping')}
                            </a>
                        </div>

                        <div className="col-md-4">
                            <a className="btn btn-grey btn-block"
                               href={_config.cartLink}>
                                {translate('ticket.cart')}
                            </a>
                        </div>

                        <div className="col-md-4 mt-2 mt-md-0">
                            <a className="btn btn-secondary btn-block"
                               href={_config.checkoutLink}>
                                {translate('ticket.checkout')}
                            </a>
                        </div>
                    </div>
                </div>
            )}


        </Modal>
    );
}

export function getInitialEventTicketData(id) {
    const _config = window._config || {};

    if (!_config.eventTicketInitialDataUrl) {
        return Promise.reject(`"_config.eventTicketInitialDataUrl" is not set`);
    }

    const url = addParamsToUrl(_config.eventTicketInitialDataUrl, {id: id});

    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(result => result.json()).then(result => {
        if (result.success === false) {
            return Promise.reject(result);
        } else {
            return Promise.resolve(result);
        }
    }).catch(e => {
        console.error(`Could not load base configuration data for id "${id}"`, e);
        return Promise.reject();
    });
}


export function initInScope($scope) {
    $scope.find('.js-event-ticket-config').each(function () {
        let $EventTicketConfigurator = $(this);
        let $EventTicketConfiguratorModal = $EventTicketConfigurator.find('.js-event-ticket-config__modal');
        let $EventTicketConfiguratorOpen = $EventTicketConfigurator.find('.js-event-ticket-config__open');
        let id = $(this).data('ticket-id');
        let cartId = $(this).data('ticket-cart-id');
        let reloadOnClose = $EventTicketConfiguratorModal.data('reload-on-close') || false;


        const renderWithData = data => render(<EventTicketConfig {...data}/>, $EventTicketConfiguratorModal[0]);

        let params = {
            isModalOpen: true,
            onModalHide: (function () {
                params.isModalOpen = false;
                renderWithData(params);
                if(reloadOnClose) {
                    location.reload();
                }
            }),
            isLoading: true,
            id: id,
            cartId: cartId
        };

        $EventTicketConfiguratorOpen.each(function() {
            let item = this;

            item.addEventListener('click', function () {
                let $this = $(this);
                params.isModalOpen = true;
                let selectedConfiguration = getPrefixedDataSet('ticket-config', $(this));
                let dataset = {
                    ...getPrefixedDataSet('ticket-config', $EventTicketConfigurator),
                    ...selectedConfiguration
                };

                if (dataset) {
                    // key is used to force rerender to use default values
                    params = {
                        ...params,
                        key: Math.random(),
                        ...dataset
                    };
                }

                renderWithData(params);
            }, {capture: true});

            item.addEventListener('click', function (event) {
                getInitialEventTicketData(id).then(result => {
                    params = {
                        ...params,
                        ...result.data,
                        isLoading: false
                    };
                    renderWithData(params);
                }).catch(e => {
                    console.error(`Could not load base configuration data for id "${id}"`, e);
                });

                event.target.removeEventListener('click', event, false);
            }, {capture: true});
        });

    });
}
