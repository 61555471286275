"use strict";

import {scrollTo} from "./scrollTo";
import {add} from './scrollAnimations';

export function initInScope ($scope) {
    $scope.find('.js-waypoints__scroll-to').on('click', function (evt) {
        evt.preventDefault();
        let $waypointButton = $(this);
        let targetSelector = $waypointButton.data('scroll-target') || $waypointButton.attr('href');
        let $target = $(targetSelector);

        scrollTo($target, function () {
                $waypointButton.closest('.waypoints').find('.waypoints__item').removeClass('waypoints__item--active');
                $waypointButton.closest('.waypoints__item').addClass('waypoints__item--active');
            },
            true);
    });
    add(
        $scope.find('.js-waypoints__scroll-to'),
        calculateAnimationProgress,
        setAnimationProgress
    );
}

function calculateAnimationProgress(element) {
    const start = window.innerHeight;
    const end = 0;

    let contentElement = $($(element).attr('href'));

    return 1 - Math.max(
        Math.min(
            (contentElement[0].getBoundingClientRect().top - end) / (start - end),
            1
        ),
        0
    );
}

function setAnimationProgress(element, progress) {
    let $el = $(element);
    if (progress > 0.01 && progress < 1) {
        if (!$el.closest('.waypoints__item').hasClass('waypoints__item--active')) {
            $el.closest('.waypoints').find('.waypoints__item').removeClass('waypoints__item--active');
            $el.closest('.waypoints__item').addClass('waypoints__item--active');
        }
    } else {
        if ($el.closest('.waypoints__item').hasClass('waypoints__item--active')) {
            $el.closest('.waypoints').find('.waypoints__item').removeClass('waypoints__item--active');
        }
    }
}

