"use strict";

import initModulesInScope from "./initModulesInScope";

export function initInScope($scope) {
    let $quickfinderSelect = $scope.find('.js-quickfinder__select');
    $quickfinderSelect.on('change', function () {
        showQuickfinderTemplate($(this));
    }); 
}

export function init() {
    let $quickfinderSelect = $('.js-quickfinder__select');

    $quickfinderSelect.each(function() {
        showQuickfinderTemplate($(this));
    });
}

function showQuickfinderTemplate($quickfinderSelect) {
    let $quickfinderSelectWrapper = $quickfinderSelect.closest('.js-quickfinder__select-wrapper'),
        $quickfinder = $quickfinderSelect.closest('.js-quickfinder'),
        $quickfinderTemplate = $quickfinder.find($quickfinderSelect.data('template')),
        $quickfinderTemplateContainer = $quickfinder.find($quickfinderSelect.data('template-container')),
        $quickfinderTemplateContainerWrapper = $quickfinder.find($quickfinderSelect.data('tamplate-container-wrapper'));
    $quickfinderTemplateContainer.html('');

    if ($quickfinderSelect.val() > 0) {
        $quickfinderTemplateContainerWrapper.attr('hidden', false);
        for (let i = 1; i <= $quickfinderSelect.val(); i++) {
            let $newTemplate = createTemplate(i, $quickfinderTemplate);
            $quickfinderTemplateContainer.append($newTemplate);
        }
        initModulesInScope($quickfinderTemplateContainer);
    } else {
        $quickfinderTemplateContainerWrapper.attr('hidden', true);
    }
}

function createTemplate(index, $template) {
    let templateHtml = $template.html();
    let $newTemplate = $(templateHtml.replace(/{{index}}/g, index));
    $newTemplate.removeClass('js-quickfinder__child-template');
    return $newTemplate;
}