/** @jsx h */

import {h} from "preact";
import {noop} from "../utils";

export default function StepNav({
    hasPreviousError = false,
    current = 0,
    max,
    onStepClick = noop
}) {
    let steps = Array(max).fill(0);

    // Helper functions
    const toggleIsPreviousText = index => {
        if (current > index && hasPreviousError) {
            return <span className="icon icon-close"></span>
        } else if (current > index) {
           return <span className="icon icon-check"></span>
        } else {
           return index + 1;
        }
    };

    return (
        <ol className={`step-legend mb-3 list-unstyled  ${hasPreviousError ? 'has-error' : ''}`}>
            {steps.map((x, index) => (
                <li key={index} className={`step-legend__item ${index === current ? 'active' : ''} ${current > index ? 'is-previous' : ''}`}>
                    <button className={`step-legend__counter btn-no-styling`}
                            type="button"
                            onClick={() => onStepClick(index)}>

                        { toggleIsPreviousText(index) }
                    </button>
                </li>
            ))}
        </ol>
    )
}