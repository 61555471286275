import { h, render } from 'preact';

export default function (props) {
    return (
        <div className="sorting-teaser-container">
            <section className="sorting-teaser">
                <input type="hidden" name={props.inputName} value={props.index + 1}/>
                <div className="embed-responsive embed-responsive-2by1">
                    <img className="embed-responsive-item" srcSet={props.imgUrl} />

                    <div className="sorting-teaser__count bg-primary text-white text-center">{props.index + 1}</div>
                </div>
            </section>
        </div>
    );
}