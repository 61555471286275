import {registerPermissionGrantedCallback} from "./cookiePermissions";

export function initInScope($scope) {
    $scope.find('.js-walls-io').each(function () {
       let $this = $(this);

       registerPermissionGrantedCallback('walls-io', function () {
           let script = document.createElement('script');

           script.setAttribute('src', $this.data('src'));
           Object.entries($this.data()).forEach(([key, value]) => {
               script.setAttribute('data-' + key, value);
           });

           $this.empty();
           $this[0].appendChild(script);
       })
    });
}