"use strict";

import initModulesInScope from "./initModulesInScope";
import {updateAffix} from "./affix";


let pendingRequest;
let $loading = $('.js-cart__list-loading');
let $listResult = $('.js-cart__list-result');

export function init() {
    $('.js-cart-overlay').on('overlay.show', function () {
        shoppingListChangeHandler();
    });
}

export function shoppingListChangeHandler() {
    if (pendingRequest) {
        pendingRequest.abort();
        pendingRequest = null;
    }

    if (!_config.shoppingListDetailUrl) {
        console.error('Can\'t fetch like list details for user overlay. _config.shoppingListDetailUrl is not set');
        return;
    }

    $loading.attr('hidden', null);

    pendingRequest = $.ajax(_config.shoppingListDetailUrl, {
        cache: false,
        dataType: 'html',
        data: {}
    });

    pendingRequest.then(function (result) {
        $listResult.empty().attr('hidden', null);
        $listResult.html(result);
        initModulesInScope($listResult);

        /*if ($listResult.find('.js-affix')) {
            console.log("found affix");
            updateAffix($listResult);
        }*/
    });

    pendingRequest.catch(function (request, error) {
        if (error !== 'abort') {
            console.warn(error, request);
            $listResult.attr('hidden', 'hidden');
            $errorMsg.attr('hidden', null);
        }
    });

    pendingRequest.always(function () {
        $loading.attr('hidden', 'hidden');
        pendingRequest = null;
    });
}