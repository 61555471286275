"use strict";

import {h, render, Component} from 'preact';
import {getConfigValue} from '@elements/config-utils';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import Teaser from './render/sortable-teaser';

const arrayMoveMutate = (array, from, to) => {
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
};

const arrayMove = (array, from, to) => {
    array = array.slice();
    arrayMoveMutate(array, from, to);
    return array;
};

class Sorting extends Component {
    constructor(props) {
        super();
        this.state.items = props.initialItems || [];

        if(localStorage.getItem('sortingListState')) {
            this.state = JSON.parse(localStorage.getItem('sortingListState'));
        }

    }

    onSortEnd({oldIndex, newIndex}) {
        this.setState(({items}) => ({
            items: arrayMove(items, oldIndex, newIndex),
        }));

        localStorage.setItem('sortingListState', JSON.stringify(this.state));
    }

    render() {
        return (
            <SortableList
                axis={'xy'}
                helperClass={'sorting-teaser--move'}
                onSortEnd={(...params) => this.onSortEnd(...params)}
                items={this.state.items}
            />
        );
    }
}

const SortableList  = SortableContainer(({items, onSortEnd}) => {
    return (
        <div className="d-flex flex-wrap">
            {items.map((item, index) => (
                <Item
                    key={`item-${index}`}
                    index={index}
                    {...item}
                />
            ))}
        </div>
    );
});

const Item = SortableElement((props) => (
    <Teaser{...props}></Teaser>
));

export function initInScope($scope) {
    let initialItems = getConfigValue('_sortingItems');

    $scope.find('.js-sortable').each(function () {
        render(<Sorting initialItems={initialItems}/>, this);
    });
}