"use strict";

import initModulesInScope from "./initModulesInScope";

export function init() {
    $('.js-user-overlay').on('overlay.show', function () {
       likeListChangeHandler();
    });

    let pendingRequest;

    function likeListChangeHandler() {
        let $loading = $('.js-user-overlay__list-loading');
        let $listResult = $('.js-user-overlay__list-result');
        let $errorMsg = $('.js-user-overlay__list-error');

        if (pendingRequest) {
            pendingRequest.abort();
            pendingRequest = null;
        }

        if (!_config.likeListDetailUrl) {
            console.error('Can\'t fetch like list details for user overlay. _config.likeListDetailUrl is not set');
            return;
        }

        $loading.attr('hidden', null);
        $errorMsg.attr('hidden', 'hidden');
        $listResult.empty().attr('hidden', null);

        pendingRequest = $.ajax(_config.likeListDetailUrl, {
            cache: false,
            dataType: 'html'
        });
        
        console.log("like list user overlay");

        pendingRequest.then(function (result) {
            console.log("result", $listResult);
            $listResult.html(result);
            initModulesInScope($listResult);
        });

        pendingRequest.catch(function (request, error) {
            if (error !== 'abort') {
                console.warn(error, request);
                $listResult.attr('hidden', 'hidden');
                $errorMsg.attr('hidden', null);
            }
        });

        pendingRequest.always(function () {
            $loading.attr('hidden', 'hidden');
            pendingRequest = null;
        });
    }
}

