"use strict";

export function initInScope($scope) {
    let $input = $scope.find('.js-toggle-inputs__input');

    $input.on('change', function () {
        let $form = $(this).closest('.js-toggle-inputs');
        let inputToToggle = $(this).data('inputs-to-toggle');

        if ($(this).is(':checked')) {
           $form.find(`[name="${inputToToggle}"]`).prop('checked', true);
        } else {
            $form.find(`[name="${inputToToggle}"]`).prop('checked', false);
        }
    });
}