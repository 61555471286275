"use strict";



export function initInScope ($scope) {
    import('jquery-inview').then(function() {
        let inviewElement = $scope.find('.js-video-inview');
        let timer = 300;

        inviewElement.prop('muted', true);
        setTimeout(function(){
            inviewElement.on('inview', function(evt, isInView) {
                if (isInView) {
                    $(this).trigger("play");
                    console.log("Video in view");
                } else {
                    $(this).trigger("pause");
                    console.log("Video NOT in view");
                }
            });
        }, timer);

    });
}
