"use strict";

let isRunning = false;
let elements = [];

export function initInScope($scope) {
    let $newElements = $scope.find('.js-affix-bottom');
    if (!$newElements.length) {
        return;
    }
    

    elements = elements.concat($newElements.toArray().map(function (element) {
        let $element = $(element);
        let $placeholder = $element.closest('.js-affix-bottom-placeholder');
        let $container = $element.closest('.js-affix-bottom-container');

        $element.css('transform', 'translateZ(0)');

        if (!$container || !$container.length) {
            console.warn('Could not find parent ".js-affix-bottom-container" for element ', element);
            return null;
        }

        if (!$placeholder || !$placeholder.length) {
            console.warn('Could not find parent ".js-affix-bottom-placeholder" for element ', element);
            return null;
        }

        if ($placeholder && $placeholder.length) {
            $placeholder.css('min-height', element.getBoundingClientRect().height);
        }
        

        return {
            element: element,
            placeholder: $placeholder[0],
            container: $container[0],
            isAffix: false,
            offset: +$element.data('affix-offset') || 40,
            affixBottom: +$element.data('affix-bottom') || false
        }
    }).filter(x => !!x));


    if (!isRunning) {
        isRunning = true;
        requestAnimationFrame(interval);
    }
}

let lastScrollPosition = 0;
function interval() {
    if (lastScrollPosition === window.scrollY && lastScrollPosition !== 0) {
        requestAnimationFrame(interval);
        return;
    }

    lastScrollPosition = window.scrollY;

    elements.forEach(function (obj) {
        let elementRect =  obj.placeholder.getBoundingClientRect();
        let containerRect = obj.container.getBoundingClientRect();
        let affixTicketBtn =  $(obj.element).attr('data-affix-ticket-button') || false;

        if (obj.isAffix) {
            if(!affixTicketBtn) {
                $('.navbar-console-bottom').attr('hidden', true);

            }
            if (isContainerBottomReached(containerRect, elementRect, obj.offset, affixTicketBtn)) {

                $(obj.element).removeClass('is-affix bottom').css({
                    position: 'relative',
                    top:'containerRect.height - elementRect.top',
                    left: '',
                });

                obj.isAffix = false;

            } if (!isAffixTopReached(containerRect, obj.offset, affixTicketBtn, elementRect)){
                $(obj.element).removeClass('is-affix bottom').css({
                    position: '',
                    top:'',
                    left: '',
                    width: '',
                });

                obj.isAffix = false;
            }
        } else {
            if(!affixTicketBtn) {
                $('.navbar-console-bottom').attr('hidden', false);
            }
            if (isAffixTopReached(containerRect, obj.offset, affixTicketBtn, elementRect) && !isContainerBottomReached(containerRect, elementRect, obj.offset, affixTicketBtn)) {
                $(obj.element).addClass('is-affix bottom').css({
                    position: 'fixed',
                    bottom: 'obj.offset',
                    left: matchMedia('(max-width: 767px)').matches ? '0' : elementRect.left,
                    width: matchMedia('(max-width: 767px)').matches ? '100%' : elementRect.width,
                });

                obj.isAffix = true;
            }
        }
    });

    requestAnimationFrame(interval);
}

function isContainerBottomReached(containerRect, elementRect, offset, affixTicketBtn) {
    return containerRect.top + containerRect.height  <= window.innerHeight;
}

function isAffixTopReached(containerRect, offset, affixTicketBtn, elementRect) {
    if(affixTicketBtn) {
        return (elementRect.top - window.innerHeight + elementRect.height + offset) <= 0;
    }
    let offsetWindow = 400;
    return containerRect.top - offset <= window.innerHeight - offsetWindow
}
