import initModulesInScope from "./initModulesInScope";

export default function asyncAppend({
        $target,
        $loading = $(),
        $notifications = $()
    }, request) {

    // Unpack json response body if the promise was created via fetch
    request = request.then(response => (response
        && response.json
        && typeof response.json === 'function'
        && response.clone
        && typeof response.clone === 'function')
        ? response.clone().json()
        : response
    );

    let $allTargets = $target instanceof $
        ? $target
        : $([].concat(...Object.entries($target).map(([key, $element]) => $element.toArray())));

    let $targetsByResultId = $target instanceof $
        ? {default: $target}
        : $target;

    $allTargets.attr('hidden', null);
    $loading.attr('hidden', null);

    request.catch((error, requestState) => {
        $loading.attr('hidden', 'hidden');
    });

    return request
        .then(
            // Unpack json response body if the promise was created via fetch
            response => (response
                && response.json
                && typeof response.json === 'function'
                && response.clone
                && typeof response.clone === 'function')
                ? response.clone().json()
                : response
        )
        .then(function (result) {
            let content = result.html || result.content;

            if (content && result.success !== false) {
                let contentByResultId = typeof content === 'string'
                    ? {default: content}
                    : content;
                $allTargets.empty();
                Object.entries(contentByResultId).forEach(([resultId, result]) => {
                    if ($targetsByResultId[resultId]) {
                        $targetsByResultId[resultId].append (result);
                        initModulesInScope($targetsByResultId[resultId]);
                    } else {
                        console.warn(`async-append: Response contained entry with unmatched element.
                        Unmatched entry key was "${resultId}".
                        Dom elements were provided for "${Object.keys($targetsByResultId).join(', ')}" `)
                    }
                });
            }

            $loading.attr('hidden', 'hidden');
            return result;
        });
}


function hasElements($element) {
    return $element && $element.length;
}
