"use strict";

import {loadImg} from '@elements/lazy-img';

export function initInScope ($scope) {
    let $sliders = $scope.find('.js-slickslider');
    let $adventureSlider = $scope.find('.js-adventure-slider');
    let $adventureNavSlider = $scope.find('.js-adventure-nav-slider');
    let $adventureSliderMobile = $scope.find('.js-adventure-slider--mobile');
    let $imgTextSlider = $scope.find('.js-img-text-slider');
    let $teaserRowSliderMobile = $scope.find('.js-teaser-row-slider--mobile');
    let $companyTeaserSliderMobile = $scope.find('.js-company-teaser-slider-mobile');
    let $teaserRowSlider = $scope.find('.js-teaser-row-slider'),
        $reviewSlider = $scope.find('.js-review-slider'),
        $likeListTeaserRowSlider = $scope.find('.js-like-list-teaser-row-slider'),
        $teaserCenterSlider = $scope.find('.js-teaser-row-center-slider'),
        $offerTeaserSlider = $scope.find('.js-offer-teaser-slider'),
        $socialSlider = $scope.find('.js-social-slider');

    import('slick-carousel').then(function() {
        $sliders.slick({
            dots: true, /*should always be true; hide with css (accessibility)*/
            slidesToShow: 1,
            swipeToSlide: true,
            infinite: false
        });

        $adventureSlider.on('init', function (event, slick) {
            preloadSlide(slick, 1);
        }).on('afterChange', function (event, slick, currentSlide) {
            preloadSlide(slick, currentSlide + 1);
        }).slick({
            dots:true,
            slidesToShow:1,
            swipeToSlide: true,
            infinite:true,
            fade:true,
            cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
            speed:900,
            prevArrow: $('.arrow-prev'),
            nextArrow: $('.arrow-next'),
            ...$adventureNavSlider.length ? {asNavFor: $adventureNavSlider}: {}
        }).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            preloadSlide(slick, nextSlide);
        }); 

        $adventureNavSlider.each(function() {
            $(this).on('init', function (event, slick) {
                preloadSlideNegativeIndex($(this), slick, -1);
                preloadSlideNegativeIndex($(this), slick,  slick.$slides.length -1);
            }).slick({
                slidesToShow:4,
                slidesToScroll:1,
                cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
                speed:900,
                ...$adventureSlider.length ? {asNavFor: $adventureSlider}: {},
                focusOnSelect:true,
                dots:true,
                arrows:false,
                infinite:true,
                centerMode:false
            }).on('afterChange', function (event, slick, currentSlide) {
                preloadSlideNegativeIndex($(this), slick, currentSlide - 1);
                preloadSlideNegativeIndex($(this), slick, currentSlide + 1);
            });
        });
        $adventureSliderMobile.each(function() {
            $(this).slick({
                slidesToShow:1.2,
                centerMode: false,
                dots:true,
                infinite:false,
                arrows:false
            }); 
        });

        $teaserRowSliderMobile.each(function() {
            $(this).slick({
                slidesToShow:1.4,
                centerMode: false,
                dots:true,
                infinite:false,
                arrows:false,
            });
        });

        $companyTeaserSliderMobile.each(function() {
            $(this).slick({
                slidesToShow:1.4,
                centerMode: false,
                dots:true,
                infinite:false,
                arrows:true
            });
        });

        $teaserRowSlider.each(function() {
            $(this).slick({
                dots:true,
                slidesToShow: $(this).attr('data-teaser-slides') || 4,
                swipeToSlide: true,
                appendDots: $(this).parent().find('.teaser-row-slider__dots'),
                infinite:false,
                prevArrow: $(this).parent().find('.teaser-row-slider__arrow.arrow-prev'),
                nextArrow: $(this).parent().find('.teaser-row-slider__arrow.arrow-next'),
            });
        });

        $offerTeaserSlider.each(function() {
            $(this).slick({
                dots:true,
                slidesToShow: $(this).attr('data-teaser-slides') || 4,
                slidesToScroll:$(this).attr('data-teaser-slides') || 4,
                appendDots: $(this).parent().find('.offer-teaser-slider__dots'),
                infinite:false,
                prevArrow: $(this).parent().find('.offer-teaser-slider__arrow.arrow-prev'),
                nextArrow: $(this).parent().find('.offer-teaser-slider__arrow.arrow-next'),
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1.4,
                            slidesToScroll: 1,
                            infinite: false,
                            appendDots: false,
                            arrows:false,
                            dots: true,
                            variableWidth:false,
                            centerMode: false
                        }
                    }
                ]
            });
        });

        $teaserCenterSlider.each(function() {
            $(this).slick({
                centerMode: true,
                slidesToShow: 3,
                swipeToSlide: true,
                prevArrow: $(this).parent().find('.teaser-row-slider__arrow.arrow-prev'),
                nextArrow: $(this).parent().find('.teaser-row-slider__arrow.arrow-next'),
                responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
            });
        });

        $likeListTeaserRowSlider.each(function() {
            $(this).slick({
                dots:true,
                slidesToShow: 2.1,
                swipeToSlide: true,
                infinite: false,
                prevArrow: $(this).parent().find('.teaser-row-slider__arrow.arrow-prev'),
                nextArrow: $(this).parent().find('.teaser-row-slider__arrow.arrow-next'),
            });
        });

        $reviewSlider.each(function() {
           $(this).slick({
               dots:true,
               slidesToShow: 1,
               swipeToSlide: true,
               cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
               speed:1100,
               fade:false,
               infinite: false,
               prevArrow: $(this).parent().find('.review-slider__arrow.arrow-prev'),
               nextArrow: $(this).parent().find('.review-slider__arrow.arrow-next'),
           })
        });

        $socialSlider.each(function() {
            $(this).slick({
                dots:true,
                slidesToShow: 4,
                slidesToScroll: 4,
                swipeToSlide: true,
                infinite: false,
                prevArrow: $(this).closest('.social-slider__wrapper').find('.social-slider__arrow.arrow-prev'),
                nextArrow: $(this).closest('.social-slider__wrapper').find('.social-slider__arrow.arrow-next'),
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1.4,
                            slidesToScroll: 1,
                            infinite: false,
                            dots: true,
                            variableWidth:false,
                            centerMode: false

                        }
                    }
                ]
            })
        });

        $imgTextSlider.each(function() {
            $(this).slick({
                dots: true, /*should always be true; hide with css (accessibility)*/
                slidesToShow: 1,
                centerMode: true,
                infinite: false,
                speed: 500,
                variableWidth: true,
                cssEase: 'ease',
                prevArrow: $(this).parent().find('.img-text-slider__arrow.arrow-prev'),
                nextArrow: $(this).parent().find('.img-text-slider__arrow.arrow-next'),
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            infinite: false,
                            dots: true,
                            variableWidth:false,
                            centerMode: false

                        }
                    }
                ]
            }).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                preloadSlide(slick, nextSlide + (matchMedia('(max-width: 767px)').matches ? 0: 1))
            });
        });


        let $allSliders = $();
        $allSliders = $allSliders
            .add($sliders)
            .add($adventureSlider)
            .add($adventureNavSlider)
            .add($adventureSliderMobile)
            .add($imgTextSlider)
            .add($teaserRowSliderMobile)
            .add($companyTeaserSliderMobile)
            .add($teaserRowSlider)
            .add($reviewSlider)
            .add($likeListTeaserRowSlider)
            .add($teaserCenterSlider)
            .add($socialSlider);

        $allSliders.each(function () {
            let $slider = $(this);
            let $tabPaneParent = $slider.closest('.tab-pane');

            if ($tabPaneParent && $tabPaneParent.length) {
                var $tabTrigger = $('[href="#' + $tabPaneParent.attr('id') + '"],[data-target="#' + $tabPaneParent.attr('id') + '"]');
                if ($tabTrigger && $tabTrigger.length) {
                    $tabTrigger.on('shown.bs.tab', function () {
                        $slider.slick('setPosition');
                    });
                }
            }
        })
    });
}

export function preloadSlide(slick, slideIndex) {
    let slideToPreload = slick.$slides[slideIndex];

    if (slideToPreload) {
        let $imgToPreload = $(slideToPreload).find('.js-lazy-img');
        if ($imgToPreload && $imgToPreload.length) {
            loadImg($imgToPreload);
        }
    }
}

export function preloadSlideNegativeIndex($slider, slick, slideIndex) {
    let slideToPreload = $slider.find(`[data-slick-index='${slideIndex}']`);

    if (slideToPreload) {
        let $imgToPreload = $(slideToPreload).find('.js-lazy-img');
        if ($imgToPreload && $imgToPreload.length) {
            loadImg($imgToPreload);
        }
    }
}