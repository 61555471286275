import $ from 'jquery';

let app = window.app || {};
window.app = app || {};
window.app.modules =  app.modules || {};


/**********************************
 *
 *      Project Stuff
 *
 * ********************************/


import { showCookieBar } from "../scripts/cookiePermissions";
showCookieBar();

import * as allowCookieBtn from '../scripts/allowCookieBtn';
app.modules.allowCookieBtn = allowCookieBtn.initInScope;


import * as ajaxInclude from '../scripts/ajaxInclude';
app.modules.ajaxInclude = ajaxInclude.initInScope;

import * as navbar from '../scripts/navbar';
app.navbar = navbar.init;
app.modules.navbar = navbar.initInScope;

import * as toc from '../scripts/toc';
app.toc = toc.init;
app.modules.toc = toc.initInScope;

import * as skipLinks from '../scripts/skip-links';
app.skipLinks = skipLinks.init;

import * as actionChanger from '../scripts/actionChanger';
app.modules.actionChanger = actionChanger.initInScope;

import * as isClickable from '../scripts/isClickable';
app.modules.isClickable = isClickable.initInScope;

import * as affix from '../scripts/affix';
app.modules.affix = affix.initInScope;

import * as affixBottom from '../scripts/affixBottom';
app.modules.affixBottom = affixBottom.initInScope;

import * as parsley from '../scripts/parsley';
app.modules.parsley = parsley.initInScope;

import * as ajaxForm from '../scripts/ajaxForm';
app.modules.ajaxForm = ajaxForm.initInScope;

import * as shariff from '../scripts/shariff.js';
app.modules.shariff = shariff.initInScope;

import * as overlay from '../scripts/overlay';
app.overlay = overlay.init;
app.modules.overlay = overlay.initInScope;

import * as likeList from '../scripts/likeList.js';
app.likeList = likeList.init;
app.modules.likeList = likeList.initInScope;

import * as shoppingList from '../scripts/shoppingList.js';
app.shoppingList = shoppingList.init;
app.modules.shoppingList = shoppingList.initInScope;

import * as shoppingCart from '../scripts/shoppingCart.js';
app.modules.shoppingCart = shoppingCart.initInScope;

import * as videoJs from '../scripts/videoJs';
app.modules.videoJs = videoJs.initInScope;

import * as userOverlay from '../scripts/userOverlay';
app.userOverlay = userOverlay.init;

import * as cartOverlay from '../scripts/cartOverlay';
app.cartOverlay = cartOverlay.init;

let selectors = {
    base: '.js-lazy-img',
    backgroundModifier: '.js-lazy-img--bg'
};

import * as lazyImg from '@elements/lazy-img';
app.modules.lazyImg = lazyImg.createInitInScope(selectors, {
    preventNative: true,
    offset: window.innerHeight / 2
});

import * as clickNav from '../scripts/click-nav';
app.modules.clickNav = clickNav.initInScope;

import * as datePicker from '../scripts/datePicker';
app.modules.datePicker = datePicker.initInScope;

import * as rangeDatePicker from '../scripts/rangeDatePicker';
app.modules.rangeDatePicker = rangeDatePicker.initInScope;

import * as lightbox from '../scripts/lightbox';
app.modules.lightbox = lightbox.initInScope;

import * as checkoutTracking from '../scripts/checkoutTracking';
app.modules.checkoutTracking = checkoutTracking.initInScope;

import * as numberSpinner from '../scripts/numberSpinner';
app.modules.numberSpinner = numberSpinner.initInScope;

import * as numberSpinnerGroup from '../scripts/numberSpinnerGroup';
app.modules.numberSpinnerGroup = numberSpinnerGroup.initInScope;

import * as numberSpinnerGroupEnableButton from '../scripts/numberSpinnerGroupEnableButton';
app.modules.numberSpinnerGroupEnableButton = numberSpinnerGroupEnableButton.initInScope;

import * as birthdayTimestamp from '../scripts/birthdayTimestamp';
app.modules.birthdayTimestamp = birthdayTimestamp.initInScope;

import * as personalization from '../scripts/personalization';
app.modules.personalization = personalization.initInScope;

import * as scrollTo from '../scripts/scrollTo';
app.modules.scrollTo = scrollTo.initInScope;

import * as person from '../scripts/person';
app.modules.person = person.initInScope;

import * as radioTabs from '../scripts/radioTabs';
app.modules.radioTabs = radioTabs.initInScope;

import * as keycard from '../scripts/keycard';
app.modules.keycard = keycard.initInScope;

import * as darkpageOverlay from '../scripts/darkpageOverlay';
app.modules.darkpageOverlay = darkpageOverlay.initInScope;

import * as darkpageOverlayWebcam from '../scripts/darkpageOverlayWebcam';
app.modules.darkpageOverlayWebcam = darkpageOverlayWebcam.initInScope;

import * as jobFinder from '../scripts/jobFinder';
app.modules.jobFinder = jobFinder.initInScope;

import * as collapseCheckbox from '../scripts/collapseCheckbox';
app.modules.collapseCheckbox = collapseCheckbox.initInScope;

import * as checkoutForm from '../scripts/checkoutForm';
app.checkoutForm = checkoutForm.init;

import * as loadNextStep from '../scripts/loadNextStep';
app.modules.loadNextStep = loadNextStep.initInScope;

import * as expandable from '../scripts/expandable';
app.modules.expandable = expandable.initInScope;

import * as interestsFilter from '../scripts/interestsFilter';
app.modules.interestsFilter = interestsFilter.initInScope;

import * as popupWindow from '../scripts/popupWindow';
app.modules.popupWindow = popupWindow.initInScope;

import * as openPersonalizationModalByDefault from '../scripts/openPersonalizationModalByDefault';
app.modules.openPersonalizationModalByDefault = openPersonalizationModalByDefault.initInScope;

import * as variantChange from '../scripts/merchandise.js';
app.modules.variantChange = variantChange.initInScope;

import * as dropDown from '../scripts/dropDown';
app.modules.dropDown = dropDown.initInScope;

import * as tooltip from '../scripts/tooltip';
app.tooltip = tooltip.init;

app.modules.popover = function ($scope) {
    $scope.find('[data-toggle="popover"]').popover();
};

import * as showKeycardInput from '../scripts/showKeycardInput';
app.modules.showKeycardInput = showKeycardInput.initInScope;

import * as shopAffiliateZermatt from '../scripts/shopAffiliateZermatt';
app.modules.shopAffiliateZermatt = shopAffiliateZermatt.initInScope;

import * as notificationHeader from '../scripts/notificationHeader';
app.modules.notificationHeader = notificationHeader.initInScope;

import * as toggleInputs from '../scripts/toggleInputs';
app.modules.toggleInputs = toggleInputs.initInScope;

import trackingInit, {initInScope as trackingInitInScope} from "../scripts/tracking";
app.tracking = trackingInit;
app.modules.tracking = trackingInitInScope;

import * as qrScanner from '../scripts/qrScanner';
app.modules.qrScanner = qrScanner.initInScope;

import * as iframeResizing from '../scripts/iframeResizing';
app.modules.iframeResizing = iframeResizing.initInScope;

import * as iframeSizeBridge from '../scripts/iframeSizeBridge';
app.modules.iframeSizeBridge = iframeSizeBridge.initInScope;

import * as conditionalForm from '../scripts/conditionalForm';
app.modules.conditionalForm = conditionalForm.initInScope;