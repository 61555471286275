"use strict";

export function initInScope($scope) {
    let $collapseCheckboxContainer = $scope.find('.js-collapse-checkbox__content'),
        $collapseHideAttributeElem = $scope.find('.js-collapse-checkbox__hide-attr-on-collapse');

    $collapseCheckboxContainer.on('show.bs.collapse', function () {
        let id = $(this).attr('id');
        let $collapseCheckbox = $scope.find('.js-collapse-checkbox[href="#' + id + '"] input');
        $collapseCheckbox.prop('checked', true);
        if($collapseHideAttributeElem.length >= 1) {
            let attributeToHide = $collapseHideAttributeElem.attr('data-attribute-to-hide');
            $collapseHideAttributeElem.removeAttr(attributeToHide);
        }
    });

    $collapseCheckboxContainer.on('hide.bs.collapse', function () {
        let id = $(this).attr('id');
        let $collapseCheckbox = $scope.find('.js-collapse-checkbox[href="#' + id + '"] input');
        $collapseCheckbox.addClass('test');
        $collapseCheckbox.prop('checked', false);
        if($collapseHideAttributeElem.length >= 1) {
            let attributeToHide = $collapseHideAttributeElem.attr('data-attribute-to-hide');
            $collapseHideAttributeElem.attr(attributeToHide, true);
        }
    });
}

export function collapseCheckboxShow() {
    let $collapseCheckboxContainer = $('.js-collapse-checkbox__content');
    $collapseCheckboxContainer.collapse('show');
} 