"use strict";

import {isParsleyForm, isValid} from "./parsley";
import initModulesInScope from "./initModulesInScope";

export function initInScope($scope) {
    $('.js-ajax-form').each(function () {
        let $container = $(this);
        let $result = $container.find('.js-ajax-form__result');
        let $loading = $container.find('.js-ajax-form__loading');
        let $error = $container.find('.js-ajax-form__error');
        let $errorContainer = $container.find('.js-ajax-form__error-container');
        let pendingRequest = null;
        let $submitOnChange = $container.data('trigger') || false;
        let $form = $container.find('.js-ajax-form__form');

        if($submitOnChange) {
            $form.on('change', function(evt) {
                $form.submit();
            });
        }

        $form.on('submit', function (evt) {
            evt.preventDefault();
            evt.stopImmediatePropagation();

            let $form = $(this);
            if (pendingRequest) {
                pendingRequest.abort();
                pendingRequest = null;
            }
            if (isParsleyForm($form) && !isValid($form)) {
                return;
            }

            $result.attr('hidden', null);
            $loading.attr('hidden', null);

            let isJsonData = $form.data('ajax-form-json-data') || false;
            let formData = new FormData($form[0]) || $form.serializeArray();
            let type = $form.attr('method') || 'POST';

            let action = $form.data('action') || $form.attr('action');

            if(isJsonData) {
                pendingRequest = $.ajax(action, {
                    data: formData,
                    method: type,
                    dataType: 'json',
                    processData: false,
                    contentType:false
                }).done(function (result) {
                    if(result.status == 'success') {
                        $result.empty().append(result.content);
                        initModulesInScope($result);
                        if($form.data('ajax-form-close-modal') && $form.closest('.modal').length > 0) {
                            $form.closest('.modal').modal('hide');
                            if($('body').hasClass('modal-open')) {
                                $('body').removeClass('modal-open');
                            }
                        }
                    } else {
                        if(result.error) {
                            $errorContainer.attr('hidden', null);
                            $errorContainer.empty().append(result.error);
                        }
                    }
                }).fail(function (jqXHR, textStatus, errorThrown) {
                    if (textStatus !== 'abort') {
                        $error.attr('hidden', null);
                        console.error(textStatus, errorThrown, jqXHR);
                    }
                }).always(function () {
                    $loading.attr('hidden', 'hidden');
                    pendingRequest = null;
                })
            } else {
                pendingRequest = $.ajax(action, {
                    data: formData,
                    dataType: 'html',
                    method: type,
                    processData:false,
                    contentType:false
                }).done(function (result) {
                    $result.empty().append(result);
                    initModulesInScope($result);
                    if($form.data('ajax-form-close-modal') && $form.closest('.modal').length > 0) {
                        $form.closest('.modal').modal('hide');
                    }
                }).fail(function (jqXHR, textStatus, errorThrown) {
                    if (textStatus !== 'abort') {
                        $error.attr('hidden', null);
                        console.error(textStatus, errorThrown, jqXHR);
                    }
                }).always(function () {
                    $loading.attr('hidden', 'hidden');
                    pendingRequest = null;
                })
            }

        });
    })
}