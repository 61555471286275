"use strict";

let promise;

export default function (){
    if (promise) {
        return promise;
    }

    promise = $.Deferred();

    import("jquery-ui-datepicker-with-i18n/ui/jquery.ui.datepicker.js").then(function () {
        if (_config.lang && _config.lang !== 'en') {
            import('jquery-ui-datepicker-with-i18n/ui/i18n/jquery.ui.datepicker-' + _config.lang + '.js').then(function () {
                promise.resolve();
            }).catch(function () {
                /*fallback if there is no package*/
                promise.resolve();
            });
        } else {
            promise.resolve();
        }
    }).catch(function () {
        promise.reject();
    });

    return promise;
};
