"use strict";

export function initInScope($scope) {
    let $item = $scope.find('.js-show-keycard-input');

    $item.on('change', function() {
        let $option = $(this).find('option:selected');
        let $wrapper =  $(this).closest('.js-show-keycard-input__wrapper');
        let $target = $(this).closest('form').find($wrapper.data('target'));
        let $input = $wrapper.find('.js-show-keycard-input__input');

        if($option.attr('data-show-keycard-input')) {
            $target.attr('hidden', false);
            $input.attr('disabled', false);
        } else {
            $target.attr('hidden', true);
            $input.attr('disabled', true);
        }
    });
}