"use strict";



export function initInScope ($scope) {
    import('jquery-inview').then(function() {
        let inviewPortalTeaser = $scope.find('.js-inView__portal-teaser');
        let inviewTeaser = $scope.find('.js-inView__portal-teaser .teaser');
        let inviewElement = $scope.find('.js-inview');
        let timer = 300;

        inviewPortalTeaser.on('inview', function(event, isInView) {
            inviewTeaser.each(function() {
                let $self = $(this);
                if(isInView) {
                    setTimeout(function() {
                        $self.addClass('inview-reveal');
                        $self.find('.teaser__image-headline').addClass('inview');
                    }, 100 + timer);
                }
                timer = timer + 200;
            });

        });

        inviewElement.on('inview', function(evt, isInView) {
            $(this).addClass('is-inview');
        });

    });
}
