/** @jsx h */

import {h, render, Fragment} from 'preact';
import {useState, useEffect} from 'preact/hooks';
import StepNav from "./components/StepNav";
import CurrencySwitch from "./components/CurrencySwitch";
import LoadingSpinner from "./components/LoadingSpinner";
import DatepickerRange from "./components/DatepickerRange";
import {translate} from '@elements/translations';
import fetch from '@elements/fetch';
import SkiTicketConfigurationList from "./components/SkiTicketConfiguration/SkiTicketConfigurationList";
import SkiTicketConfigurationForm from "./components/SkiTicketConfiguration/SkiTicketConfigurationForm";
import { v4 as uuidv4 } from 'uuid';
import { Modal } from 'react-bootstrap';
import {noop} from "./utils.js";
import {addParamsToUrl} from "./utils";
import {getPrefixedDataSet} from "./data-set-utils";
import {animateNotification, updateNotificationUI} from "./shoppingList";
import {responseTracking} from "@elements/tracking";
import Datepicker from "./components/Datepicker";
import {UTCDateToLocalDate} from "./rangeDatePicker";

let deletedCartItems = [];

function SkiTicketConfig({
    isLoading,
    id,
    noDatepicker,
    stepDefault = 0,
    fromDefault = null,
    toDefault = null,
    configurationsDefault = [],
    ageGroups = [],
    name = "",
    currencies = [],
    persons = {byId: {}, allIds: []},
    datePickerOptions = [],
    datePickerDescription = "",
    priceTendencies = {byId: {}, allIds: []},
    seasons = {byId: {}, allIds: []},
    onModalHide = noop,
    insurancesDefault = {byId: {}, allIds: []},
    upgradesDefault = {byId: {}, allIds: []},
    specialTicketsDefault = {byId: {}, allIds: []},
    pricesDefault = [],
    selectedConfigurationIdDefault = null,
    selectedAgeGroupIdDefault = null,
    durationDefault = null,
    standardTicketDefault = {name: "", description: ""},
    isModalOpen = true,
}) {
    const maxStep = 2;

    // State
    const [loadingData, setLoadingData] = useState(false);
    const [completedConfiguration, setCompletedConfiguration] = useState(false);
    const [currentStep, setCurrentStep] = useState(stepDefault); // 0 is initial state
    const [fromDate, setFromDate] = useState(fromDefault); // today is initial date
    const [toDate, setToDate] = useState(toDefault);
    const [configurations, setConfigurations] = useState(configurationsDefault);
    const [selectedConfigurationId, setSelectedConfigurationId] = useState(selectedConfigurationIdDefault); // determines which configuration is currently edited in the form
    const [selectedCurrencyId, setSelectedCurrencyId] = useState("chf");
    const [selectedPersonId, setSelectedPersonId] = useState(null);
    const [prices, setPrices] = useState(pricesDefault);
    const [insurances, setInsurances] = useState(insurancesDefault);
    const [upgrades, setUpgrades] = useState(upgradesDefault);
    const [specialTickets, setSpecialTickets] = useState(specialTicketsDefault);
    const [addedToCartCount, setAddedToCartCount] = useState(0);
    const [hasConfigurationError, setHasConfigurationError] = useState(false);
    const [duration, setDuration] = useState(durationDefault);
    const [standardTicket, setStandardTicket] = useState(standardTicketDefault);


    // Helper Functions
    const handleSubmit = newConfiguration => {
        if (selectedConfigurationId) {
            // update existing configuration
            setConfigurations(configurations.map(function (configuration) {
                return configuration.id === selectedConfigurationId
                    ? {...configuration, ...newConfiguration}
                    : configuration
            }));


            setSelectedConfigurationId(null);
        } else {
            // add new configuration
            setConfigurations([...configurations, {
                id: uuidv4(), // generate a local unique id
                ...newConfiguration,
            }]);
            
            console.log(configurations); 
        }
        if(configurations !== []) {
            
            console.log("configurations not null");
            console.log(JSON.stringify(configurations));
        }
    };

    const addConfigurationsToCart = () => {
        const _config = window._config || {};
    
        if (!_config.addToCartUrl) {
            console.warn(`"_config.addToCartUrl" is not set`);
        }

        setLoadingData(true);

        let data = {
            id: id,
            fromDate: fromDate,
            toDate: toDate,
            type: 'skiticket',
            deletedCartItems: deletedCartItems,
            configurations: configurations.map(({id, ...rest}) => rest)
        };

        let request = fetch(_config.addToCartUrl, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        responseTracking(request);

        request.then(result => result.json()).then(result => {
            if (result.success === true) {
                setCompletedConfiguration(true);
                setAddedToCartCount(result.addedCounter);
                animateNotification();
                updateNotificationUI(result.cartCounter);
                setLoadingData(false);
            } else {
                console.warn("ERROR ADD to cart");
                setLoadingData(false);
            }
        }).catch(e => {
            setLoadingData(false);
            console.error(`Could not load configuration data (options and price) for id "${id}"`, e);
        });
    };

    const getSkiTicketData = id => {
        const _config = window._config || {};

        if (!_config.skiTicketDataUrl) {
           console.warn(`"_config.skiTicketDataUrl" is not set`);
        }

        setLoadingData(true);
        
        let data = {
            id: id,
            fromDate: fromDate,
            toDate: toDate
        };

        const url = addParamsToUrl(_config.skiTicketDataUrl, data);

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(result => result.json()).then(result => {
            if (result.success === true) {
                setPrices(result.data.prices);
                setInsurances(result.data.insurances);
                setUpgrades(result.data.upgrades);
                setSpecialTickets(result.data.specialTickets);
                if(result.data.duration) {
                    setDuration(result.data.duration);
                }
                if(result.data.standardTicket) {
                    console.log(result.data.standardTicket); 
                    setStandardTicket(result.data.standardTicket);
                }
                setHasConfigurationError(false);
                setLoadingData(false);
            } else {
                setHasConfigurationError(true);
                setLoadingData(false);
            }
        }).catch(e => {
            console.error(`Could not load configuration data (insurances, upgrades, specialTickets and prices) for id "${id}"`, e);
        });
    };

    const getSelectedAgeGroupId = () => {
        let ageGroupTempId = null;

        if(selectedConfigurationId) {
            ageGroupTempId = getConfigurationById(selectedConfigurationId).ageGroupId;
        } else if(selectedAgeGroupIdDefault !== null) {
            ageGroupTempId = selectedAgeGroupIdDefault;
        } else if(ageGroups.allIds.length === 1) {
            ageGroupTempId = ageGroups.allIds[0];
        }

        return ageGroupTempId;
    };


    const getConfigurationById = id => configurations.find(configuration => configuration.id === id);

    const getLocalizedDate = (date) => {
        let newDate = UTCDateToLocalDate(new Date(date));
        let dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };

        if(_config.lang === 'de') {
            newDate = newDate.toLocaleDateString('de-DE', dateOptions);
        } else if(_config.lang === 'fr' || _config.lang === 'it') {
            newDate = newDate.toLocaleDateString('fr-FR', dateOptions);
        } else {
            newDate = newDate.toLocaleDateString('en-US', dateOptions);
        }
        return newDate;
    };

    const getDatePickerHeadline = () => {
        let text = "";

        if(datePickerDescription) {
            text = datePickerDescription;
        } else if(datePickerOptions['selectableDates'] === 1) {
            text = translate('ski-ticket.choose-start-date')
        } else {
            text = translate('ski-ticket.choose-dates')
        }

        return text;
    };

    const handleConfigurationDelete = (id) => {
        let newConfiguration = configurations.filter(function( obj ) {
            return obj.id !== id;
        });

        configurations.map((obj) => obj.id === id && obj.cartId ? deletedCartItems.push(obj.cartId) : null);

        setConfigurations(newConfiguration);

        console.log("DELETEDCARTITEMS",deletedCartItems);

        if (selectedConfigurationId) {
            setSelectedConfigurationId(null);
            setHasConfigurationError(null);
        }
    };

    useEffect(function() {
        if(noDatepicker) {
            getSkiTicketData(id);
        }
    }, [noDatepicker]);


    // Render
    if (isLoading) {
        return (
            <LoadingSpinner/>
        );
    }

    return (
         <Modal
             dialogClassName="modal-dialog modal-lg-shop"
             show={isModalOpen} onHide={onModalHide}>

             <button type="button" className="close text-right" onClick={onModalHide}>
                 <span aria-hidden="true" className="icon icon-close"></span>
             </button>

             {completedConfiguration === false ? (
                 <Fragment>
                     {currentStep === 1 ? (
                         <div className="ticket-configuration__currency-switch">
                             <CurrencySwitch currencies={currencies} selectedCurrencyId={selectedCurrencyId} onCurrencyChange={setSelectedCurrencyId}/>
                         </div>
                     ) : null}

                     { noDatepicker ? null : (
                         <StepNav max={maxStep} current={currentStep} onStepClick={(step) => {
                             setCurrentStep(step);
                             setHasConfigurationError(false);
                         }} hasPreviousError={hasConfigurationError}/>
                     )}

                     <div className="modal-content__inner">
                         {currentStep === 0 ? (

                             <div className="ticket-configuration">
                                 <div className="ticket-configuration__header">
                                     <div className="ticket-configuration__title text-primary">{name}</div>
                                     <h2 className="ticket-configuration__title">{ getDatePickerHeadline() }</h2>
                                 </div>

                                 <div className="my-3">
                                     {datePickerOptions['selectableDates'] === 1 ? (
                                         <Datepicker
                                             onChangeDate={setFromDate}
                                             date={fromDate}
                                             datePickerOptions={datePickerOptions} seasons={seasons} priceTendencies={priceTendencies}/>
                                         ) : (
                                         <DatepickerRange
                                             onChangeFromDate={setFromDate}
                                             onChangeToDate={setToDate}
                                             fromDate={fromDate}
                                             toDate={toDate}
                                             datePickerOptions={datePickerOptions} seasons={seasons} priceTendencies={priceTendencies}/>
                                        )
                                     }
                                 </div>

                                 <ol className="list-unstyled list-inline ticket-configuration__legend">
                                     {seasons.allIds.map((id) => (
                                         <li key={id} className="list-inline-item"> {/*whenever you map over a list you should use the key attribute with an id (or index if there is no id)*/}
                                             <span className={`ticket-configuration__legend-status ticket-configuration__legend-status--${seasons.byId[id].color}`}></span> {seasons.byId[id].name}
                                         </li>
                                     ))}
                                     {priceTendencies.allIds.map((id) => (
                                         <li key={id} className="list-inline-item">
                                             <span className={`icon icon-arrow-${priceTendencies.byId[id].arrow}`}></span> {priceTendencies.byId[id].name}
                                         </li>
                                     ))}
                                 </ol>
                             </div>
                         ) : (

                             <div className="ticket-configuration">
                                 <div className="ticket-configuration__header">
                                     <div className={`ticket-configuration__title  ${hasConfigurationError ? 'text-default-headline' : 'text-primary'}`}>{name}</div>
                                     <h2 className="ticket-configuration__title">{translate('ski-ticket.configure')}</h2>
                                     {datePickerOptions['selectableDates'] === 1 ? (
                                         <div className="ticket-configuration__subtitle">{getLocalizedDate(fromDate)}</div>
                                     ) : (
                                         <div className="ticket-configuration__subtitle">
                                             {duration ? (
                                                 duration + " " + translate('ski-ticket.from') + " "
                                             ): "" }
                                             {getLocalizedDate(fromDate)} - {getLocalizedDate(toDate)}</div>
                                     )}
                                 </div>

                                 {hasConfigurationError === true ? (
                                        <div className="ticket-configuration__box text-center">
                                            <div className="text-secondary p-4">
                                                <span className="icon icon-warning mr-2"></span>
                                                {translate('ticket.no-configuration-for-date')}
                                            </div>
                                        </div>
                                     ) : (
                                         <Fragment>
                                             {!loadingData ? (
                                                 <Fragment>
                                                     <SkiTicketConfigurationForm
                                                         key={selectedConfigurationId} // bind the form to the selectedConfigurationId, forces rerender of the element if changed
                                                         ageGroups={ageGroups}
                                                         prices={prices}
                                                         currencies={currencies}
                                                         persons={persons}
                                                         name={name}
                                                         insurances={insurances}
                                                         upgrades={upgrades}
                                                         specialTickets={specialTickets}
                                                         selectedCurrencyId={selectedCurrencyId}
                                                         isInEdit={!!selectedConfigurationId}
                                                         configurations={configurations}
                                                         standardTicket={standardTicket}
                                                         selectedPersonId={selectedConfigurationId
                                                             ? getConfigurationById(selectedConfigurationId).personId
                                                             : null}
                                                         selectedAgeGroupId={getSelectedAgeGroupId()}
                                                         selectedInsuranceId={selectedConfigurationId
                                                             ? getConfigurationById(selectedConfigurationId).insuranceId
                                                             : null}
                                                         selectedUpgradeIds={selectedConfigurationId
                                                             ? getConfigurationById(selectedConfigurationId).upgradeIds
                                                             : []}
                                                         selectedSpecialTicketId={selectedConfigurationId
                                                             ? getConfigurationById(selectedConfigurationId).specialTicketId
                                                             : null}
                                                         onSubmit={handleSubmit}
                                                         submitText={selectedConfigurationId
                                                             ? translate('ticket.update-configuration-cta')
                                                             : translate('ticket.add-configuration-cta')
                                                         }
                                                     />

                                                     <SkiTicketConfigurationList configurations={configurations}
                                                                                 selectedConfigurationId={selectedConfigurationId}
                                                                                 insurances={insurances}
                                                                                 upgrades={upgrades}
                                                                                 persons={persons}
                                                                                 specialTickets={specialTickets}
                                                                                 ageGroups={ageGroups}
                                                                                 selectedCurrencyId={selectedCurrencyId}
                                                                                 currencies={currencies}
                                                                                 prices={prices}
                                                                                 onEditClick={setSelectedConfigurationId}
                                                                                 onDeleteClick={handleConfigurationDelete}/>
                                                 </Fragment>
                                                 ) : (
                                                     <div className="ticket-configuration__box text-center p-5">
                                                         <LoadingSpinner/>
                                                     </div>
                                                 )}
                                         </Fragment>
                                 )}
                             </div>
                         )}
                     </div>

                     {hasConfigurationError === true ? (
                         <div className="text-center mt-4">
                             <button className="btn btn-secondary ticket-configuration__bottom-btn"
                                 onClick={() => {
                                 setCurrentStep(currentStep - 1);
                                 setHasConfigurationError(false);
                             }}>
                                 {translate('ticket.choose-new-date')}
                             </button>
                         </div>
                         ) : (
                         <div className="text-center mt-4">
                             {currentStep + 1 < maxStep ? (
                                 <button className="btn btn-secondary ticket-configuration__bottom-btn"
                                         disabled={((!toDate || !fromDate) && datePickerOptions['selectableDates'] !== 1) || (!fromDate && datePickerOptions['selectableDates'] === 1)}
                                         onClick={() => {
                                             setCurrentStep(currentStep + 1);
                                             getSkiTicketData(id);
                                         }}>
                                     {translate('ticket.continue')}
                                 </button>
                             ) : (
                                 <button className="btn btn-secondary ticket-configuration__bottom-btn"
                                         disabled={!configurations || configurations.length === 0}
                                         onClick={() => {
                                             setCurrentStep(currentStep + 1);
                                             addConfigurationsToCart();
                                         }}>
                                     {translate('ticket.add-to-cart')}
                                 </button>
                             )}
                         </div>
                         )}
                 </Fragment>
             ) : (
                 <div className="ticket-configuration__completed">
                 {!loadingData ? (
                        <Fragment>
                            <span className="icon icon-complete ticket-configuration__completed__icon"/>

                            <div className="h6 text-uppercase">
                                {addedToCartCount + " "}
                                {+addedToCartCount === 1 ? (
                                    translate('ticket.ticket-added-to-cart')
                                ) : (
                                    translate('ticket.tickets-added-to-cart')
                                )}
                            </div>

                            <div className="row mt-5">
                                <div className="col-md-4">
                                    <a className="btn bg-transparent text-primary btn-block text-center px-3"
                                       href={_config.ticketOverviewLink}>
                                        {translate('ticket.continue-shopping')}
                                    </a>
                                </div>

                                <div className="col-md-4">
                                    <a className="btn btn-grey btn-block"
                                       href={_config.cartLink}>
                                        {translate('ticket.cart')}
                                    </a>
                                </div>

                                <div className="col-md-4 mt-2 mt-md-0">
                                    <a className="btn btn-secondary btn-block"
                                       href={_config.checkoutLink}>
                                        {translate('ticket.checkout')}
                                    </a>
                                </div>
                            </div>
                        </Fragment>
                     ) : (
                        <Fragment>
                            <LoadingSpinner/>
                        </Fragment>
                     )}
                 </div>
             )}
        </Modal>
    );
}

export function getInitialSkiTicketData(id) {
    const _config = window._config || {};

    if (!_config.skiTicketInitialDataUrl) {
        return Promise.reject(`"_config.skiTicketInitialDataUrl" is not set`);
    }

    const url = addParamsToUrl(_config.skiTicketInitialDataUrl, {id: id});

    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(result => result.json()).then(result => {
        if (result.success === false) {
            return Promise.reject(result);
        } else {
            return Promise.resolve(result);
        }
    }).catch(e => {
        console.error(`Could not load base configuration data for id "${id}"`, e);
        return Promise.reject();
    });
}


export function initInScope($scope) {

    $scope.find('.js-ski-ticket-config').each(function () {
        let $SkiTicketConfigurator = $(this);
        let $SkiTicketConfiguratorModal = $SkiTicketConfigurator.find('.js-ski-ticket-config__modal');
        let $SkiTicketConfiguratorOpen = $SkiTicketConfigurator.find('.js-ski-ticket-config__open');
        let id = $(this).data('ticket-id');
        let reloadOnClose = $SkiTicketConfiguratorModal.data('reload-on-close') || false;

        const renderWithData = data =>  render(<SkiTicketConfig {...data}/>, $SkiTicketConfiguratorModal[0]);

        let params = {
            isModalOpen: true,
            onModalHide: (function() {
                params.isModalOpen = false;
                renderWithData(params);
                if(reloadOnClose) {
                    location.reload();
                }
            }),
            isLoading: true,
            id: id
        };

        $SkiTicketConfiguratorOpen.each(function() {
            let item = this;
            item.addEventListener('click', function() {
                let $this = $(this);
                params.isModalOpen = true;
                let selectedConfiguration = getPrefixedDataSet('ticket-config', $(this));
                let dataset = {
                    ...getPrefixedDataSet('ticket-config', $SkiTicketConfigurator),
                    ...selectedConfiguration
                };
                
                if (dataset) {
                    // key is used to force rerender to use default values
                    params = {
                        ...params,
                        key: Math.random(),
                        ...dataset
                    };
                }
                

                renderWithData(params);
            }, {capture: true});

            item.addEventListener('click', function(event) {
                getInitialSkiTicketData(id).then(result => {
                    params = {
                        ...params,
                        ...result.data,
                        isLoading: false
                    };
                    renderWithData(params);
                });

                event.target.removeEventListener('click', event, false);

            }, {capture: true});
        });
    });
}
