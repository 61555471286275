/** @jsx h */

import {h, render, Fragment} from 'preact';
import {useState, useEffect} from 'preact/hooks';
import CurrencySwitch from "./components/CurrencySwitch";
import LoadingSpinner from "./components/LoadingSpinner";
import {translate} from '@elements/translations';
import fetch from '@elements/fetch';
import {Modal} from 'react-bootstrap';
import {noop} from "./utils.js";
import {addParamsToUrl} from "./utils";
import {getPrefixedDataSet} from "./data-set-utils";
import EventTicketListConfigurationForm
    from "./components/EventTicketListConfiguration/EventTicketListConfigurationForm";
import {animateNotification, updateNotificationUI} from "./shoppingList";
import {responseTracking} from "@elements/tracking";

function EventTicketListConfig({
    isLoading,
    id,
    cartId = 0,
    title = "",
    configurationsDefault = [],
    ageGroups = [],
    currencies = [],
    ticketList = [],
    onModalHide = noop,
    prices,
    ticketCounterDefault = {},
    availability = null,
    selectedConfigurationIdDefault = null,
    isModalOpen = true
    }) {

    // State
    const [loadingData, setLoadingData] = useState(false);
    const [completedConfiguration, setCompletedConfiguration] = useState(false);
    const [configurations, setConfigurations] = useState(configurationsDefault);
    const [selectedConfigurationId, setSelectedConfigurationId] = useState(selectedConfigurationIdDefault); // determines which configuration is currently edited in the form
    const [selectedCurrencyId, setSelectedCurrencyId] = useState("chf");
    const [addedToCartCount, setAddedToCartCount] = useState(0);
    const [ticketCounterSum, setTicketCounterSum] = useState(0);

    const addConfigurationsToCart = () => {
        const _config = window._config || {};

        if (!_config.addToCartUrl) {
            console.warn(`"_config.addToCartUrl" is not set`);
        }

        setLoadingData(true);

        setConfigurations(configurations.map(function (configuration) {
            return configuration.id === selectedConfigurationId
                ? {...configuration, cartId: configuration.id }
                : configuration
        }));
        
        console.log("CONFIGS",configurations); 

        let data = {
            id: id,
            cartId: cartId,
            type: 'event-list-ticket',
            configurations: configurations.map(({id, ...rest}) => rest)
        };

        let request = fetch(_config.addToCartUrl, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        responseTracking(request);

        request.then(result => result.json()).then(result => {
            if (result.success === true) {
                setCompletedConfiguration(true);
                setAddedToCartCount(result.addedCounter);
                animateNotification();
                updateNotificationUI(result.cartCounter);
                setLoadingData(false);
            } else {
                setLoadingData(false);
            }
        }).catch(e => {
            console.error(`Could not add ticket to cart for id "${id}"`, e);
            setLoadingData(false);
        });
    };

    const handleChange = newConfiguration => {

        if(configurations.length > 0) {

            function existingConfig(configuration) {
                return configuration.ticketId === newConfiguration.ticketId && configuration.ageGroupId === newConfiguration.ageGroupId;
            }

            let existingConfigurations = configurations.filter(existingConfig);

            if(existingConfigurations.length > 0) {
                setConfigurations(configurations.map(function (configuration) {
                    return configuration === existingConfigurations[0]
                        ? {...configuration, ...newConfiguration}
                        : configuration
                }));
            } else {
                setConfigurations([
                    ...configurations,
                    {
                        ...newConfiguration
                    }
                ]);
            }
        } else {
            setConfigurations([
                ...configurations,
                {
                    ...newConfiguration
                }
            ]);
        }


        console.log("configurations", configurations, configurations.length); 

        console.log(JSON.stringify(configurations));
    };

    const getConfigurationCounterSum = () => {
        let sum = 0;
        configurations.map((configuration) => {
            sum += configuration.ticketCounter;
        });
        console.log("SUM", sum);
        return sum;
    };
    
    // Render
    if (isLoading) {
        return (
            <LoadingSpinner/>
        );
    }

    return (
        <Modal
            dialogClassName="modal-dialog modal-lg-shop"
            show={isModalOpen} onHide={onModalHide}>

            <button type="button" className="close text-right" onClick={onModalHide}>
                <span aria-hidden="true" className="icon icon-close"></span>
            </button>

            {completedConfiguration === false ? (
                <Fragment>

                    <div className="ticket-configuration__currency-switch">
                        <CurrencySwitch currencies={currencies} selectedCurrencyId={selectedCurrencyId}
                                        onCurrencyChange={setSelectedCurrencyId}/>
                    </div>

                    <div className="modal-content__inner">

                        <div className="ticket-configuration">
                            <div className="ticket-configuration__header">
                                <h2 className="ticket-configuration__title">{translate('event-ticket.configure')}</h2>
                            </div>

                            <EventTicketListConfigurationForm
                                key={selectedConfigurationId} // bind the form to the selectedConfigurationId, forces rerender of the element if changed
                                ageGroups={ageGroups}
                                ticketList={ticketList}
                                prices={prices}
                                availability={availability}
                                title={title}
                                ticketCounterDefault={ticketCounterDefault}
                                currencies={currencies}
                                setTicketCounterSum={setTicketCounterSum}
                                selectedCurrencyId={selectedCurrencyId}
                                selectedConfigurationId={selectedConfigurationId}
                                isInEdit={!!selectedConfigurationId}
                                configurations={configurationsDefault}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className="text-center mt-4">
                        <button className="btn btn-secondary ticket-configuration__bottom-btn"
                                disabled={getConfigurationCounterSum() === 0}
                                onClick={() => {
                                    addConfigurationsToCart();
                                }}>
                            {translate('ticket.add-to-cart')}
                        </button>
                    </div>

                    { loadingData ? (
                        <Fragment>
                            <LoadingSpinner/>
                        </Fragment>
                    ) : ""}
                </Fragment>
            ) : (
                <div className="ticket-configuration__completed">

                    <span className="icon icon-complete ticket-configuration__completed__icon"/>

                    <div className="h6 text-uppercase">
                        {addedToCartCount + " "}
                        {+addedToCartCount === 1 ? (
                            translate('event-ticket.ticket-added-to-cart')
                        ) : (
                            translate('event-ticket.tickets-added-to-cart')
                        )}
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-4">
                            <a className="btn bg-transparent text-primary btn-block text-center px-3"
                               href={_config.ticketOverviewLink}>
                                {translate('ticket.continue-shopping')}
                            </a>
                        </div>

                        <div className="col-md-4">
                            <a className="btn btn-grey btn-block"
                               href={_config.cartLink}>
                                {translate('ticket.cart')}
                            </a>
                        </div>

                        <div className="col-md-4 mt-2 mt-md-0">
                            <a className="btn btn-secondary btn-block"
                               href={_config.checkoutLink}>
                                {translate('ticket.checkout')}
                            </a>
                        </div>
                    </div>

                </div>
            )}


        </Modal>
    );
}

export function getInitialEventTicketListData(id) {
    const _config = window._config || {};

    if (!_config.eventTicketListInitialDataUrl) {
        return Promise.reject(`"_config.eventTicketListInitialDataUrl" is not set`);
    }

    const url = addParamsToUrl(_config.eventTicketListInitialDataUrl, {id: id});

    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(result => result.json()).then(result => {
        console.log(result);
        if (result.success === false) {
            return Promise.reject(result);
        } else {
            return Promise.resolve(result);
        }
    }).catch(e => {
        console.error(`Could not load base configuration data for id "${id}"`, e);
        return Promise.reject();
    });
}

export function initInScope($scope) {
    $scope.find('.js-event-ticket-list-config').each(function () {
        let $EventTicketConfigurator = $(this);
        let $EventTicketConfiguratorModal = $EventTicketConfigurator.find('.js-event-ticket-list-config__modal');
        let $EventTicketConfiguratorOpen = $EventTicketConfigurator.find('.js-event-ticket-list-config__open');
        let id = $(this).data('ticket-id');
        let cartId = $(this).data('ticket-cart-id');
        let reloadOnClose = $EventTicketConfiguratorModal.data('reload-on-close') || false;

        const renderWithData = data => render(<EventTicketListConfig {...data}/>, $EventTicketConfiguratorModal[0]);

        let params = {
            isModalOpen: true,
            onModalHide: (function () {
                params.isModalOpen = false;
                renderWithData(params);
                if(reloadOnClose) {
                    location.reload();
                }
            }),
            isLoading: true,
            id: id,
            cartId: cartId
        };

        $EventTicketConfiguratorOpen.each(function() {
            let item = this;
            item.addEventListener('click', function () {
                let $this = $(this);
                params.isModalOpen = true;
                let selectedConfiguration = getPrefixedDataSet('ticket-config', $(this));
                let dataset = {
                    ...getPrefixedDataSet('ticket-config', $EventTicketConfigurator),
                    ...selectedConfiguration
                };


                if (dataset) {
                    // key is used to force rerender to use default values
                    params = {
                        ...params,
                        key: Math.random(),
                        ...dataset
                    };
                }

                getInitialEventTicketListData(id).then(result => {
                    params = {
                        ...result.data,
                        ...params,
                        isLoading: false
                    };
                    console.log("params one", params);

                    renderWithData(params);
                });
            }, {capture: true});
        });


    });
}
