/** @jsx h */

import { h, render, Fragment} from 'preact';
import { useState } from 'preact/hooks';
import Select from 'react-select';

export function initInScope($scope) {
    $scope.find('.js-react-select').each(function () {
        let element = this;
        let optionsString = element.dataset.reactSelectOptions;
        let options;
        try {
            options = JSON.parse(element.dataset.reactSelectOptions);
        } catch (e) {
            console.error('Invalid data-react-select-options. Expected valid JSON, got', '\n', optionsString, '\n', e);
        }


        render(<CustomSelect options={options} element={element} />, this)
    });
}


function CustomSelect({options, element}) {
    let [selectedOption, setSelectedOption] = useState(options[0]);

    return (
        <Fragment>
            <Select className='form-control--react-select'
                    classNamePrefix="form-control--react-select"
                    options={options}
                    required
                    filterOption={filterOptions}
                    value={selectedOption}
                    onChange={(value) => {
                        setSelectedOption(value);
                    }}
            />
            <input type="hidden"
                   className='js-shopping-list__additional-input'
                   name={element.getAttribute('name')}
                   value={selectedOption.value}/>
        </Fragment>
    )
}

const filterOptions = ({label}, query) => label.indexOf(query) >= 0;
