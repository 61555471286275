"use strict";

import {scrollTo} from "./scrollTo";
import {isValidKeycard} from "./keycard";

export function initInScope($scope) {

    import('qr-scanner').then((QrScanner) => {
        QrScanner = QrScanner.default;
        QrScanner.WORKER_PATH = '/static/js/libs/qr-scanner-worker.min.js';

        try {
            QrScanner.hasCamera().then(function(deviceHasCamera){

                if(deviceHasCamera) {
                    let $button = $scope.find('.js-qr-scanner__btn');
                    $button.each(function() {
                        let scanner;
                        try {
                            $button.attr('hidden', false);
                            $button.on('click', function() {
                                let $qrScanner = $(this).closest('.js-qr-scanner');
                                let video = $qrScanner[0].getElementsByClassName('js-qr-scanner__video')[0];
                                let $videoQrResultInput = $qrScanner.find('.js-qr-scanner__result-input');
                                let $videoQrResult = $qrScanner.find('.js-qr-scanner__result');
                                let $preview = $qrScanner.find('.js-qr-scanner__preview');
                                scanner = new QrScanner(video, result => setQrScanResult($videoQrResult, $videoQrResultInput, $preview, result, scanner));
                                video.play();
                                scanner.start();
                                $preview.attr('hidden', false);
                                setTimeout(function() {
                                    scrollTo($preview);
                                }, 200);
                            });
                        } catch(error) {
                            $button.attr('hidden', true);
                        }
                    });
                } else {
                    $scope.find('.js-qr-scanner__btn').attr('hidden', true);
                    console.warn('QR-Scanner: No Camera detected. QR Scanner will not be available for keycard scanning.');
                }
            });
        } catch {
            console.warn("something went wrong....");
        }
    });

    function setQrScanResult(resultContainer, resultInput, preview, result, scanner) {
        let newKeycardNumber = result.split('?rfid=').pop();

        if(isValidKeycard(newKeycardNumber)) {
            resultContainer.html(newKeycardNumber).addClass('has-success');
            setTimeout(function() {
                preview.attr('hidden', true);
                resultInput.val('');
                resultInput.attr('maxlength','29');
                resultInput.removeAttr('data-parsley-length');
                resultInput.val(newKeycardNumber);
                scanner.stop();
                scanner = null;
                resultContainer.removeClass('has-success').html('');
            }, 400);

        } else {
            resultContainer.addClass('has-error').html(resultContainer.attr('data-qr-scanner-result-error'));
            preview.addClass('has-error');
            scanner.stop();

            setTimeout(function() {
                resultContainer.removeClass('has-error');
                preview.removeClass('has-error');
                resultContainer.html('');
                scanner.start();
            }, 1400);
        }
    }
}
