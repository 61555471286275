"use strict";

import initModulesInScope from "./initModulesInScope";

export function init() {
    let $container = $('.js-checkout-form__container');
    let $form = $container.find('.js-checkout-form');
    let $resultList = $container.find('.js-checkout-form__result-list');
    let $resultCart = $('.js-checkout-form__result-cart');
    let $resultPaymentMethods = $container.find('.js-checkout-form__result-payment-methods');
    let $loading = $('.js-checkout-form__loading');
    let $triggerSubmitInput = $('.js-checkout-form__input');
    let pendingRequest = null;

    deleteVoucher();

    $('.js-checkout-form__submit').unbind().on('click', function (evt) {
        evt.preventDefault();
        evt.stopImmediatePropagation();
        loadData(evt, 'submit');
    });

    $triggerSubmitInput.on('change', function(evt) {
        evt.preventDefault();
        evt.stopImmediatePropagation();

        loadData(evt, 'change', $(this));
    });

    function deleteVoucher() {
        document.querySelectorAll('.js-voucher-form__delete').forEach(item => item.addEventListener('click', function(evt) {
            evt.preventDefault();
            evt.stopImmediatePropagation();
            let voucherId = $(item).closest('.js-voucher-form__item').attr('data-voucher-id') || console.warn("data-checkout-id not defined");
            loadData(evt, 'submit', null, true, voucherId);
        }, {capture: true}));
    }

    function loadData(evt, trigger, $inputToSerialize, deleteAction, voucherId) {

        if (pendingRequest) {
            pendingRequest.abort();
            pendingRequest = null;
        }


        $resultList.attr('hidden', null);
        $resultCart.attr('hidden', null);
        $loading.removeAttr('hidden');
        let data;


        let actionList = $form.attr('data-ajax-url-list');
        let actionCart = $form.attr('data-ajax-url-cart');
        let actionPaymentMethods = $form.attr('data-ajax-url-payment-methods');


        if(deleteAction) {
            data = {
                voucher: voucherId,
                action: 'delete'
            }
        } else {
            if(trigger == 'change') {
                data = $inputToSerialize.serialize();
            } else {
                data = $form.find('input').serializeArray();
            }
        }

        $.when(pendingRequest = $.ajax(actionList, {
                data: data,
                dataType: 'html'
            }
        )).then(function (resp1) {
            $resultList.empty().append(resp1);
            initModulesInScope($resultList);

            return  pendingRequest = $.ajax(
                actionCart, {
                    data: data,
                    dataType: 'html',
                }
            );
        }).then(function (resp2) {

            $resultCart.empty().append(resp2);
            initModulesInScope($resultCart);
            return  pendingRequest = $.ajax(
                actionPaymentMethods, {
                    data: data,
                    dataType: 'html',
                }
            );
        }).then(function (resp3) {

            $resultPaymentMethods.empty().append(resp3);
            initModulesInScope($resultPaymentMethods);
        }).always(function() {
            $loading.attr('hidden', 'hidden');
            initModulesInScope($container);
            pendingRequest = null;

            deleteVoucher();
            
        });

    }

}