"use strict";

import initModulesInScope from "./initModulesInScope";
import {isParsleyForm, isValid} from "./parsley";

export function initInScope ($scope) {
    let $forms = $('.js-step-form');

    $forms.each(function () {
        let $form = $(this);
        let $steps = $form.find('.js-step-form__step');
        let $ajaxSteps = $form.find('.js-step-form__step--ajax').not('.is-disabled');
        let $inputs = $ajaxSteps.find('input');
        let $submitButton = $form.find('.js-step-form__submit');
        let pendingRequest = null;

        $form.on('submit', function(evt) {
            $(this).find('.js-step-form--trigger-submit').addClass('has-submit-trigger');
        });

        $form.find('.js-step-form--trigger-submit.has-submit-trigger').on('change', function(evt) {
            $form.trigger('submit');
        });

        $inputs.on('change', function(evt) {
            evt.preventDefault();
            evt.stopImmediatePropagation();

            if (pendingRequest) {
                pendingRequest.abort();
                pendingRequest = null;
            }
            if (isParsleyForm($form) && !isValid($form)) {
                return;
            }

            let $step = $(this).closest('.js-step-form__step');
            let $self = $(this);
            let currentStep = $step.attr('data-step');


            $steps.each(function() {
                let $self = $(this);
                let number = $(this).data('step');
                $submitButton.attr('disabled', true);
                if(currentStep === '1') {
                    $('.js-filter-form__result').html('');
                }
                if(number > currentStep) {
                    $self.find('input').prop('checked', null);
                    $self.addClass('is-disabled');
                }
            });

            let nextStep = +currentStep + 1;
            let $nextStep = $form.find(`.js-step-form__step[data-step="${nextStep}"]`);
            let $nextStepResult = $nextStep.find('.js-step-form__result');
            let $loading = $nextStep.find('.js-step-form__loading');

            $nextStepResult.attr('hidden', null);
            $loading.attr('hidden', null);

            let formData = $form.serializeArray();
            let type = $form.attr('method') || 'POST';

            let action = $form.data('step-form-action') + `&nextStepNumber=${nextStep}`;

            pendingRequest = $.ajax(action, {
                data: formData,
                dataType: 'html',
                method: type 
            }).done(function (result) {
                $nextStepResult.empty().append(result);
                $nextStep.removeClass('is-disabled');
                initModulesInScope($form);
                if($self.closest('.js-step-form__step--toggle-last').length > 0) {
                    let $lastStep = $form.find('.js-step-form__step--last');
                    $lastStep.removeClass('is-disabled');
                    $submitButton.attr('disabled', false);
                }
                if($self.hasClass('js-step-form--trigger-submit') && $self.hasClass('has-submit-trigger')) {
                    $form.trigger('submit');
                }
            }).fail(function (jqXHR, textStatus, errorThrown) {
                if (textStatus !== 'abort') {
                    console.error(textStatus, errorThrown, jqXHR);
                }
            }).always(function () {
                $loading.attr('hidden', 'hidden');
                pendingRequest = null;
            })
        });


    });
}
