/** @jsx h */

import {h} from "preact";
import {noop} from "../utils";
import {useState} from "preact/hooks";
import {translate} from '@elements/translations';

/**
 * @return {string}
 */

export default function CurrencySwitch({
        currencies = [],
        selectedCurrencyId,
        onCurrencyChange = noop
    }) {

    //render
    return (
        <div className="form-group">
            <label className="control-label fz10 ticket-configuration__currency-switch__label" htmlFor="configuration-age-group">
                {translate('ticket.Währung-einstellen')}
            </label>

            <div className="select__wrapper ticket-configuration__currency-switch__select-wrapper">
                <select className="form-control select"
                        id="configuration-currency-switch"
                        value={selectedCurrencyId}
                        onChange={evt => {
                            onCurrencyChange(evt.target.value);
                        }}>
                    {currencies.allIds.map((currency) => (
                        <option key={currency} id={currency} value={currency}>{currencies.byId[currency].name}</option>
                    ))}
                </select>

                <span className="icon icon-arrow-bold select-icon" aria-hidden="true"/>
            </div>
        </div>
    );
}