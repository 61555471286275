"use strict";

export function initInScope ($scope) {
    let $darkpageOverlay = $scope.find('.js-darkpage-overlay--webcam');

    try {
        let data = sessionStorage.getItem('darkpageOverlayWebcam');
        if (data != 'alreadyShown') {
            $darkpageOverlay.removeAttr('hidden');
            $('body').addClass('overflow-hidden');
        }
    } catch(err) {}

    $('.js-darkpage-overlay--webcam__close, .js-darkpage-overlay--webcam__link').on('click', function() {
        try {
            sessionStorage.setItem('darkpageOverlayWebcam', 'alreadyShown');
            $darkpageOverlay.attr('hidden', '');
            $('body').removeClass('overflow-hidden');

        } catch(err) {}
    });
}

