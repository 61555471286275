"use strict";

import initModulesInScope from "./initModulesInScope";

let isRunning = false;
let $navbarAffix;
let lastScrollPosition = 0;
let isNavOverlayOpen = false;
let isNavLevelOpen = false;

export function init() {
    let $mainNavOverlay = $('.js-navbar__main-nav-overlay');
    let $mainNav = $('.js-navbar__nav');
    let $cartOverlay = $('.js-cart-overlay');
    let $userOverlay = $('.js-user-overlay');

    $mainNavOverlay.on('overlay.show', function () {
        renderOverlayTemplate();
        $mainNav.addClass('is-nav-white is-main-nav-open');
        isNavOverlayOpen = true;
        setAffix();
    }).on('overlay.hide', function () {
        $mainNav.removeClass('is-nav-white is-main-nav-open');
        isNavOverlayOpen = false;
        updateDom();
    });

    $cartOverlay.on('overlay.show', function () {
        setAffix();
    }).on('overlay.hide', function () {
        updateDom();
    });

    $userOverlay.on('overlay.show', function () {
        renderOverlayTemplate();
        setAffix();
    }).on('overlay.hide', function () {
        updateDom();
    });
}

export function initInScope($scope) {
    $navbarAffix = $scope.find('.js-navbar-affix');
    let $navLevelToggler = $scope.find('.js-toggle-nav-level');

    document.body.addEventListener('click', function (evt) {
        if (isNavLevelOpen) {
            let $target = $(evt.target);
            let $parent = $target.closest('.js-toggle-nav-level, .js-nav-item__dropdown');

            if (!$target.is('.js-toggle-nav-level, .js-nav-item__dropdown') && !$parent.length) {
                closeDropdown($navLevelToggler);
            }
        }
    });

    $navLevelToggler.on('click', function (evt) {
        evt.preventDefault();
        renderOverlayTemplate();
        toggleDropdown($(this));
    });

    if ($navbarAffix) {
        navbarToggleAffix($navbarAffix);
    }
}

function toggleDropdown($dropdownToggler) {
    let isMobileDropdown = $dropdownToggler.is('.js-toggle-nav-overlay-dropdown--mobile');

    if (matchMedia('(min-width: 768px)').matches && !isMobileDropdown ) {
        let navItem = $dropdownToggler.closest('li');

        if (!navItem.hasClass('is-open')) {
            if(isMobileDropdown) {
                navItem.find('.icon').removeClass('icon-search').addClass('icon-close');
            }
            navItem.closest('.navbar').find('li').removeClass('is-open');
            navItem.addClass('is-open');
            isNavLevelOpen = true;
            $dropdownToggler.attr('aria-expanded', 'true');

            if (navItem.hasClass('circle-animation')) {
                circleAnimation(navItem);
            }

        } else {
            closeDropdown($dropdownToggler);
        }
    }
    if(isMobileDropdown) {
        if (!$dropdownToggler.hasClass('is-open')) {
            $dropdownToggler.find('.icon').removeClass('icon-search').addClass('icon-close');
            $dropdownToggler.addClass('is-open').attr('aria-expanded', 'true');
            isNavLevelOpen = true;
            let $targetOverlay = $($dropdownToggler.attr('data-target'));
            $targetOverlay.addClass('is-open');
        } else {
            closeDropdown($dropdownToggler);
        }
    }
}

function closeDropdown($dropdownToggler) {
    let isMobileDropdown = $dropdownToggler.is('.js-toggle-nav-overlay-dropdown--mobile');
    let navItem;
    if(!isMobileDropdown) {
        navItem = $dropdownToggler.closest('li');
    } else {
        navItem = $dropdownToggler;
        let $targetOverlay = $($dropdownToggler.attr('data-target'));
        $targetOverlay.removeClass('is-open');
    }

    $dropdownToggler.closest('.is-open').removeClass('is-open');
    isNavLevelOpen = false;
    $dropdownToggler.attr('aria-expanded', 'false');
    if(isMobileDropdown) {
        $('body').removeClass('overflow-hidden');
        navItem.find('.icon').removeClass('icon-close').addClass('icon-search');
    }
}


function circleAnimation(navItem) {
    setTimeout(function () {
        let svg = navItem.find('.js-circle-percent');
        svg.each(function () {
            let val = $(this).data('percent'),
                $circle = $(this).children('.bar');

            let c = Math.PI * ($circle.attr('r') * 2);
            let percent = ((100 - val) / 100) * c;
            $circle.css({strokeDashoffset: percent});
        })
    }, 400);
}

function navbarToggleAffix($navbarAffix) {
    if (!isRunning) {
        isRunning = true;
        requestAnimationFrame(interval);
    }
}


function interval() {
    if (lastScrollPosition === window.scrollY || isNavOverlayOpen) {
        requestAnimationFrame(interval);
        return;
    }

    lastScrollPosition = window.scrollY;
    updateDom();
    requestAnimationFrame(interval);
}

function updateDom() {
    let offset = $navbarAffix.attr('data-affix-offset');

    $navbarAffix.isAffix = offset <= window.scrollY;

    if ($navbarAffix.isAffix) {
        setAffix();
    } else {
        unsetAffix();
    }
}

function setAffix() {
    $navbarAffix.addClass('is-affix');
    if ($navbarAffix.hasClass('navbar-shadow')) {
        $navbarAffix.addClass('navbar-white');
    }
}

function unsetAffix() {
    $navbarAffix.removeClass('is-affix');
    if ($navbarAffix.hasClass('navbar-shadow')) {
        $navbarAffix.removeClass('navbar-white');
    }
}

function renderOverlayTemplate() {
    let $renderNavbarContainer = $('.js-render-overlay-template');

    if($renderNavbarContainer.length){

        $renderNavbarContainer.each(function (index, element) {
            let $template = $(this).find('.js-render-overlay-template__template');
            let templateHtml = $template.html();

            $(this).html(templateHtml).removeClass('js-render-overlay-template');

            $template.remove();
            initModulesInScope($(this));
        });

    }
}