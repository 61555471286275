"use strict";
import loadScript from '@elements/load-script';
import {isParsleyForm, isValid} from "./parsley";

export function initInScope($scope) {
    let $paymentForm = $scope.find('.js-payment-form');

    $paymentForm.each(function() {
        let $container = $(this);
        let $loading = $container.find('.js-payment-form__loading');
        let $error = $container.find('.js-payment-form__error');
        let $form = $container.find('.js-payment-form__form');
        let pendingRequest = null;

        $form.on('submit', function(evt) {
            let $form = $(this);
            let $submit = $form.find('.js-payment-form__submit');
            let action = $form.data('action') || $form.attr('action');

            evt.preventDefault();
            evt.stopImmediatePropagation();

            if (isParsleyForm($form) && !isValid($form)) {
                return;
            }

            $loading.attr('hidden', null);
            
            let data = {};
            $.each($form.serializeArray(), function (index, obj) {
                data[obj.name] = obj.value;
            });

            pendingRequest = $.ajax(action, {
                data: data,
                method: 'POST',
            }).done(function (result) {
                if ('undefined' !== typeof result.action && result.action === 'redirect') {
                    if ('undefined' !== typeof result.location) {
                        window.location.href = result.location;
                    //
                        return true;
                    }
                }
                else if ('undefined' !== typeof result.action && result.action === 'payment') {
                    let paymentConfig = result.payment.config;
                    let priceDatatrans = +result.payment.params.amount;
                    let priceLastShown = +_config.totalToPay;

                    if (priceDatatrans !== priceLastShown){
                        // price is changed - redirect to payment page again
                        console.error('[Payment]', 'paymentMismatch between ' + priceLastShown+ ' and ' + priceDatatrans);
                        window.location.href = result.paymentMismatchUrl;
                    } else {
                        loadScript(paymentConfig.script).then(() => {
                            $loading.attr('hidden', true);
                            $submit.prop('disabled', true);

                            Datatrans.startPayment({
                                params: result.payment.params,
                                'opened': function () {
                                    $loading.attr('hidden', null);
                                },
                                'closed': function () {
                                    $loading.attr('hidden', 'hidden');
                                }
                            });
                        }).catch((error) => {
                            $submit.prop('disabled', false);
                            console.error(error);
                        });
                    }
                }
            }).fail(function (jqXHR, textStatus, errorThrown) {
                if (textStatus !== 'abort') {
                    $error.attr('hidden', null);
                }
            }).always(function () {
                $loading.attr('hidden', 'hidden');
                pendingRequest = null;
                $submit.prop('disabled', false);
            })
        });
    });
}
