"use strict";

import {isParsleyForm, isValid} from "./parsley";

export function initInScope ($scope) {
    let $wrapper = $scope.find('.js-enable-on-form-validate__wrapper');

    $wrapper.each(function() {
        let $form = $(this).find('.js-enable-on-form-validate__form');
        let $button = $(this).find('.js-enable-on-form-validate__button');

        $form.on('change', function (evt) {
            let $form = $(this);
            toggleDisableFormButton($form, $button);
        });
        $button.on('click', function () {
            if($form.hasClass('disable-has-error')) {
                $form.removeClass('disable-has-error');
            }
        })

        function toggleDisableFormButton($form, $button) {
            if (isParsleyForm($form) && isValid($form)) {
                $button.removeClass('disabled');
            } else {
                $button.addClass('disabled');
            }
        }

    });
}
