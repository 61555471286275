
"use strict";

import initModules from "./initModulesInScope";

let pendingRequest;
export function initInScope($scope) {

    let $editButton = $scope.find('.js-person__edit');
    let $input = $scope.find('.js-person-form__input'),
        $keycardInput = $('.js-person__keycard');
    
    $input.on('change', function() {
        let $this = $(this),
            $wrapper = $this.closest('.js-person-container'),
            $resultContainer = $wrapper.find('.js-person-form__result'),
            $loading = $wrapper.find('.js-person-form__loading'),
            url =  $this.find(':selected').attr('data-action'),
            keycardValue = $this.find(':selected').attr('data-keycard-value');
        

        $resultContainer.addClass('is-loading');
        $loading.attr('hidden', null).removeClass('hidden');
        $wrapper.find('.js-ajax-form__error-container').attr('hidden', true).empty();

        if (pendingRequest) {
            pendingRequest.abort();
        }

        pendingRequest = $.ajax(url, {
            data: {
                pId: $this.data('person-id')
            },
            dataType: 'html',
            headers: {Ajax: 1}
        }).done(function (result) {
            let $result = $(result);
            $resultContainer.empty();
            $resultContainer.append($result);
            if (keycardValue) {
                $keycardInput.val(keycardValue);
                $keycardInput.attr('disabled', true);
            } else {
                $keycardInput.val('');
                $keycardInput.removeAttr('disabled');
            }
            initModules($result);
        }).fail(function () {
        }).always(function () {
            pendingRequest = null;
            $resultContainer.removeClass('is-loading');
            $loading.attr('hidden', 'hidden');
        });
    });

    $editButton.on('click', function() {
        let $editPerson = $(this).closest('.js-person');
        $editPerson.find('input, select').removeAttr('disabled');
        $editPerson.find('.js-person__edit-image').removeAttr('hidden');
        $keycardInput.removeAttr('disabled');
    });


}