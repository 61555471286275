"use strict";

let selectedId,
    isSelected = false,
    questionIndex = 0,
    jobId,
    questionCount = $('.js-jobfinder-question').length,
    questions = {
        answer : []
    };


export function initInScope($scope) {
    let $jobfinder = $scope.find('.js-jobfinder'),
        $question = $scope.find('.js-jobfinder-question'),
        $answer = $scope.find('.js-jobfinder-answer'),
        $goBack = $scope.find('.js-jobfinder__go-back'),
        $next = $scope.find('.js-jobfinder__next'),
        $resultBtn = $scope.find('.js-jobfinder__result-btn');

    updateUI($scope);
    $answer.on('click', function () {
        $(this).closest('.js-jobfinder-question').find('.js-jobfinder-answer').removeClass("selected");
        $(this).addClass('selected');
        storeAnswer($(this));
        updateButtons($goBack, $next, $question, $resultBtn);
    });

    $goBack.on('click', function () {
        questionIndex -= 1;
        updateUI($scope);
    });

    $next.on('click', function () {
        questionIndex += 1;
        if(questionIndex != questionCount) {
            updateUI($scope);
        }
    });
}


function showActiveQuestion($question) {
    $question.attr('hidden', true);
    $question.each(function (index) {
        if (index == questionIndex) {
            $(this).attr('hidden', false);
        }
    });
}

function updateProgressBar() {
    let $progressBar = $('.js-jobfinder__progress-bar'),
        $progressBarCircle = $('.js-jobfinder__progress-bar-circle'),
        $progressBarValue = $('.js-jobfinder__progress-bar-value');

    let currentProgress = parseInt( Math.round(100/questionCount)) * (questionIndex);
    $progressBarCircle.css({'margin-left': currentProgress + "%"});
    $progressBarValue.html(currentProgress + "%");
    $progressBar.css({'width': currentProgress + "%"});
}

function updateUI($scope) {
    let $question = $scope.find('.js-jobfinder-question'),
        $goBack = $scope.find('.js-jobfinder__go-back'),
        $next = $scope.find('.js-jobfinder__next'),
        $resultBtn = $scope.find('.js-jobfinder__result-btn');

    showActiveQuestion($question);
    updateProgressBar();
    updateButtons($goBack, $next, $question, $resultBtn);
}

function storeAnswer($answer) {
    selectedId = $answer.data('typ'); 
    jobId = $answer.attr('data-jobid').split(',');
    questions.answer[ selectedId ] = jobId;
}

function updateButtons($goBack, $next, $question, $resultBtn) {
    isSelected = $($question[questionIndex]).find('.selected').length;

    if (questionCount-1 == questionIndex) {
        $resultBtn.attr('hidden', false);
        $next.attr('hidden', true);
        if (isSelected == 0) {
            $resultBtn.attr('disabled', true).addClass('disabled');
        } else {
            $resultBtn.attr('disabled', false).removeClass('disabled');
            let url = $resultBtn.attr('href');
            if (url.indexOf("?ergId") > 0) {
                url = url.substring(0, url.indexOf("?ergId"));
            }
            url = url + '?ergId=' + questions.answer;
            $resultBtn.attr('href', url);
        }
    } else {
        $resultBtn.attr('hidden', true);
        $next.attr('hidden', false);
    }

    if (questionIndex == 0) {
        $goBack.attr('hidden', true);
    } else {
        $goBack.attr('hidden', false);
    }


    if (isSelected == 0) {
        $next.attr('disabled', true);
    } else {
        $next.attr('disabled', false);
    }
}