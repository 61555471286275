import {configureStore} from "@reduxjs/toolkit";
import {combineReducers} from "redux";
import eventsReducer from "./features/events/eventsReducer";

const rootReducer = combineReducers({
    test: () => 'test',
    events: eventsReducer
});

const store = configureStore({
    reducer: rootReducer,
});

export function getStore() {
    return store;
}