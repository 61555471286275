"use strict";

import initModulesInScope from "./initModulesInScope";

export function initInScope($scope) {
    let $wrappers = $scope.find('.js-add-template__wrapper');

    $wrappers.each(function () {
        let $wrapper = $(this);
        let $container = $wrapper.find('.js-add-template__template-container');
        let $lastId = $wrapper.find('.js-add-template__item').last().find('.js-add-template__item__id').attr("id");

        let $newId = $lastId ? (parseInt($lastId) + 1) : 1;
        let $addButton = $wrapper.find('.js-add-template__item--add');
        let $deleteButtons = $('.js-add-template__item--delete');
        $wrapper.find('.js-add-template__item__form-group__input').attr('disabled', true);

        $addButton.on('click', function (evt) {
            evt.preventDefault();
            let $template = $($wrapper.find('.js-add-template__template').html());

            let $templateNew = $template.clone();

            $templateNew.find('.js-add-template__item__id').attr("id", $newId);
            $templateNew.find('.js-add-template__item--delete').on('click', deleteItem);
            $newId++;

            $templateNew.find('.js-add-template__item__form-group').each(function (index) {
                let $itemInput = $(this).find('.js-add-template__item__form-group__input');
                let $itemLabel = $(this).find('.js-add-template__item__form-group__label');
                let itemName = $itemInput.attr('name');

                $itemLabel.attr('for', itemName+'-'+$newId);
                $itemInput.attr('id', itemName+'-'+$newId);
                $itemInput.attr('name', `item[${$newId}][${itemName}]`);
                $itemInput.attr('disabled', false);
            });

            $container.append($templateNew);
            initModulesInScope($wrappers);

        });

        $deleteButtons.on('click', deleteItem);

        function deleteItem() {
            $(this).closest('.js-add-template__item').remove();
        }
    });
}