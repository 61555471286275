"use strict";

export function initInScope ($scope) {

    if(window.location.href.indexOf("shopAffililate=zermatt.ch") > -1) {
        try {
            let data = sessionStorage.getItem('shopAffiliateZermatt');
            if (data != 'showButton') {
                sessionStorage.setItem('shopAffiliateZermatt', 'showButton');
            }
        } catch(err) {}
    }

    try {
        let data = sessionStorage.getItem('shopAffiliateZermatt');
        if (data == 'showButton') {
            $scope.find('.js-shop-affiliate-zermatt__button').attr('hidden', false);
        }
    } catch(err) {}
}
