"use strict";

export function initInScope($scope) {
    let $body = $('body');
    let $dropDowns = $scope.find('.js-drop-down');
    let $dropDownsToggles = $scope.find('.js-drop-down__toggle');

    $dropDownsToggles.on('click', function (evt) {
        let $dropDown = $(this).closest('.js-drop-down');

        if ($dropDown.hasClass('show')) {
            closeDropDown($dropDown);
            $body.off('click', bodyHandler);
        } else {
            closeDropDown($dropDowns);
            openDropDown($dropDown);
            $body.on('click', bodyHandler);
        }

        evt.preventDefault();
    });

    function bodyHandler(e) {
        let $target = $(e.target);
        if (!$target.is('.js-drop-down') && !$target.closest('.js-drop-down').length) {
            closeDropDown($dropDowns);
            $body.off('click', bodyHandler);
        }
    }
}

function openDropDown($dropDown) {
    $dropDown.addClass('show')
        .find('.js-drop-down__menu').addClass('show');
}

function closeDropDown($dropDown) {
    $dropDown.removeClass('show')
        .find('.js-drop-down__menu').removeClass('show');
}