"use strict";

import loadJQueryUi from './loadJQueryUi';


/* -- TODO: adjustments -- */

$.fn.zIndex = function() {
    /* backfill old zIndex function for old jquery ui datepicker*/
    return this.css("zIndex");
};

export function initInScope ($scope) {
    let $datepickerContainers = $scope.find('.js-datepicker');

    loadJQueryUi().then(function () {
        $datepickerContainers.each(function () {

            let $this = $(this);
            let $datepicker = $(this).find('.js-datepicker__input');
            let $altField = $(this).find('.js-datepicker__alt-field');
            //$datepicker.val( toLocalDateString($altField.val()) );
            let isInline = false;
            let $datepickerInlineButton = $('.js-datepicker--inline__button');
            if ($(this).hasClass('js-datepicker--inline')) {
                isInline = true;
            }

            let $inputFromDayShort = $('.js-datepicker__from-input-day--short');
            let $inputFromMonth = $('.js-datepicker__from-input-month');
            let $inputFromYear = $('.js-datepicker__from-input-year');
            let $inputFromDayFull = $('.js-datepicker__from-input-day--full');
            let availableDateRanges;

            if ($(this).attr('data-ranges') !== '[]' && !!$(this).attr('data-ranges')) {
                availableDateRanges = JSON.parse($(this).attr('data-ranges'));
            } else {
                availableDateRanges =  false;
            }

            // capitalize fr datepicker monthnames
            if (_config.lang && _config.lang == 'fr') {
                let monthNames = $.datepicker.regional['fr'].monthNames;
                let capitalizedMonthNames = [];
                monthNames.forEach(function(month) {
                    capitalizedMonthNames.push(month.charAt(0).toUpperCase()+month.slice(1));
                });
                $.datepicker.regional['fr'].monthNames = capitalizedMonthNames;
                $.datepicker.setDefaults($.datepicker.regional['fr']);
            }

            let options = {
                numberOfMonths: isInline && matchMedia('(min-width: 768px)').matches ? 2 : 1,
                minDate: availableDateRanges ? availableDateRanges[0].start : 0, // minimum selectable date is today
                maxDate: availableDateRanges ? availableDateRanges[availableDateRanges.length-1].end : null,
                altFormat: 'yy-mm-dd',
                altField: $altField,
                nextText: '<span class="icon icon-arrow" aria-label="Next" title="Next"></span>',
                prevText: '<span class="icon icon-arrow" aria-label="Previous" title="Previous"></span>',
                dateFormat: 'yy-mm-dd',
                firstDay: 1,
                beforeShowDay: function(date) {
                    let newDate = $.datepicker.formatDate( "yy-mm-dd", date);

                    // new Date(fromDate).getTime() + 1 * dayInMilliseconds
                    if(availableDateRanges) {
                        for(let i=0; i< availableDateRanges.length; i++) {
                            if(newDate >= availableDateRanges[i].start && newDate <= availableDateRanges[i].end) {
                                return [true, ''];
                            }
                        }
                        return [false, ''];
                    }

                    return [true, ''];
                },
                onSelect: function(dateString, inst) {
                    let selectedDate = new Date(inst.selectedYear, inst.selectedMonth, inst.selectedDay);
                    let $self = $(this);
                    $(this).trigger('change');
                    $altField.val(dateString);
                    $datepicker.datepicker('setDate', dateString);
                    $datepicker.val(toLocalDateString(Date.parse(dateString)));
                    if(isInline) {
                        let weekday = $.datepicker.formatDate( "DD", selectedDate);
                        let month = $.datepicker.formatDate( "MM", selectedDate);
                        $self.data('datepicker').inline = true;
                        $datepickerInlineButton.attr('disabled', false);
                        $inputFromDayShort.text(inst.selectedDay);
                        $inputFromDayFull.text(weekday);
                        $inputFromMonth.text(month);
                        $inputFromYear.text(inst.selectedYear);

                    }
                }
            };

            if ($altField.val()) {
                options.defaultDate = +$altField.val();
            }

            function toLocalDateString(timestamp) {
                return new Date(+timestamp).toLocaleString(_config.lang, {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric'
                });
            }

            addKeyboardSupport($($datepicker));

            if (isInline) {
                $(this).datepicker(options);
            } else {
                $datepicker.datepicker(options);
            }
            function addKeyboardSupport($datepicker) {
                function triggerSelect () {
                    $('.ui-datepicker-current-day').click();
                }
                let newDate = $datepicker.datepicker('getDate');

                $datepicker.on('keydown', function (evt) {
                    if(newDate == null) {
                        $datepicker.datepicker('setDate', new Date());
                        newDate = $datepicker.datepicker('getDate');
                    }

                    switch (evt.keyCode) {
                        case 37: /*left*/
                            newDate.setDate(newDate.getDate() - 1);
                            evt.preventDefault();
                            evt.stopImmediatePropagation();
                            break;
                        case 38: /*up*/
                            newDate.setDate(newDate.getDate() - 7);
                            evt.preventDefault();
                            evt.stopImmediatePropagation();
                            break;
                        case 39: /*right*/
                            newDate.setDate(newDate.getDate() + 1);
                            evt.preventDefault();
                            evt.stopImmediatePropagation();
                            break;
                        case 40: /*down*/
                            newDate.setDate(newDate.getDate() + 7);
                            evt.preventDefault();
                            evt.stopImmediatePropagation();
                            break;
                        case 33: /*page up*/
                            newDate.setDate(newDate.getDate() - 30);
                            evt.preventDefault();
                            evt.stopImmediatePropagation();
                            break;
                        case 34: /*page down*/
                            newDate.setDate(newDate.getDate() + 30);
                            evt.preventDefault();
                            evt.stopImmediatePropagation();
                            break;
                        case 13: /*enter*/
                            // selectedDate = newDate;
                            // triggerSelect();
                            newDate.setDate(newDate.getDate());
                            $altField.val(newDate.getTime());
                            $datepicker.datepicker('setDate', newDate);
                            $(this).trigger('change');
                            $datepicker.val(toLocalDateString(newDate.getTime()));
                            $datepicker.datepicker('hide');

                            evt.preventDefault();
                            evt.stopImmediatePropagation();
                            break;
                    }

                    if (newDate.getTime() !== $datepicker.datepicker('getDate').getTime()) {
                        $datepicker.datepicker('setDate', newDate);
                        $datepicker.val( toLocalDateString(newDate.getTime()));
                        if(isInline) {
                            $datepickerInlineButton.attr('disabled', false);

                        }

                    }
                });
            }
        });
    });

}
