"use strict";

import loadScript from "./loadScript";

export function initInScope ($scope) {
    let lightboxGroups = $scope.find('.js-lightbox-group');
    let lightboxGroupItems = $scope.find('.js-lightbox-group__item');

    import('lightgallery').then(function () {
        /* the following imports are only needed if there are videos in your lightbox*/
        /* IMPORTANT: Do not forget to add 'lg-video-object lg-html5' to video-elements*/
        import('video.js').then(function (videojs) {
            /* because lg-video needs a global instance of videojs */
            window.videojs = videojs;
        });
        loadScript('//f.vimeocdn.com/js/froogaloop2.min.js');
        import('lg-video');

        lightboxGroups.each(function () {
            $(this).lightGallery({
                selector: '.js-lightbox-group__item',
                animateThumb: false,
                download: false,
                videojs: true,
                counter: false,
                prevHtml: "<span class='icon icon-arrow-left text-default'></span>",
                nextHtml: "<span class='icon icon-arrow-right text-default'></span>",
                addClass: $(this).attr('data-additional-class') ||  "custom-lightbox",
                iframeMaxWidth: '78%'
            });
        });

        //prevent isClickable
        lightboxGroupItems.on('click', function (evt) {
            let $this = $(this);
            if($this.closest('.js-tracking--pageview').length === 0) {
                evt.stopImmediatePropagation();
            }
        });
    });

}
