"use strict";

import initModulesInScope from "./initModulesInScope";

export function initInScope ($scope) {

    let pendingRequest = null;
    let pendingRequestForm = null;
    let $input = $scope.find('.js-merch-change-variant__input');
    let $resultForm = $scope.find('.js-merch-change-variant__form-result');
    let $resultDetail = $scope.find('.js-merch-change-variant__result-detail');
    let $loading = $scope.find('.js-merch-change-variant__loading');
    let $wrapper = $('.js-merch-change-variant__wrapper');


    $input.on('change', function (evt) {
        evt.preventDefault();

        if (pendingRequest) {
            pendingRequest.abort();
            pendingRequest = null;
        }

        let actionDetail;
        let actionForm;
        let toggleModal = false;
        let modal;
        let selectedOption;

        if($(this).is('select')) {
            selectedOption = $(this).find('option:selected');
            toggleModal = selectedOption.data('toggle-modal') || false;
            actionDetail = selectedOption.data('merch-detail-action');
            actionForm = selectedOption.data('merch-form-action');
            modal = selectedOption.data('target') || false;
        } else {
            actionDetail = $(this).data('merch-detail-action');
            actionForm = $(this).data('merch-form-action');
        }

        if(toggleModal) {
            selectedOption.prop("selected", false);
            $(modal).modal('show');
        } else {
            $loading.attr('hidden', null);

            $.when(pendingRequest = $.ajax(actionDetail, {
                    type: "GET",
                    cache: false
                }
            )).then(function (resp1) {
                $resultDetail.empty().append(resp1);
                return  pendingRequest = $.ajax(
                    actionForm, {
                        type: "GET",
                        cache: false
                    }
                );
            }).then(function (resp2) {
                $resultForm.empty().append(resp2);
            }).always(function() {
                $loading.attr('hidden', 'hidden');
                pendingRequestForm = null;
                initModulesInScope($wrapper);
            });
        }

    });
}