/** @jsx h */

import {h, Fragment} from 'preact';
import {Collapse, Button} from 'react-bootstrap';
import {noop} from "../../utils";
import {useState} from "preact/hooks";
import {translate} from '@elements/translations';
import NumberSpinner from "../NumberSpinner";

export default function EventTicketCombiItem({
    ticket = {},
    ageGroups = [],
    selectedCurrencyId = 0,
    currencies = [],
    availability = 0,
    defaultOpen = false,
    ticketCounter = {},
    getTotalTicketCount = noop,
    onConfigurationChanged = noop,
    setTicketCounter = noop,
    index = 0
}) {
    const [open, setOpen] = useState(defaultOpen);

    const getTotalOfTicketAgeGroup = (ticketId, ageGroupId) => {
        let subtotal = 0;
        if(ticketCounter[ticketId][ageGroupId]) {
            subtotal = ticket.prices[ageGroupId][selectedCurrencyId] * ticketCounter[ticketId][ageGroupId];
        }
        return subtotal;
    };

    return (
        <li className="ticket-configuration__box" key={index}>
            <Fragment>
                <Button onClick={() => setOpen(!open)}
                        aria-controls={index}
                        variant="no-styling"
                        aria-expanded={open} className="ticket-configuration__box-header p-0 w-100">
                    <div className="row">
                        <div className="col-md-6 col-10">
                            {ticket.title}
                            <time className="d-block text-grey lh-1" dateTime={ticket.datetime}>{ticket.date}</time>
                        </div>
                        <div className="col-md-6 col-2 text-right">
                            <span
                                className={`icon icon-arrow-bold text-primary ml-2 fz10  ${open ? 'icon-rotate-180' : ''}`}/>
                        </div>
                    </div>
                </Button>
                <Collapse in={open}>
                    <div id={index}>
                        <div className="ticket-configuration__table vertical-gutter--3">
                            <div className="ticket-configuration__table-header row vertical-gutter__item d-md-flex d-none">
                                <div className="col-md-4">
                                    {translate('event-ticket.Personen')}
                                </div>
                                <div className="col-md-2">
                                    {translate('event-ticket.Anzahl')}
                                </div>
                                <div className="col-md-3 text-center">
                                    {translate('event-ticket.Einzelpreis')}
                                </div>
                                <div className="col-md-3 text-right">
                                    {translate('event-ticket.Gesamtpreis')}
                                </div>
                            </div>
                            {ticket.ageGroups.map((ageGroup, index) => (
                                <div className="row vertical-gutter__item" key={index}>
                                    <div className="col-md-4 col-6 d-flex align-items-center">
                                        {ageGroups.byId[ageGroup].name}
                                    </div>
                                    <div className="col-md-2 col-6">
                                        <NumberSpinner
                                            value={ticketCounter[ticket.id][ageGroup]}
                                            maxValue={availability - getTotalTicketCount(ticket.id, ageGroup)}
                                            onChange={(value) => {

                                                setTicketCounter(ticketCounter => {
                                                    return {...ticketCounter, [ticket.id]: {...ticketCounter[ticket.id], [ageGroup]: value}}
                                                });

                                                onConfigurationChanged(
                                                    {ticketId: ticket.id,
                                                        ageGroupId: ageGroup,
                                                        ticketCounter: value,
                                                        date: ticket.datetime});
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-3 col-6 text-md-center">
                                        <div className="d-md-none fz10">{translate('event-ticket.Einzelpreis')}</div>
                                        {currencies.byId[selectedCurrencyId].name + " " + ticket.prices[ageGroup][selectedCurrencyId].toFixed(2)}
                                    </div>
                                    <div className="col-md-3 col-6 text-md-right">
                                        <div className="d-md-none fz10">{translate('event-ticket.Gesamtpreis')}</div>
                                        {currencies.byId[selectedCurrencyId].name + " " + getTotalOfTicketAgeGroup(ticket.id,ageGroup).toFixed(2)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Collapse>
            </Fragment>
        </li>
    )
}