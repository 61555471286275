/** @jsx h */

import {h, render, Component} from "preact";
import {noop} from "../utils";
import {useState} from "preact/hooks";
import {translate} from '@elements/translations';
import loadJQueryUi from '../loadJQueryUi';
import {dateToISOString, ISOStringToDate, localDateToUTCDate, UTCDateToLocalDate} from "@elements/date-utils";


/**
 * @return {string}
 */

export default class DatepickerRange extends Component {

    //datepicker just copied and adjustments for preact
    componentDidMount() {
        const dayInMilliseconds = 24 * 60 * 60 * 1000;
        this.$el = $(this.el);
        let $el = this.$el;
        let props = this.props;
        let predefinedOptions = this.props.datePickerOptions;
        let seasons = this.props.seasons;
        let priceTendencies = this.props.priceTendencies;

        loadJQueryUi().then(function () {
            let $this = $el,
                $fromInput = $this.find('.js-range-datepicker__from'),
                $toInput = $this.find('.js-range-datepicker__to'),
                isFromDate = false,
                isToDate = false,
                fromDate = props.fromDate || predefinedOptions['from'] || null,
                toDate = props.toDate || predefinedOptions['to'] || null,
                minSelectableDates = predefinedOptions['minSelectableDates'],
                minSelectableDays = predefinedOptions['minSelectableDays'],
                maxSelectableDays = predefinedOptions['maxSelectableDays'],
                availableDateRanges = predefinedOptions['availableDateRanges'] || false;


            let $inputFromDayShort = $('.js-datepicker__from-input-day--short'),
                $inputFromMonth = $('.js-datepicker__from-input-month'),
                $inputFromYear = $('.js-datepicker__from-input-year'),
                $inputFromDayFull = $('.js-datepicker__from-input-day--full');

            let $inputToDayShort = $('.js-datepicker__to-input-day--short'),
                $inputToMonth = $('.js-datepicker__to-input-month'),
                $inputToYear = $('.js-datepicker__to-input-year'),
                $inputToDayFull = $('.js-datepicker__to-input-day--full');

            $this.attr('data-from', fromDate);
            $this.attr('data-to', toDate);
            $this.attr('data-fromStr', fromDate);
            $this.attr('data-toStr', toDate);
            
            // capitalize fr datepicker monthnames
            if (_config.lang && _config.lang == 'fr') {
                let monthNames = $.datepicker.regional['fr'].monthNames;
                let capitalizedMonthNames = [];
                monthNames.forEach(function(month) {
                    capitalizedMonthNames.push(month.charAt(0).toUpperCase()+month.slice(1));
                });
                $.datepicker.regional['fr'].monthNames = capitalizedMonthNames;
                $.datepicker.setDefaults($.datepicker.regional['fr']);
            }


            let options = {
                numberOfMonths:  matchMedia('(min-width: 768px)').matches ? 2 : 1,
                nextText: '<span class="icon icon-arrow" aria-label="Next" title="Next"></span>',
                prevText: '<span class="icon icon-arrow" aria-label="Previous" title="Previous"></span>',
                dateFormat: 'yy-mm-dd',
                minDate: availableDateRanges && availableDateRanges[0] ? availableDateRanges[0].from : 0, // minimum selectable date is today
                defaultDate: fromDate,
                firstDay: 1,
                maxDate: availableDateRanges && availableDateRanges[availableDateRanges.length-1] ?  availableDateRanges[availableDateRanges.length-1].to : null,
                onSelect: function(dateString, inst) {
                    let selectedDate = new Date(Date.UTC(inst.selectedYear, inst.selectedMonth, inst.selectedDay));
                    let fromDate = $this.attr('data-from');
                    let toDate = $this.attr('data-to');

                    let month = $.datepicker.formatDate( "MM", UTCDateToLocalDate(selectedDate));
                    let weekday = $.datepicker.formatDate( "DD", UTCDateToLocalDate(selectedDate));

                    if (!fromDate || dateString < fromDate || (dateString === fromDate && minSelectableDays !== 1) || toDate) {
                        $this.attr('data-from', dateString);
                        $this.attr('data-fromStr', dateString);
                        $this.attr('data-to', null);
                        $this.attr('data-toStr', null);


                        $(this).trigger('changed', {
                            from: dateString,
                            to: null
                        });

                        $inputToDayShort.text('');
                        $inputToDayFull.text('');
                        $inputToMonth.text('');
                        $inputToYear.text('');

                        $inputFromDayShort.text(selectedDate.getUTCDate());
                        $inputFromDayFull.text(weekday);
                        $inputFromMonth.text(month);
                        $inputFromYear.text(selectedDate.getUTCFullYear());


                    } else {
                        $this.attr('data-to', dateString);
                        $this.attr('data-toStr', dateString);

                        $el.trigger('changed', {
                            from: dateString,
                            to: dateString
                        });

                        $inputToDayShort.text(selectedDate.getUTCDate());
                        $inputToDayFull.text(weekday);
                        $inputToMonth.text(month);
                        $inputToYear.text(selectedDate.getUTCFullYear());

                        $el.datepicker('refresh');
                    }

                    updateInputs();
                },
                beforeShowDay: function (date) {
                    let newDate = $.datepicker.formatDate( "yy-mm-dd", date);

                    let dateFrom = $this.attr('data-fromStr');
                    let dateTo = $this.attr('data-toStr');
                    let dateClass = '';

                    if(seasons) {
                        seasons.allIds.map((seasonId) => {
                            let season = seasons.byId[seasonId];
                            for(let i=0; i< season['ranges'].length; i++) {
                                if(newDate >= season['ranges'][i].from && newDate <= season['ranges'][i].to) {
                                    let colorClass = ` is-season--${season.color} `;
                                    dateClass += colorClass;
                                }
                            }
                        });
                    }

                    if(priceTendencies) {
                        priceTendencies.allIds.map((tendencyId) => {
                            let priceTendency = priceTendencies.byId[tendencyId];
                            for(let i=0; i< priceTendency['ranges'].length; i++) {
                                if(newDate >= priceTendency['ranges'][i].from && newDate <= priceTendency['ranges'][i].to) {
                                    let colorClass = ` is-price-tendency--${priceTendency.arrow} `;
                                    dateClass += colorClass;
                                }
                            }
                        });
                    }

                    if (dateFrom === newDate) {
                        $(this).trigger('changed', {
                            from: dateFrom,
                            to: null
                        });
                        
                        let currentFromDay = new Date(dateFrom);

                        let day = $.datepicker.formatDate( "dd", UTCDateToLocalDate(currentFromDay));
                        let month = $.datepicker.formatDate( "MM", UTCDateToLocalDate(currentFromDay));
                        let weekday = $.datepicker.formatDate( "DD", UTCDateToLocalDate(currentFromDay));
                        let year = $.datepicker.formatDate( "yy", UTCDateToLocalDate(currentFromDay));

                        $inputFromDayShort.text(day);
                        $inputFromDayFull.text(weekday);
                        $inputFromMonth.text(month);
                        $inputFromYear.text(year);

                        $el.trigger('change');
                        dateClass += ' is-datefrom';
                    }
                    
                    if (dateTo === newDate) {
                        $(this).trigger('changed', {
                            from: dateFrom,
                            to: dateTo
                        });

                        let currentToDay = new Date(dateTo);

                        let day = $.datepicker.formatDate( "dd", UTCDateToLocalDate(currentToDay));
                        let month = $.datepicker.formatDate( "MM", UTCDateToLocalDate(currentToDay));
                        let weekday = $.datepicker.formatDate( "DD", UTCDateToLocalDate(currentToDay));
                        let year = $.datepicker.formatDate( "yy", UTCDateToLocalDate(currentToDay));

                        $inputToDayShort.text(day);
                        $inputToDayFull.text(weekday);
                        $inputToMonth.text(month);
                        $inputToYear.text(year);

                        $el.trigger('change');
                        dateClass += ' is-dateto';
                        updateInputs();
                    }

                    if (dateTo && dateFrom
                        && newDate >  dateFrom
                        && newDate <  dateTo) {
                        dateClass += ' is-in-range';
                    }

                    // new Date(fromDate).getTime() + 1 * dayInMilliseconds
                    if(availableDateRanges) {
                        for(let i=0; i< availableDateRanges.length; i++) {
                            if(newDate >= availableDateRanges[i].from && newDate <= availableDateRanges[i].to) {
                                return updateMinMaxDays();

                            }
                        }
                        return [false, dateClass];
                    }

                    if(!availableDateRanges) {
                        return updateMinMaxDays();
                    }

                    //enable only days between minDays and maxDays
                    function updateMinMaxDays() {

                        if(dateFrom && !dateTo) {
                            for(let j = minSelectableDays; j <= maxSelectableDays; j++ ) {
                                let minDate = (Date.parse(dateFrom) + minSelectableDays * dayInMilliseconds) ;
                                let maxDate = (Date.parse(dateFrom) + maxSelectableDays * dayInMilliseconds);
                                let dateFromDate =  Date.parse(dateFrom);

                                if((Date.parse(newDate) >= minDate && Date.parse(newDate) <= maxDate) || Date.parse(newDate) <= dateFromDate) {
                                    return [true, dateClass];
                                } else {
                                    return [false, dateClass];
                                }
                            }
                        } else {
                            return [true, dateClass];
                        }
                    }
                    return [true, dateClass];

                },
                onClose: function() {
                    let toDate = $this.attr('data-to');
                    let fromDate = $this.attr('data-from');

                    isFromDate = false;
                    isToDate = false;
                    if(!toDate && fromDate) {
                        let correctedToDate = Date.parse(fromDate) + 1 * dayInMilliseconds;
                        correctedToDate = UTCDateToLocalDate(new Date(correctedToDate));
                        $this.attr('data-to', $.datepicker.formatDate( "yy-mm-dd", correctedToDate));
                        $(this).data('datepicker').inline = false;
                        updateInputs();
                        toDate = $this.attr('data-to');

                        $el.trigger('changed', {
                            from: fromDate,
                            to: toDate
                        });
                        $el.trigger('change');
                    }
                }
            };

            $el.datepicker(options);

            function toLocalDateString(timestamp) {
                return UTCDateToLocalDate(new Date(+timestamp)).toLocaleString(_config.lang, {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric'
                });
            }

            function updateInputs() {
                let toValue = $this.attr('data-to');
                let fromValue = $this.attr('data-from');
                
                if ($fromInput.val() !== fromValue) {
                    $fromInput.val(fromValue);
                    props.onChangeFromDate(fromValue);
                }
                if ($toInput.val() !== toValue && $fromInput.val() === fromValue) {
                    $toInput.val(toValue);
                    props.onChangeToDate(toValue);
                }
                if ($toInput.val() === toValue && $fromInput.val() === fromValue && fromValue !== '' && toValue !== '') {
                    $this.trigger('change');
                }
            }

        });

    }

    componentWillUnmount() {
        // this.$el.datepicker('destroy');
    }

    render() {
        return (
            <div className="ticket-configuration__datepicker-wrapper">
                <div className="row">
                    <div className="col-6">
                        <div className="ticket-configuration__datepicker-text">
                            <div className="ticket-configuration__datepicker-text__label">
                                {translate('datepicker.from')}
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="ticket-configuration__datepicker-text__day js-datepicker__from-input-day--short">
                                </div>
                                <div className="pl-2">
                                    <div>
                                        <span className="ticket-configuration__datepicker-text__month js-datepicker__from-input-month"></span>
                                        <span className="ticket-configuration__datepicker-text__year js-datepicker__from-input-year ml-1"></span>
                                    </div>
                                    <span className="ticket-configuration__datepicker-text__day-name js-datepicker__from-input-day--full"></span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-6">
                        <div className="ticket-configuration__datepicker-text">
                            <div className="ticket-configuration__datepicker-text">
                                <div className="ticket-configuration__datepicker-text__label">
                                    {translate('datepicker.to')}
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="ticket-configuration__datepicker-text__day js-datepicker__to-input-day--short"></div>
                                    <div className="pl-2">
                                        <div>
                                            <span className="ticket-configuration__datepicker-text__month js-datepicker__to-input-month"></span>
                                            <span className="ticket-configuration__datepicker-text__year js-datepicker__to-input-year ml-1"></span>
                                        </div>
                                        <span className="ticket-configuration__datepicker-text__day-name js-datepicker__to-input-day--full"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="js-datepicker-range ticket-configuration__datepicker" ref={el => this.el = el}>
                    <input type="hidden" className="js-range-datepicker__from" name="from" value={ this.props.fromDate }/>
                    <input type="hidden" className="js-range-datepicker__to" name="to" value={ this.props.toDate }/>
                </div>


                {/*<div id="datepicker" ref={el => this.el = el} />*/}

            </div>
        );
    }
}
