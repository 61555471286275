/** @jsx h */

import {h, Fragment} from "preact";
import {noop} from "../../utils";
import {useState} from "preact/hooks";
import {translate} from '@elements/translations';
import EventTicketCombiItem from "./EventTicketCombiItem";

export default function EventTicketCombiConfigurationForm({
    ageGroups = [],
    selectedCurrencyId,
    currencies = [],
    status = null,
    ticketList = [],
    availability = 0,
    selectedConfigurationId = 0,
    ticketCounter = [],
    onChangeTicketCounter = noop,
    isInEdit = false,
    onChange = noop
}) {
    

    const getTotalOfTicketAgeGroup = (ticketId, ageGroupId) => {
        let subtotal = 0;

        ticketList.map(function(ticket) {
            if(ticket.id === ticketId && ticketCounter[ticketId][ageGroupId]) {
                subtotal = ticket.prices[ageGroupId][selectedCurrencyId] * ticketCounter[ticketId][ageGroupId];
            }
        });

        return subtotal;
    };

    const getTotalOfTickets = () => {
        let total = 0;
        for (let [ticketId] of Object.entries(ticketCounter)) {
            for (let [ageGroup] of Object.entries(ticketCounter[ticketId])) {
                total += getTotalOfTicketAgeGroup(ticketId, ageGroup);
            }
        }
        return total;
    };

    const getTotalTicketCount = (currentTicketId, currentAgeGroupId) => {
        let count = 0;

        for (let [ticketId] of Object.entries(ticketCounter)) {
            for (let [ageGroup] of Object.entries(ticketCounter[ticketId])) {
                if(ageGroup !== currentAgeGroupId || currentTicketId !== ticketId) {
                    count += ticketCounter[ticketId][ageGroup];
                }
            }
        }
        return count;
    };

    // Render
    return (
        <form>
            <ul className="list-unstyled">
                {ticketList.map((ticket, index) => (
                    <EventTicketCombiItem
                        defaultOpen={(isInEdit && ticket.id === selectedConfigurationId) || (!isInEdit && index === 0)}
                        ticket={ticket}
                        index={index}
                        status={status}
                        availability={availability}
                        currencies={currencies}
                        getTotalTicketCount={getTotalTicketCount}
                        setTicketCounter={onChangeTicketCounter}
                        ticketCounter={ticketCounter}
                        selectedCurrencyId={selectedCurrencyId}
                        onConfigurationChanged={onChange}
                        ageGroups={ageGroups}
                    />

                ))}
            </ul>

            <div className="ticket-configuration__box-footer">
                <div className="row">
                    <div className="col-8">
                        {translate('event-ticket.Gesamtsumme')}
                    </div>
                    <div className="col-4 text-right">
                        {currencies.byId[selectedCurrencyId].name + " " + getTotalOfTickets().toFixed(2) }
                    </div>
                </div>
            </div>
        </form>
    );

}