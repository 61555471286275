"use strict";

import loadJQueryUi from './loadJQueryUi';

export function initInScope ($scope) {
    const dayInMilliseconds = 24 * 60 * 60 * 1000;
    let $datepickerContainers = $scope.find('.js-range-datepicker'),
        availableDateRanges;
    /* TODO: addKeyboardSupport optimize */





    loadJQueryUi().then(function () {


        $datepickerContainers.each(function () {

            let $this = $(this);
            let $fromInput = $(this).find('.js-range-datepicker__from');
            let $toInput = $(this).find('.js-range-datepicker__to');
            let $datepicker = $(this).find('.js-range-datepicker__input');
            let isFromDate = false;
            let isToDate = false;
            let isInline = false;
            let selectableDates = parseInt($(this).attr('data-selectable-dates')) || 2;
            let minSelectableDates = parseInt($(this).attr('data-min-selectable-dates')) || 2;
            let minSelectableDays = parseInt($(this).attr('data-min-days'));
            let maxSelectableDays =  parseInt($(this).attr('data-max-days'));
            let selectableDayRange = false;

            if(minSelectableDays > maxSelectableDays) {
                console.warn("Datepicker: data-max-days must be a bigger number than data-min-days");
            } else {
                if(minSelectableDays && !maxSelectableDays) {
                    console.warn("Datepicker: data-max-days is not defined.");
                } else if(!minSelectableDays && maxSelectableDays) {
                    console.warn("Datepicker: data-min-days is not defined.");
                } else {
                    selectableDayRange = true;
                }
            }

            if ($(this).attr('data-ranges') !== '[]' && !!$(this).attr('data-ranges')) {
                availableDateRanges = JSON.parse($(this).attr('data-ranges'));
            } else {
                availableDateRanges =  false;
            }

            if ($this.hasClass('js-range-datepicker--inline')) {
                isInline = true;
            }

            let $inputFromDayShort = $('.js-datepicker__from-input-day--short');
            let $inputFromMonth = $('.js-datepicker__from-input-month');
            let $inputFromYear = $('.js-datepicker__from-input-year');
            let $inputFromDayFull = $('.js-datepicker__from-input-day--full');

            let $inputToDayShort = $('.js-datepicker__to-input-day--short');
            let $inputToMonth = $('.js-datepicker__to-input-month');
            let $inputToYear = $('.js-datepicker__to-input-year');
            let $inputToDayFull = $('.js-datepicker__to-input-day--full');


            let $datepickerInlineButton = $('.js-datepicker--inline__button');


            $this.attr('data-from', $fromInput.val());
            $this.attr('data-to', $toInput.val());
            $this.attr('data-fromStr', $fromInput.val());
            $this.attr('data-toStr', $toInput.val());



            // capitalize fr datepicker monthnames
            if (_config.lang && _config.lang == 'fr') {
                let monthNames = $.datepicker.regional['fr'].monthNames;
                let capitalizedMonthNames = [];
                monthNames.forEach(function(month) {
                    capitalizedMonthNames.push(month.charAt(0).toUpperCase()+month.slice(1));
                });
                $.datepicker.regional['fr'].monthNames = capitalizedMonthNames;
                $.datepicker.setDefaults($.datepicker.regional['fr']);
            }

            let options = {
                numberOfMonths: isInline && matchMedia('(min-width: 768px)').matches ? 2 : 1,
                nextText: '<span class="icon icon-arrow" aria-label="Next" title="Next"></span>',
                prevText: '<span class="icon icon-arrow" aria-label="Previous" title="Previous"></span>',
                dateFormat: 'yy-mm-dd',
                minDate: availableDateRanges && availableDateRanges[0] ? availableDateRanges[0].start : 0, // minimum selectable date is today
                firstDay: 1,
                defaultDate: $this.data('from')
                    ? $this.data('from')
                    : null,
                maxDate: availableDateRanges && availableDateRanges[availableDateRanges.length-1] ?  availableDateRanges[availableDateRanges.length-1].end : null,
                    onSelect: function(dateString, inst) {
                    let selectedDate = new Date(inst.selectedYear, inst.selectedMonth, inst.selectedDay);

                    let fromDate = $this.attr('data-from');
                    let toDate = $this.attr('data-to');

                    let month = $.datepicker.formatDate( "MM", selectedDate);
                    let weekday = $.datepicker.formatDate( "DD", selectedDate);


                    if (!fromDate || dateString < fromDate || (dateString == fromDate && minSelectableDays != 1) || toDate) {
                        $this.attr('data-from', dateString);
                        $this.attr('data-fromStr', dateString);
                        $this.attr('data-to', null);
                        $this.attr('data-toStr', null);

                        $(this).data('datepicker').inline = true;

                        $(this).trigger('changed', {
                            from: dateString,
                            to: null
                        });


                        if(isInline) {
                            $inputToDayShort.text('');
                            $inputToDayFull.text('');
                            $inputToMonth.text('');
                            $inputToYear.text('');
                            if(minSelectableDates == 1) {
                                $datepickerInlineButton.attr('disabled', false);
                            } else {
                                $datepickerInlineButton.attr('disabled', true);

                            }
                            $inputFromDayShort.text(inst.selectedDay);
                            $inputFromDayFull.text(weekday);
                            $inputFromMonth.text(month);
                            $inputFromYear.text(inst.selectedYear);
                        }

                    } else {
                        $this.attr('data-to', dateString);
                        $this.attr('data-toStr', dateString);

                        $(this).trigger('changed', {
                            from: dateString,
                            to: dateString
                        });

                        $(this).data('datepicker').inline = false;

                        if(isInline) {
                            $inputToDayShort.text(inst.selectedDay);
                            $inputToDayFull.text(weekday);
                            $inputToMonth.text(month);
                            $inputToYear.text(inst.selectedYear);
                            $datepickerInlineButton.attr('disabled', false);

                        }
                        $this.datepicker('refresh');
                    }
                    updateInputs();
                    
                },
                beforeShowDay: function (date) {
                    
                    let newDate = $.datepicker.formatDate( "yy-mm-dd", date);
                    let dateFrom = $this.attr('data-fromStr');
                    let dateTo = $this.attr('data-toStr');

                    if (dateFrom === newDate) {
                        return [true, 'is-datefrom'];
                    }

                    
                    if (dateTo === newDate) {
                        return [true, 'is-dateto'];
                    }

                    if (dateTo && dateFrom
                        && newDate >  dateFrom
                        && newDate <  dateTo) {
                        return [true, 'is-in-range'];
                    }
                    
                   // new Date(fromDate).getTime() + 1 * dayInMilliseconds
                    if(availableDateRanges && selectableDayRange && isInline) {
                        for(let i=0; i< availableDateRanges.length; i++) {
                            if(newDate >= availableDateRanges[i].start && newDate <= availableDateRanges[i].end) {
                                return updateMinMaxDays();

                            }
                        }
                        return [false, ''];
                    }

                    if(!availableDateRanges && selectableDayRange && isInline) {
                        return updateMinMaxDays();
                    }


                    //enable only days between minDays and maxDays
                    function updateMinMaxDays() {

                        if(dateFrom && !dateTo) {
                            for(let j = minSelectableDays; j <= maxSelectableDays; j++ ) {
                                let minDate = (Date.parse(dateFrom) + minSelectableDays * dayInMilliseconds) ;
                                let maxDate = (Date.parse(dateFrom) + maxSelectableDays * dayInMilliseconds);
                                let dateFromDate =  Date.parse(dateFrom);

                                if((Date.parse(newDate) >= minDate && Date.parse(newDate) <= maxDate) || Date.parse(newDate) <= dateFromDate) {
                                    return [true, ''];
                                } else {
                                    return [false, ''];
                                }
                            }
                        } else {
                            return [true, ''];
                        }
                    }
                    return [true, ''];

                },
                onClose: function() {
                    let toDate = $this.attr('data-to');
                    let fromDate = $this.attr('data-from');

                    isFromDate = false;
                    isToDate = false;
                    if (!toDate && fromDate) {
                        let correctedToDate = Date.parse(fromDate) + 1 * dayInMilliseconds;
                        correctedToDate = UTCDateToLocalDate(new Date(correctedToDate));
                        $this.attr('data-to', $.datepicker.formatDate( "yy-mm-dd", correctedToDate));
                        $(this).data('datepicker').inline = false;
                        updateInputs();
                        toDate = $this.attr('data-to');

                        $(this).trigger('changed', {
                            from: fromDate,
                            to: toDate
                        });
                        $this.trigger('change');
                    }
                }
            };

            function updateInputs() {
                let toValue = $this.attr('data-to');
                let fromValue = $this.attr('data-from');
                
                if ($fromInput.val() !== fromValue) {
                    $fromInput.val(fromValue);
                    $datepicker.val( toLocalDateString(Date.parse($this.attr('data-from'))) + ' - ' +  toLocalDateString(Date.parse($this.attr('data-to'))));
                }
                if ($toInput.val() !== toValue && $fromInput.val() == fromValue) {
                    $toInput.val(toValue);
                    $datepicker.val( toLocalDateString(Date.parse($this.attr('data-from'))) + ' - ' +  toLocalDateString(Date.parse($this.attr('data-to'))));
                }
                if (!toValue) {
                    $datepicker.val( toLocalDateString(Date.parse($this.attr('data-from'))) + ' - ' +  toLocalDateString(Date.parse($this.attr('data-from')) + 1 * dayInMilliseconds));
                }
                if ($toInput.val() == toValue && $fromInput.val() == fromValue && fromValue != '' && toValue != '') {
                    $this.trigger('change');
                }
            } 

            function addKeyboardSupport($datepicker) {

                let newDate = $this.attr('data-from');

                function triggerSelect () {
                    //$('.ui-datepicker-current-day').click();
                }

                $datepicker.on('keydown', function (evt) {
                    newDate = $datepicker.datepicker('getDate');

                    if(newDate == null) {
                        $datepicker.datepicker('setDate', new Date());
                        newDate = $datepicker.datepicker('getDate');
                    }

                    switch (evt.keyCode) {
                        case 37: /*left*/
                            newDate.setDate(newDate.getDate() - 1);
                            evt.preventDefault();
                            evt.stopImmediatePropagation();
                            break;
                        case 38: /*up*/
                            newDate.setDate(newDate.getDate() - 7);
                            evt.preventDefault();
                            evt.stopImmediatePropagation();
                            break;
                        case 39: /*right*/
                            newDate.setDate(newDate.getDate()  + 1);
                            evt.preventDefault();
                            evt.stopImmediatePropagation();
                            break;
                        case 40: /*down*/
                            newDate.setDate(newDate.getDate()  + 7);
                            evt.preventDefault();
                            evt.stopImmediatePropagation();
                            break;
                        case 33: /*page up*/
                            newDate.setDate(newDate.getDate()  - 30);

                            evt.preventDefault();
                            evt.stopImmediatePropagation();
                            break;
                        case 34: /*page down*/
                            newDate.setDate(newDate.getDate()  + 30);
                            evt.preventDefault();
                            evt.stopImmediatePropagation();
                            break;
                        case 13: /*enter*/

                            if (!isFromDate) {
                                newDate.setDate(newDate.getDate());
                                $fromInput.val(newDate.getTime());
                                $this.attr('data-to', null);
                                $this.attr('data-from', new Date(newDate.getTime()));
                                updateInputs();
                                isFromDate = true;

                            } else  {
                                $toInput.val(newDate.getTime());
                                newDate.setDate(newDate.getDate());
                                $this.attr('data-to', new Date(newDate.getTime()));
                                updateInputs();
                                $this.trigger('change');
                                isToDate = true;
                                $datepicker.datepicker('hide');
                            }

                            evt.preventDefault();
                            evt.stopImmediatePropagation();
                            break;
                    }

                    if (newDate.getTime() !== $datepicker.datepicker('getDate').getTime()) {
                        $datepicker.datepicker('setDate', newDate);
                        let toDateValue = '';
                        if ($this.attr('data-to')) {
                            toDateValue = toLocalDateString(Date.parse($this.attr('data-to')));
                        }
                        $datepicker.val( toLocalDateString(Date.parse($this.attr('data-from'))) + ' - ' +  toDateValue);

                    }
                });
            }


            function toLocalDateString(timestamp) {
                return UTCDateToLocalDate(new Date(+timestamp)).toLocaleString(_config.lang, {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric'
                });
            }

            addKeyboardSupport($($datepicker));

            if (isInline) {
                $(this).datepicker(options);

            } else {
                $datepicker.datepicker(options);
            }


        });


    });

}

export function UTCDateToLocalDate(date) {
    if (date) {
        if (date instanceof Date){
            return new Date(date.getTime() + (date.getTimezoneOffset() * 60 * 1000));
        } else {
            console.warn('UTCDateToLocalDate expects a date, but was given ', date);
        }
    }
}
