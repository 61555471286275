export function supportsLocalStorage() {
    let supports = false;
    try {
        localStorage.setItem('supportsLocalStorage', 'true');
        supports = !!localStorage.getItem('supportsLocalStorage')
    } catch (e) {}

    return supports;
}

export function isEmptyObject(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export const addParamsToUrl = (url, params) =>
    url + (url.indexOf('?') >= 0 ? '&': '?')
    + Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

export const trace = (label) => (x) => {
    return x;
};

// No operation function; usually used as default fallback function to prevent errors
export function noop() {}