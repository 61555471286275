/** @jsx h */

import {h} from "preact";
import {noop} from "../../utils";
import {useState} from "preact/hooks";
import {translate} from '@elements/translations';
import EventTicketListItem from "./EventTicketListItem";

export default function EventTicketListConfigurationForm({
     ageGroups = [],
     selectedCurrencyId,
     currencies = [],
     ticketList = [],
     selectedConfigurationId = 0,
     ticketCounterDefault = {},
     isInEdit = false,
     prices,
     onChange = noop
 }) {
    const [ticketCounter, setTicketCounter] = useState(ticketCounterDefault);

    const getTotalOfTicketAgeGroup = (ticketId, ageGroupId) => {
        let subtotal = 0;
        if(ticketCounter[ticketId][ageGroupId]) {
            subtotal = prices[ageGroupId][selectedCurrencyId] * ticketCounter[ticketId][ageGroupId];
        }
        return subtotal;
    };

    const onCounterChanged = () => {
        let sum = 0;
        for (let [ticketId] of Object.entries(ticketCounter)) {
            for (let [ageGroup] of Object.entries(ticketCounter[ticketId])) {
                sum += ticketCounter[ticketId][ageGroup];
            }
        }
        return sum;
    };

    const getTotalOfTickets = () => {
        let total = 0;
        for (let [ticketId] of Object.entries(ticketCounter)) {
            for (let [ageGroup] of Object.entries(ticketCounter[ticketId])) {
                total += getTotalOfTicketAgeGroup(ticketId, ageGroup);
            }
        }
        return total;
    };

    // Render
    return (
        <form>
            <ul className="list-unstyled">
                {ticketList.map((ticket, index) => (

                    <EventTicketListItem
                        defaultOpen={(isInEdit && ticket.id === selectedConfigurationId) || (!isInEdit && index === 0)}
                        ticket={ticket}
                        index={index}
                        currencies={currencies}
                        ticketCounter={ticketCounter}
                        selectedCurrencyId={selectedCurrencyId}
                        setTicketCounter={setTicketCounter}
                        prices={prices}
                        onCounterChanged={onCounterChanged}
                        onConfigurationChanged={onChange}
                        ageGroups={ageGroups}
                    />
                ))}
            </ul>

            <div className="ticket-configuration__box-footer">
                <div className="row">
                    <div className="col-md-8">
                        {translate('event-ticket.Gesamtsumme')}
                    </div>
                    <div className="col-md-4 text-right">
                        {currencies.byId[selectedCurrencyId].name + " " + getTotalOfTickets().toFixed(2) }
                    </div>
                </div>
            </div>

        </form>
    );

}