export function initInScope($scope) {
    $scope.find('.js-conditional-form').each(function () {
        let $form = $(this);
        let $parts = $form.find('.js-conditional-form__part');

        $form.find('.js-conditional-form__hide-all').on('click', function() {
            $parts.attr('hidden', 'hidden');
        });

        $form.on('change', function () {
            let formData = $(this).serializeArray();
            $parts.attr('hidden', 'hidden');
            
            formData
                .filter(({name, value}) => !!value)
                .forEach(({name, value}) => {
                    let $currentParts = $parts.filter(`[data-name="${name}"]`);
                    $currentParts.filter(':not([data-value])').attr('hidden', null);

                    $currentParts.filter(`[data-value="${value}"]`).attr('hidden', null);
                });
        });
    })
}