"use strict";

export function initInScope($scope) {

    let $radioTabs = $scope.find('.js-radio-tabs'),
        $radios = $radioTabs.find('.js-radio-tabs__input'),
        $accordionGroups = $radioTabs.find('.js-radio-tabs__option');

    $accordionGroups.on('show.bs.tab', function (e) {
        let $this = $(this);

        let $radio = $this.find('.js-radio-tabs__input');
        $radio.prop("checked", true);

        $radios.each(function () {
            let $input = $(this);
            let attributesStr = $input.data('radio-tabs-add-attributes');
            if (attributesStr) {
                let $parentGroup = $input.closest('.js-radio-tabs__option');
                let $parentInput = $parentGroup.find('.js-radio-tabs__input');

                // all attributes in data-radio-accordion-add-attributes are added/removed corresponding to the parent radio
                attributesStr.split(',').forEach(function (attribute) {
                    if ($parentInput.is(':checked')) {
                        $input.attr(attribute, '');
                    } else {
                        $input.removeAttr(attribute);
                    }
                });

            }

        });


    });
}
