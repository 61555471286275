/** @jsx h */

import {h, Fragment} from "preact";
import {noop} from "../../utils";
import {useState} from "preact/hooks";
import {translate} from '@elements/translations';
import {Tooltip, Button, OverlayTrigger} from 'react-bootstrap';

export default function SkiTicketConfigurationForm({
                                                       name = "",
                                                       submitText = "",
                                                       ageGroups = [],
                                                       selectedAgeGroupId: defaultAgeGroupId = null,
                                                       selectedPersonId: defaultPersonId = null,
                                                       selectedCurrencyId,
                                                       prices = [],
                                                       configurations = [],
                                                       currencies = [],
                                                       upgrades = [],
                                                       insurances = [],
                                                       specialTickets = [],
                                                       isInEdit = false,
                                                       persons = [],
                                                       selectedInsuranceId: defaultInsuranceId = null,
                                                       selectedUpgradeIds: defaultUpgradeId = [],
                                                       selectedSpecialTicketId: defaultSpecialTicketId = null,
                                                       standardTicket = [],
                                                       onSubmit = noop,
                                                   }) {
    // State
    const [selectedAgeGroupId, setSelectedAgeGroupId] = useState(defaultAgeGroupId);
    const [selectedInsuranceId, setSelectedInsuranceId] = useState(defaultInsuranceId); /* radios */
    const [selectedUpgradeIds, setSelectedUpgradeIds] = useState(defaultUpgradeId); /* checkboxes */
    const [selectedSpecialTicketId, setSelectedSpecialTicketId] = useState(defaultSpecialTicketId); /* radios */
    const [selectedPersonId, setSelectedPersonId] = useState(defaultPersonId);

    let personsInConfiguration = [];
    configurations.map((configuration) => configuration.personId ? personsInConfiguration.push(configuration.personId) : null);

    // Helper functions
    const toggleUpgrade = id => {
        if (selectedUpgradeIds.includes(id)) {
            setSelectedUpgradeIds(selectedUpgradeIds.filter(x => x !== id));
        } else {
            setSelectedUpgradeIds([...selectedUpgradeIds, id]);
        }
    };


    const toggleInsurance = id => {
        setSelectedInsuranceId(id);
    };

    const toggleSpecialTicket = id => {
        setSelectedSpecialTicketId(id);
    };

    function compareArrays(first, second) {
        return first.every((e) => second.includes(e)) && second.every((e) => first.includes(e));
    }

    const handleSubmit = () => {

        let selectedPrice = prices.filter(obj => compareArrays(obj.upgradeIds, selectedUpgradeIds) && obj.insuranceId === selectedInsuranceId && obj.specialTicketId === selectedSpecialTicketId)[0];

        console.log("SUBMIT", {
            upgradeIds: selectedUpgradeIds,
            insuranceId: selectedInsuranceId,
            ageGroupId: selectedAgeGroupId,
            mappedAgeGroupId: selectedPrice.ageGroupMap[selectedAgeGroupId],
            personId: selectedPersonId,
            specialTicketId: selectedSpecialTicketId,
            realSpecialTicketId: selectedPrice.realSpecialTicketId,
            ticketArticleId: selectedPrice.ticketArticleId ? selectedPrice.ticketArticleId : 0,
            ticketProductId: selectedPrice.ticketProductId,
            realUpgradeId: selectedPrice.realUpgradeId
        });

        //ticketArticleId, ticketProductId and mappedAgeGroupId are needed for backend
        onSubmit({
            upgradeIds: selectedUpgradeIds,
            insuranceId: selectedInsuranceId,
            ageGroupId: selectedAgeGroupId,
            mappedAgeGroupId: selectedPrice.ageGroupMap[selectedAgeGroupId],
            personId: selectedPersonId,
            specialTicketId: selectedSpecialTicketId,
            ticketArticleId: selectedPrice.ticketArticleId ? selectedPrice.ticketArticleId : 0,
            realSpecialTicketId: selectedPrice.realSpecialTicketId,
            ticketProductId: selectedPrice.ticketProductId,
            realUpgradeId: selectedPrice.realUpgradeId
        });


        setSelectedUpgradeIds([]);
        setSelectedInsuranceId(null);
        setSelectedAgeGroupId(defaultAgeGroupId);
        setSelectedPersonId(null);
        setSelectedSpecialTicketId(null);
    };

    //get Total Price of configuration
    const getPrice = () => {
        let selectedPrice = prices.filter(obj => compareArrays(obj.upgradeIds, selectedUpgradeIds) && obj.insuranceId === selectedInsuranceId && obj.specialTicketId === selectedSpecialTicketId)[0];
        let total = 0;

        if (selectedPrice) {
            total = selectedPrice.byAgeGroupId[selectedAgeGroupId][selectedCurrencyId].toFixed(2)
        } else {
            console.warn("Error: getPrice(): configuration not found in prices");
            console.table(prices);
            console.warn("selectedAgeGroupId:", selectedAgeGroupId, "selectedUpgradeIds:", selectedUpgradeIds, "selectedInsuranceId:", selectedInsuranceId, "selectedSpecialTicketId:", selectedSpecialTicketId);
        }
        return total;
    };

    //get total of original price - needed if price has discount
    const getOriginalPrice = () => {
        let selectedPrice = prices.filter(obj => compareArrays(obj.upgradeIds, selectedUpgradeIds) && obj.insuranceId === selectedInsuranceId && obj.specialTicketId === selectedSpecialTicketId)[0];
        let total = 0;

        if (selectedPrice) {
            try {
                total = selectedPrice.originalPriceByAgeGroupId[selectedAgeGroupId][selectedCurrencyId].toFixed(2)
            } catch {
                total = 0;
            }
        } else {
            console.warn("Error: getOriginalPrice(): configuration not found in prices");
            console.table(prices);
            console.warn("selectedAgeGroupId:", selectedAgeGroupId, "selectedUpgradeIds:", selectedUpgradeIds, "selectedInsuranceId:", selectedInsuranceId, "selectedSpecialTicketId:", selectedSpecialTicketId);
        }
        return total;
    };

    //skiticket winter / selection day is saturday -> kids are for free
    const getAdditionalPriceInfo = () => {
        let selectedPrice = prices.filter(obj => compareArrays(obj.upgradeIds, selectedUpgradeIds) && obj.insuranceId === selectedInsuranceId && obj.specialTicketId === selectedSpecialTicketId)[0];
        let info = "";

        if (selectedPrice) {
            try {
                info = selectedPrice.ageGroupAdditionalInfo[selectedAgeGroupId];
            } catch {
            }
        } else {
            console.warn("Error: getAdditionalPriceInfo(): configuration not found in prices");
            console.table(prices);
            console.warn("selectedAgeGroupId:", selectedAgeGroupId, "selectedUpgradeIds:", selectedUpgradeIds, "selectedInsuranceId:", selectedInsuranceId, "selectedSpecialTicketId:", selectedSpecialTicketId);
        }
        return info;
    };

    const getOriginalLabelPriceInfo = () => {
        let selectedPrice = prices.filter(obj => compareArrays(obj.upgradeIds, selectedUpgradeIds) && obj.insuranceId === selectedInsuranceId && obj.specialTicketId === selectedSpecialTicketId)[0];
        let info = "";

        if (selectedPrice) {
            try {
                info = selectedPrice.originalLabelPriceByAgeGroupId[selectedAgeGroupId];
            } catch {
            }
        } else {
            console.warn("Error: getOriginalLabelPriceInfo(): configuration not found in prices");
            console.table(prices);
            console.warn("selectedAgeGroupId:", selectedAgeGroupId, "selectedUpgradeIds:", selectedUpgradeIds, "selectedInsuranceId:", selectedInsuranceId, "selectedSpecialTicketId:", selectedSpecialTicketId);
        }
        return info;
    };

    const getPriceWarning = () => {
        let selectedPrice = prices.filter(obj => compareArrays(obj.upgradeIds, selectedUpgradeIds) && obj.insuranceId === selectedInsuranceId && obj.specialTicketId === selectedSpecialTicketId)[0];
        let info = "";

        if (selectedPrice) {
            try {
                info = selectedPrice.priceWarningByAgeGroupId[selectedAgeGroupId];
            } catch {
            }
        } else {
            console.warn("Error: getPriceWarning(): configuration not found in prices");
            console.table(prices);
            console.warn("selectedAgeGroupId:", selectedAgeGroupId, "selectedUpgradeIds:", selectedUpgradeIds, "selectedInsuranceId:", selectedInsuranceId, "selectedSpecialTicketId:", selectedSpecialTicketId);
        }
        return info;
    };


    //get Total Price of configuration
    const getFixedPrice = () => {
        let total = getPrice().toFixed(2);
        return total;
    };

    //get price difference if upgrade will be chosen
    const getPriceDifferenceUpgrade = (upgrade) => {
        let currentPrice = getPrice();

        let newUpgradesArray = [...selectedUpgradeIds];
        if (!newUpgradesArray.includes(upgrade)) {
            newUpgradesArray.push(upgrade);
        }
        let otherPrice = prices.filter(obj => compareArrays(obj.upgradeIds, newUpgradesArray) && obj.insuranceId === selectedInsuranceId && obj.specialTicketId === selectedSpecialTicketId);
        let difference = 0;

        if (otherPrice !== 'undefined' && otherPrice.length > 0) {
            otherPrice = otherPrice[0].byAgeGroupId[selectedAgeGroupId][selectedCurrencyId];
            difference = otherPrice - currentPrice;
        }

        return difference.toFixed(2);
    };

    //get price difference if insurance will be chosen
    const getPriceDifferenceInsurance = (insurance) => {
        let currentPrice = getPrice();

        let otherPrice = prices.filter(obj => compareArrays(obj.upgradeIds, selectedUpgradeIds) && obj.insuranceId === insurance && obj.specialTicketId === selectedSpecialTicketId)[0];

        let difference = 0;
        if (otherPrice) {
            otherPrice = otherPrice.byAgeGroupId[selectedAgeGroupId][selectedCurrencyId];
            difference = +otherPrice - +currentPrice;
        }

        return difference.toFixed(2);
    };

    const getPriceDifferenceSpecialTicket = (specialTicket) => {
        let currentPrice = getPrice();
        let otherPrice = prices.filter(obj => compareArrays(obj.upgradeIds, selectedUpgradeIds) && obj.insuranceId === selectedInsuranceId && obj.specialTicketId === specialTicket)[0];

        let difference = 0;
        if (otherPrice) {
            otherPrice = otherPrice.byAgeGroupId[selectedAgeGroupId][selectedCurrencyId];
            difference = otherPrice - currentPrice;
        }

        return difference.toFixed(2);
    };

    //get available upgrades if special-tickets are selected
    const getAvailableUpgrades = () => {
        let availableUpgrades = [];
        if (specialTickets.allIds.length > 0) {
            let objects = prices.filter(obj => obj.specialTicketId === selectedSpecialTicketId);

            objects.map(function (obj) {
                availableUpgrades = [...obj.upgradeIds];
            });

            availableUpgrades = [...new Set(availableUpgrades)];
        } else {
            availableUpgrades = upgrades.allIds;
        }

        return availableUpgrades;
    };

    const getAvailableSpecialTickets = () => {
        let availableSpecialTickets = [];

        prices.map(function (obj) {
            availableSpecialTickets.push(obj.specialTicketId);
        });

        if (!availableSpecialTickets.includes(selectedSpecialTicketId)) {
            setSelectedInsuranceId(null);
        }

        availableSpecialTickets = [...new Set(availableSpecialTickets)];

        return availableSpecialTickets;
    };


    const getAvailableInsurances = () => {
        let availableInsurances = [];

        if (specialTickets.allIds.length > 0) {
            let objects = prices.filter(obj => compareArrays(obj.upgradeIds, selectedUpgradeIds) && obj.specialTicketId === selectedSpecialTicketId);
            objects.map(function (obj) {
                availableInsurances.push(obj.insuranceId);
            });

            if (!availableInsurances.includes(selectedInsuranceId)) {
                setSelectedInsuranceId(null);
            }

            availableInsurances = [...new Set(availableInsurances)];
        } else {
            prices.map(function (obj) {
                availableInsurances.push(obj.insuranceId);
            });

            if (!availableInsurances.includes(selectedInsuranceId)) {
                setSelectedInsuranceId(null);
            }

            availableInsurances = [...new Set(availableInsurances)];
        }

        return availableInsurances;
    };

    const renderAvailablePersonOptions = (person, persons) => {

        if (!personsInConfiguration.includes(person) || person === selectedPersonId) {
            return <option key={person} id={person} data-person-id={person}
                           data-age-group-id={persons.byId[person].ageGroup}
                           value={person}>{persons.byId[person].name}</option>
        }
    };

    const getSelectedPersonOrAgeGroup = () => {

        if(selectedPersonId) {
            return selectedPersonId;
        }

        if(selectedAgeGroupId) {
            return selectedAgeGroupId;
        }

        // first person should be selected by default
        // if first person is already in configuration and therefore can not be selected by default
        if(persons && (persons.allIds.length !== personsInConfiguration.length)) {
            let personWasFound = false;
            persons.allIds.map(function(person) {
                if(!personWasFound && !selectedPersonId && !personsInConfiguration.includes(person)) {
                    setSelectedPersonId(person);
                    setSelectedAgeGroupId(persons.byId[person].ageGroup);
                    personWasFound = true;
                    return person;
                }
            });
        }
        // if there are no persons, first age-group should be selected first
        else if(!selectedAgeGroupId) {
            setSelectedAgeGroupId(ageGroups.allIds[0]);
            return ageGroups.allIds[0];
        }
    };

    const getInfoTextOfPersonOrAgeGroup = () => {
        return selectedAgeGroupId && !selectedPersonId ? ageGroups.byId[selectedAgeGroupId].info : false;
    }

    // Render
    return (
        <form className={`ticket-configuration__box ${isInEdit ? 'ticket-configuration__box--edit' : ''}`}>
            {isInEdit ? (
                <span className="ticket-configuration__box__headline">
                    {translate('ski-ticket.ticket-in-edit')}
                </span>
            ) : ""}
            <label htmlFor="configuration-age-group" className="ticket-configuration__title">
                {translate('ski-ticket.add-person')}
            </label>

            <div className="row">
                <div className="col-md-6">
                    <div className="form-group d-block">
                        <div className="select__wrapper">
                            <select className="form-control select"
                                    id="configuration-age-group"
                                    value={getSelectedPersonOrAgeGroup()}
                                    onChange={evt => {
                                        if (evt.target.options[evt.target.selectedIndex].dataset.ageGroupId) {
                                            setSelectedAgeGroupId(evt.target.options[evt.target.selectedIndex].dataset.ageGroupId);
                                        } else {
                                            setSelectedAgeGroupId(evt.target.value);
                                        }
                                        setSelectedPersonId(evt.target.options[evt.target.selectedIndex].dataset.personId);
                                    }}>

                                {persons && (persons.allIds.length !== personsInConfiguration.length || personsInConfiguration.includes(selectedPersonId)) ? (
                                    <optgroup label={translate('ski-ticket.choose-person')}>
                                        <option value={null} disabled={true}
                                                selected={!selectedPersonId && !selectedAgeGroupId}>{translate('ski-ticket.please-choose')}</option>
                                        {
                                            persons.allIds.map((person) => (
                                                renderAvailablePersonOptions(person, persons, selectedPersonId)
                                            ))
                                        }
                                    </optgroup>
                                ) : null}

                                {persons ? (
                                    <optgroup label={translate('ski-ticket.choose-age-group')}>
                                        <option value={null} disabled={true}
                                                selected={!selectedAgeGroupId}>{translate('ski-ticket.please-choose')}</option>
                                        {ageGroups.allIds.map((ageGroup) => (
                                            <option key={ageGroup} id={ageGroup}
                                                    value={ageGroup}>{ageGroups.byId[ageGroup].name}</option>
                                        ))}
                                    </optgroup>
                                ) : (
                                    <Fragment>
                                        <option value={null} disabled={true}
                                                selected={!selectedAgeGroupId}>{translate('ski-ticket.please-choose')}</option>
                                        ageGroups.allIds.map((ageGroup) => (
                                        <option id={ageGroup} value={ageGroup}>{ageGroups.byId[ageGroup].name}</option>
                                        ))
                                    </Fragment>
                                )}
                            </select>

                            {persons ? (
                                <input type="hidden" name="selectedPerson" value={selectedPersonId}/>
                            ) : null}

                            <span className="icon icon-arrow-bold select-icon" aria-hidden="true"/>
                        </div>

                        { getInfoTextOfPersonOrAgeGroup() ? (
                            <div className="text-danger fz12 mt-2">
                                { getInfoTextOfPersonOrAgeGroup() }
                            </div>
                        ) : "" }
                    </div>
                </div>
                <div className="col-md-6 d-flex align-items-center justify-content-end">
                    <div
                        className="ticket-configuration__title ticket-configuration__title--big d-none d-md-block text-right">
                        {getOriginalPrice() > 0 ? (
                            <span className="text-primary d-block fz14 text-line-through">
                                {getOriginalPrice()
                                + " " + currencies.byId[selectedCurrencyId].name}
                            </span>
                        ) : null}
                        {prices && selectedAgeGroupId ? translate('ski-ticket.price') + " "
                            + getPrice() + " " + currencies.byId[selectedCurrencyId].name : null}

                        {getOriginalLabelPriceInfo() ? (
                            <span className="d-flex align-items-center mt-1 fz14 text-primary">
                                <span className=" icon icon-percent fz18 mr-2"/>
                                {getOriginalLabelPriceInfo()}
                            </span>
                        ) : null}

                        {getAdditionalPriceInfo() ? (
                            <span className="d-block fz12 text-primary">
                                {getAdditionalPriceInfo()}
                            </span>
                        ) : null}

                        {getPriceWarning() ? (
                            <span className="d-block fz12 mt-1 text-secondary">
                                {getPriceWarning()}
                            </span>
                        ) : null}
                    </div>
                </div>
            </div>

            {specialTickets.allIds.length > 0 ? (
                <fieldset className="mt-md-4 mt-2">
                    <legend
                        className="ticket-configuration__title">{translate('ski-ticket.ticket-specialTicket')}</legend>
                    <ul className="row list-unstyled vertical-gutter--2">
                        {getAvailableSpecialTickets().map((specialTicket) => (
                            specialTicket === null ? (
                                <li className="col-sm-6 vertical-gutter__item">
                                    <label className="ticket-configuration__checkbox-wrapper">
                                        <input type="radio"
                                               id="no-specialTicket"
                                               name="specialTicket"
                                               checked={selectedSpecialTicketId === specialTicket}
                                               onClick={(evt) => {
                                                   toggleSpecialTicket(specialTicket);
                                               }}
                                               className="custom-checkbox__input"
                                        />
                                        <span
                                            className="custom-checkbox__box custom-checkbox__box--rounded icon icon-check"/>
                                        <span
                                            className="custom-checkbox__text d-flex w-100 justify-content-between align-items-center">
                                            <span>
                                            {standardTicket.name}

                                              {selectedAgeGroupId && selectedSpecialTicketId !== specialTicket ? " (" + (
                                                  getPriceDifferenceSpecialTicket(specialTicket) > 0 ? "+" : ""
                                              ) + getPriceDifferenceSpecialTicket(specialTicket) + " " + currencies.byId[selectedCurrencyId].name + ")" : null}
                                            </span>

                                            { standardTicket && standardTicket.description !== null ? (
                                            <OverlayTrigger
                                                trigger="click"
                                                key={specialTicket}
                                                rootClose={true}
                                                description={standardTicket.description}
                                                overlay={
                                                    <Tooltip id={`tooltip-${specialTicket}`}>
                                                        <div className="wysiwyg"
                                                             dangerouslySetInnerHTML={{__html: standardTicket.description}}></div>
                                                    </Tooltip>
                                                }>
                                              <Button className="btn-no-styling btn-tooltip"><span
                                                  className="icon icon-faq"></span></Button>
                                            </OverlayTrigger>
                                            ) : null }
                                    </span>
                                    </label>
                                </li>
                            ) : (
                                <li key={specialTicket} className="col-sm-6 vertical-gutter__item">

                                    <label className="ticket-configuration__checkbox-wrapper">
                                        <input type="radio"
                                               id={specialTicket}
                                               name="specialTicket"
                                               className="custom-checkbox__input"
                                               checked={selectedSpecialTicketId === specialTicket}
                                               onClick={(evt) => {
                                                   toggleSpecialTicket(specialTicket);
                                               }}
                                        />
                                        <span
                                            className="custom-checkbox__box custom-checkbox__box--rounded icon icon-check"/>
                                        <span
                                            className="custom-checkbox__text d-flex w-100 justify-content-between align-items-center">
                                            <span>
                                                {specialTickets.byId[specialTicket].name}

                                                {selectedAgeGroupId && selectedSpecialTicketId !== specialTicket ? " (" + (
                                                    getPriceDifferenceSpecialTicket(specialTicket) > 0 ? "+" : ""
                                                ) + getPriceDifferenceSpecialTicket(specialTicket) + " " + currencies.byId[selectedCurrencyId].name + ")" : null}
                                            </span>

                                          <OverlayTrigger
                                              trigger="click"
                                              key={specialTicket}
                                              rootClose={true}
                                              description={specialTickets.byId[specialTicket].description}
                                              overlay={
                                                  <Tooltip id={`tooltip-${specialTicket}`}>
                                                      <div className="wysiwyg"
                                                           dangerouslySetInnerHTML={{__html: specialTickets.byId[specialTicket].description}}></div>
                                                  </Tooltip>
                                              }>
                                              <Button className="btn-no-styling btn-tooltip"><span
                                                  className="icon icon-faq"></span></Button>
                                            </OverlayTrigger>
                                        </span>
                                    </label>
                                </li>
                            )
                        ))}
                    </ul>
                </fieldset>

            ) : null}


            {upgrades.allIds.length > 0 ? (
                <fieldset className="mt-md-4 mt-2">
                    <legend className="ticket-configuration__title">{translate('ski-ticket.ticket-upgrades')} <span
                        className="text-muted fz14">({translate('ski-ticket.optional')})</span></legend>
                    <ul className="row list-unstyled vertical-gutter--2">
                        {getAvailableUpgrades().map((upgrade) => (
                            <li key={upgrade} className="col-sm-6 vertical-gutter__item">

                                <label className="ticket-configuration__checkbox-wrapper">
                                    <input type="checkbox"
                                           name={upgrade}
                                           className="custom-checkbox__input"
                                           checked={selectedUpgradeIds.includes(upgrade)}
                                           onClick={(evt) => {
                                               toggleUpgrade(upgrade);
                                           }}
                                    />
                                    <span className="custom-checkbox__box icon icon-check"/>
                                    <span
                                        className="custom-checkbox__text d-flex w-100 justify-content-between align-items-center">
                                    <span>
                                        {upgrades.byId[upgrade].name}

                                        {selectedAgeGroupId && !selectedUpgradeIds.includes(upgrade) ? " (" + (
                                            getPriceDifferenceUpgrade(upgrade) > 0 ? "+" : "-"
                                        ) + getPriceDifferenceUpgrade(upgrade) + " " + currencies.byId[selectedCurrencyId].name + ")" : null}
                                    </span>

                                  <OverlayTrigger
                                      key={upgrade}
                                      description={upgrades.byId[upgrade].description}
                                      trigger="click"
                                      rootClose={true}
                                      overlay={
                                          <Tooltip id={`tooltip-${upgrade}`}>
                                              <div className="wysiwyg"
                                                   dangerouslySetInnerHTML={{__html: upgrades.byId[upgrade].description}}></div>
                                          </Tooltip>
                                      }
                                  >
                                      <Button className="btn-no-styling btn-tooltip"><span
                                          className="icon icon-faq"></span></Button>
                                    </OverlayTrigger>
                                </span>
                                </label>

                                {upgrades.byId[upgrade].warning ? (
                                    <span className="text-danger fz12 mt-1">{upgrades.byId[upgrade].warning}</span>
                                ) : ""}
                            </li>
                        ))}
                    </ul>
                </fieldset>
            ) : null}

            {insurances.allIds.length > 0 ? (
                <fieldset className="mt-md-4 mt-2">
                    <legend className="ticket-configuration__title">{translate('ski-ticket.ticket-insurance')} <span
                        className="text-muted fz14">({translate('ski-ticket.optional')})</span></legend>
                    <ul className="row list-unstyled vertical-gutter--2">
                        {getAvailableInsurances().map((insurance) => (
                            insurance === null ? (
                                <li className="col-sm-4 vertical-gutter__item">
                                    <label className="ticket-configuration__checkbox-wrapper">
                                        <input type="radio"
                                               id="no-insurance"
                                               name="insurance"
                                               checked={selectedInsuranceId === insurance}
                                               onClick={(evt) => {
                                                   toggleInsurance(insurance);
                                               }}
                                               className="custom-checkbox__input"
                                        />
                                        <span
                                            className="custom-checkbox__box custom-checkbox__box--rounded icon icon-check"/>
                                        <span
                                            className="custom-checkbox__text d-flex w-100 justify-content-between align-items-center">
                                        {translate('ski-ticket.no-insurance')}

                                            {selectedAgeGroupId && selectedInsuranceId !== insurance ? " (" + (
                                                getPriceDifferenceInsurance(insurance) > 0 ? "+" : ""
                                            ) + getPriceDifferenceInsurance(insurance) + " " + currencies.byId[selectedCurrencyId].name + ")" : null}
                                    </span>
                                    </label>
                                </li>
                            ) : (
                                <li key={insurance} className="col-sm-4 vertical-gutter__item">

                                    <label className="ticket-configuration__checkbox-wrapper">
                                        <input type="radio"
                                               id={insurance}
                                               name="insurance"
                                               className="custom-checkbox__input"
                                               checked={selectedInsuranceId === insurance}
                                               onClick={(evt) => {
                                                   toggleInsurance(insurance);
                                               }}
                                        />
                                        <span
                                            className="custom-checkbox__box custom-checkbox__box--rounded icon icon-check"/>
                                        <span
                                            className="custom-checkbox__text d-flex w-100 justify-content-between align-items-center">
                                    <span>
                                        {insurances.byId[insurance].name}

                                        {selectedAgeGroupId && selectedInsuranceId !== insurance ? " (" + (
                                            getPriceDifferenceInsurance(insurance) > 0 ? "+" : ""
                                        ) + getPriceDifferenceInsurance(insurance) + " " + currencies.byId[selectedCurrencyId].name + ")" : null}
                                    </span>

                                  <OverlayTrigger
                                      key={insurance}
                                      trigger="click"
                                      description={insurances.byId[insurance].description}
                                      rootClose={true}
                                      overlay={
                                          <Tooltip id={`tooltip-${insurance}`}>
                                              <div className="wysiwyg"
                                                   dangerouslySetInnerHTML={{__html: insurances.byId[insurance].description}}></div>
                                          </Tooltip>
                                      }
                                  >
                                      <Button className="btn-no-styling btn-tooltip"><span
                                          className="icon icon-faq"></span></Button>
                                    </OverlayTrigger>
                                </span>
                                    </label>
                                </li>
                            )
                        ))}
                    </ul>
                </fieldset>
            ) : null}

            <div className="ticket-configuration__title ticket-configuration__title--big mt-4 d-md-none">
                {getOriginalPrice() > 0 ? (
                    <span className="text-primary d-block fz14 text-line-through">
                                {getOriginalPrice()
                                + " " + currencies.byId[selectedCurrencyId].name}
                            </span>
                ) : null}

                {prices && selectedAgeGroupId ? translate('ski-ticket.price') + " "
                    + getPrice() + " " + currencies.byId[selectedCurrencyId].name : null}

                {getAdditionalPriceInfo() ? (
                    <span className="d-block fz12 text-primary">{getAdditionalPriceInfo()}</span>
                ) : null}

                {getOriginalLabelPriceInfo() ? (
                    <span className="d-flex mt-1 fz12 text-primary">
                                <span className=" icon icon-percent fz18 mr-2"/>
                        {getOriginalLabelPriceInfo()}
                            </span>
                ) : null}

                {getPriceWarning() ? (
                    <span className="d-block fz12 mt-1 text-secondary">
                                {getPriceWarning()}
                            </span>
                ) : null}
            </div>

            <div className="mt-4 text-center">
                <button className={`btn w-100 w-md-auto ${!selectedAgeGroupId ? 'btn-grey' : 'btn-primary'}`}
                        type="button"
                        disabled={!selectedAgeGroupId}
                        onClick={handleSubmit}>
                    {submitText}
                </button>
            </div>

        </form>
    );
}