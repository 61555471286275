"use strict";

let scriptCache = {};

if (!window.jQuery ||
    (window.jQuery && parseInt(window.jQuery.fn.jquery.replace('.', '')) < parseInt('1.5.1'.replace('.', '')))) {
    throw new Error('jQuery.loadScript requires jQuery 1.5.1 or greater.');
}

export default function loadScript (url) {
    if (!url.startsWith('http') && !url.startsWith('//')) {
        console.warn('\'loadScript\' should only be used for external resources. Use \'import\' for local files. \n Loaded url was \'' + url + '\'');

        if (process && process.env && process.env.CACHE_BUSTER) {
            url = '/cache-buster-' + process.env.CACHE_BUSTER + url;
        }
    }

    if (!scriptCache[url]) {
        scriptCache[url] = $.ajax({
            url: url,
            dataType: "script",
            cache: true
        });
        scriptCache[url].catch(function (errorMsg) {
            console.error('error loading script ' + url + '\n',  errorMsg);
        });
    }
    return scriptCache[url];
};