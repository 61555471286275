"use strict";

import {isParsleyForm, isValid} from "./parsley";
import {getServicePermission} from "./cookiePermissions";
import {trackEvent} from "./tracking";

export function initInScope($scope) {
    let $form = $scope.find('.js-tracking--checkout');

    $form.on('submit', function() {
        let $self = $(this);

        if(isParsleyForm($form)) {
            if(isValid($form)) {
                //fb payment tracking
                if (getServicePermission('facebook')) {
                    if(typeof fbq != 'undefined') {
                        fbq('track', 'AddPaymentInfo');
                    }
                }


                //cashback tracking
                let cashbackInput = $self.find('.js-tracking--checkout-cashback');

                if(cashbackInput && cashbackInput.length != 0) {
                    if(cashbackInput.is(':checked')) {
                        console.log( cashbackInput.data('tracking-category'));
                        trackEvent(
                            cashbackInput.data('tracking-category'),
                            cashbackInput.data('tracking-action'),
                            cashbackInput.data('tracking-label-selected'),
                        );
                    } else {
                        trackEvent(
                            cashbackInput.data('tracking-category'),
                            cashbackInput.data('tracking-action'),
                            cashbackInput.data('tracking-label-removed'),
                        );
                    }
                }

                //payment tracking
                let paymentInputSelected = $self.find('.js-tracking--checkout-payment:checked');

                if(paymentInputSelected && paymentInputSelected.length != 0) {
                    let paymentValue = paymentInputSelected.val();
                    trackEvent(
                        paymentInputSelected.data('tracking-category'),
                        paymentInputSelected.data('tracking-action'),
                        paymentValue
                    );
                }
            }
        }
    });
}